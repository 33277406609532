export const isLocalStorageAvailable = () => {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const companyInfoAvailable = (pinflagApiKey) => {
  try {
    const companiesInformation = localStorage.getItem('companiesInformation')
    const companiesObject = JSON.parse(companiesInformation)

    if (!companiesObject[pinflagApiKey]) return false

    const { createdAt, ...companyInfo } = companiesObject[pinflagApiKey]

    if (!createdAt) return false

    const dateParsed = Date.parse(createdAt)
    const now = new Date()
    const diff = Math.abs(now - dateParsed) / (1000 * 60)
    return diff < 10 ? companyInfo : false
  } catch (e) {
    return false
  }
}

export const setCompanyLocalStorage = (companyInfo) => {
  try {
    const { pinflagApiKey } = companyInfo

    const companiesInformation = localStorage.getItem('companiesInformation')
    const companiesObject = JSON.parse(companiesInformation) || {}

    Object.assign(companyInfo, { createdAt: new Date() })

    companiesObject[pinflagApiKey] = companyInfo
    const companiesInformationParsed = JSON.stringify(companiesObject)

    localStorage.setItem('companiesInformation', companiesInformationParsed)
  } catch (e) {
    console.log('error', e)
  }
}

export const setUserInfoLocalStorage = (userInfo) => {
  try {
    Object.assign(userInfo, { createdAt: new Date() })

    const userInfoParsed = JSON.stringify(userInfo)
    localStorage.setItem('userInformation', userInfoParsed)
  } catch (e) {
    console.log('error', e)
  }
}

export const getUserInfoLocalStorage = () => {
  try {
    const userInformation = localStorage.getItem('userInformation')

    if (!userInformation) return {}

    const userObject = JSON.parse(userInformation)
    const { createdAt, ...userInfo } = userObject

    const dateParsed = Date.parse(createdAt)
    const now = new Date()
    const diff = Math.abs(now - dateParsed) / (1000 * 60 * 60)
    return diff < 24 ? userInfo : {}
  } catch (error) {
    return {}
  }
}

export const savePaymentMethodsLocalStorage = (paymentMethods, pinflagApiKey) => {
  try {
    const paymentMethodsInformation = localStorage.getItem('paymentMethods')
    const paymentMethodsObject = JSON.parse(paymentMethodsInformation) || {}

    paymentMethodsObject[pinflagApiKey] = { paymentMethods, createdAt: new Date() }

    localStorage.setItem('paymentMethods', JSON.stringify(paymentMethodsObject))
  } catch (e) {
    console.log('error', e)
  }
}

export const getPaymentMethodsLocalStorage = (pinflagApiKey) => {
  try {
    const localPaymentMethods = localStorage.getItem('paymentMethods')

    if (!localPaymentMethods) return null

    const paymentMethodsObject = JSON.parse(localPaymentMethods)

    if (!paymentMethodsObject[pinflagApiKey]) return null

    const { createdAt, paymentMethods } = paymentMethodsObject[pinflagApiKey]

    const dateParsed = Date.parse(createdAt)
    const now = new Date()
    const diff = Math.abs(now - dateParsed) / (1000 * 60 * 60)

    return diff < 12 ? paymentMethods : null
  } catch (error) {
    return null
  }
}

export const saveConfigurationsLocalStorage = (configurations, pinflagApiKey) => {
  try {
    const configurationsInformation = localStorage.getItem('configurations')
    const configurationsObject = JSON.parse(configurationsInformation) || {}
    configurationsObject[pinflagApiKey] = { configurations, createdAt: new Date() }

    localStorage.setItem('configurations', JSON.stringify(configurationsObject))
  } catch (e) {
    console.log('error', e)
  }
}

export const getConfigurationsLocalStorage = (pinflagApiKey) => {
  try {
    const localConfigurations = localStorage.getItem('configurations')

    if (!localConfigurations) return null

    const configurationsObject = JSON.parse(localConfigurations)

    if (!configurationsObject[pinflagApiKey]) return null
    const { createdAt, configurations } = configurationsObject[pinflagApiKey]

    const dateParsed = Date.parse(createdAt)
    const now = new Date()
    const diff = Math.abs(now - dateParsed) / (1000 * 60 * 60)

    return diff < 12 ? configurations : null
  } catch (error) {
    return null
  }
}

export const getLoaderInfoLocalStorage = (pinflagApiKey) => {
  try {
    const loaderInfo = localStorage.getItem('loaderInfo')

    if (!loaderInfo) return null

    const loaderInfoObject = JSON.parse(loaderInfo)

    if (!loaderInfoObject[pinflagApiKey]) return null

    const { createdAt, ...loaderInfoParsed } = loaderInfoObject[pinflagApiKey]

    const dateParsed = Date.parse(createdAt)
    const now = new Date()
    const diff = Math.abs(now - dateParsed) / (1000 * 60)

    return diff < 10 ? loaderInfoParsed : null
  } catch (error) {
    return null
  }
}

export const setLoaderInfoLocalStorage = (loaderInfo, pinflagApiKey) => {
  try {
    const loaderInfoInformation = localStorage.getItem('loaderInfo')
    const loaderInfoObject = JSON.parse(loaderInfoInformation) || {}
    Object.assign(loaderInfo, { createdAt: new Date() })

    loaderInfoObject[pinflagApiKey] = loaderInfo

    localStorage.setItem('loaderInfo', JSON.stringify(loaderInfoObject))
  } catch (e) {
    console.log('error', e)
  }
}
