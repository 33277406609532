import * as turf from '@turf/turf'

export const sortGeojson = (geojsonFeatures, userCoordinate) => {
  const options = { units: 'kilometers' }
  for (const point of geojsonFeatures) {
    point.properties.distance = turf.distance(
      userCoordinate,
      point.geometry,
      options
    )
  }
  geojsonFeatures.sort((pointA, pointB) => {
    if (pointA.properties.distance > pointB.properties.distance) return 1
    if (pointA.properties.distance < pointB.properties.distance) return -1
    return 0
  })
  return geojsonFeatures
}
