import { numberParser } from './numberParser'
import { backupRates, postalCodesVTEX } from '../constants/states'
import CryptoJS from 'crypto-js'

export const transformCart = items => {
  const lines = []
  items.forEach(item => {
    lines.push({
      id: `${item.item_id}`,
      productName: item.name,
      productImage: item.extension_attributes.image_url,
      quantity: parseInt(item.qty),
      lineTotal: numberParser(item.price * item.qty)
    })
  })
  return lines
}

export const transformCartTotals = data => {
  return {
    subtotal: data.base_grand_total,
    total: data.grand_total,
    shippingCost: data.shipping_discount_amount,
    itemsCount: data.items_qty
  }
}

export const scrollToTop = () => {
  const scrollers = document.getElementsByClassName('list-item-div')
  const arrayScrollers = [...scrollers]
  arrayScrollers.forEach((scroller) => {
    scroller.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  })
}

export const scrollToBottom = (className) => {
  const scroll = document.getElementsByClassName(className)
  scroll[0].scrollTo({
    top: scroll[0].scrollHeight,
    behavior: 'smooth'
  })
}

export const totalPriceParser = (items) => {
  const totalPrice = items?.reduce((a, b) => { return { final_line_price: a.final_line_price + b.final_line_price } })
  return totalPrice.final_line_price / 100
}

export const abreviatedCheckoutLines = (cartLines) => {
  if (!cartLines || Object.keys(cartLines).length === 0) return []
  return cartLines.map(line => {
    return { variantId: `${btoa(`gid://shopify/ProductVariant/${line?.variantId}`)}`, quantity: line.quantity }
  })
}

export const abreviatedCartLines = (cartLines) => {
  if (!cartLines || Object.keys(cartLines).length === 0) return []
  return cartLines.map(line => {
    return { merchandiseId: `gid://shopify/ProductVariant/${line?.variantId}`, quantity: line.quantity }
  })
}

export const onlyLetters = (value) => {
  const result = value.replace(/[^a-z \-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ`'´]/gi, '')
  return result
}

export const onlyNumbers = (value) => {
  const result = value.replace(/[^\d]/g, '')
  return result
}

export const onlySomeNumbers = (value) => {
  const result = value.match(/\d{10,}/g)
  if (result) {
    return result[0].match(/\d{9}/)[0]
  }
  return value
}

export const onlySomeLetters = (value, quantity) => {
  const result = value.match(new RegExp(`[A-Za-z\\d\\s\\S]{${quantity + 1},}`, 'g'))
  if (result) {
    return result[0].match(new RegExp(`[A-Za-z\\d\\s\\S]{${quantity}}`))[0]
  }
  return value
}

export const camelToSnake = (str) => (str.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`))

export const itemsUrlParser = (items) => {
  return items
    .map((item) => `${item.variantId}:${item.quantity}`)
    .join(',')
}

export const extraAttributesParser = attributes => {
  if (!Object.keys(attributes).length) return ''
  return '&' + Object.keys(attributes)
    .map(attrKey => `${attrKey}=${attributes[attrKey]}`)
    .join('&')
}

export const setServiceAndShippingcost = (selectedService, setterService, setterCart, cartData) => {
  setterService(selectedService)
  setterCart(prevState => ({
    ...prevState,
    shippingCost: selectedService.price
  }
  ))
}

export const isUserInfoChanged = (loadedInformation, userInfo) => {
  return Object.keys(loadedInformation.shipping).some(key => {
    if (!Object.keys(userInfo).includes(key)) return false

    if (key === 'first_name' || key === 'last_name') {
      return userInfo.names !== `${loadedInformation.shipping.first_name} ${loadedInformation.shipping.last_name}`
    }

    return userInfo[key] !== loadedInformation.shipping[key]
  })
}

export const isInfoChanged = (loadedInformation, newInformation) => {
  return Object.keys(loadedInformation.shipping).some(key => {
    return newInformation[key] !== loadedInformation.shipping[key]
  })
}

export const areDiscountCodesChanged = (loadedInformation, newDiscountCodes) => {
  const newDiscountCodesTitle = getTitleDiscountCodes(newDiscountCodes)
  const { discountCodes } = loadedInformation
  if (discountCodes.length !== newDiscountCodesTitle.length) {
    return true
  }
  const diff = discountCodes.filter(discount => !newDiscountCodesTitle.some(newDiscount => {
    return newDiscount.toLowerCase().includes(discount.toLowerCase()) ||
      discount.toLowerCase().includes(newDiscount.toLowerCase())
  }))

  return diff.length !== 0
}

export const getPinflagApiKey = () => {
  const searchParams = new URLSearchParams(document.location.search)
  return searchParams.get('token') || process.env.REACT_APP_API_KEY
}

export const getOrderFormId = () => {
  const searchParams = new URLSearchParams(document.location.search)
  return searchParams.get('orderFormId')
}

export const isValidCode = (discountCode, code) => {
  if (discountCode.code.includes('Bundler')) {
    return discountCode.code.toLowerCase().includes(code.toLowerCase()) && discountCode.applicable
  }
  return discountCode.code.toLowerCase() === code.toLowerCase() && discountCode.applicable
}

export const appliedDiscountObject = (discount) => ({
  amount: discount,
  title: 'CUSTOM',
  value: discount,
  valueType: 'FIXED_AMOUNT'
})

export const rutWithoutDot = (rut) => rut.replaceAll('.', '')

export const getBackupRate = (stateId) => {
  const backupRate = stateId ? backupRates[stateId] : 3190
  const data = {
    services: [
      {
        packageDeliveryTime: '2-5 días hábiles',
        price: backupRate,
        serviceType: 'standard',
        courier: 'blueexpress'
      }
    ]
  }
  return data
}

export const getPinflagPostalCode = (price) => {
  const priceLength = `${price}`.length
  return price === 0 ? '0030001' : '0'.repeat(7 - priceLength) + `${price}`
}

const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const getPostalCode = (district) => {
  const parsedDistrict = removeAccents(district.toLowerCase())
  return postalCodesVTEX[parsedDistrict]
}

export const getTotalPriceSLA = (logisticInfo, index) => {
  const totalPrice = logisticInfo.reduce((acc, currentValue) => acc + currentValue.slas[index].price, 0)
  return totalPrice / 100
}

export const parseDeliveryTime = (oldDeliveryTime) => {
  const time = oldDeliveryTime.slice(0, oldDeliveryTime.indexOf('bd'))
  const info = oldDeliveryTime.slice(oldDeliveryTime.indexOf(time) + time.length)
  const infoTranslate = { h: 'horas hábiles', bd: 'días hábiles' }
  return `Máximo ${time} ${infoTranslate[info]}`
}

export const encryptToken = (token, key) => {
  const iv = CryptoJS.lib.WordArray.random(16)

  const encrypted = CryptoJS.AES.encrypt(token, key, { iv })

  const combined = iv.toString() + encrypted.toString()

  return combined
}

export const decryptToken = (encrypted, key) => {
  const iv = CryptoJS.enc.Hex.parse(encrypted.slice(0, 32))

  const encryptedToken = encrypted.slice(32)

  try {
    const decrypted = CryptoJS.AES.decrypt(encryptedToken, key, { iv })

    return decrypted.toString(CryptoJS.enc.Utf8)
  } catch (error) {
    console.error('Error durante la desencriptación:', error)
    return null
  }
}

export const findLowestPrice = (listPoints) => {
  try {
    if (listPoints.length === 0) {
      return null
    }
    const lowestPrice = listPoints.reduce((min, point) => {
      return point.properties.price < min.properties.price ? point : min
    }, listPoints[0])

    return lowestPrice.properties.price
  } catch (error) {
    console.error('Error durante la busqueda de menor precio:', error)
    return null
  }
}

export const getTitleDiscountCodes = discountCodes => {
  return discountCodes.map(
    discount => discount.type === 'gift-card' ? discount.originalCode : discount.code
  )
}

export const parseDiscountCodes = discountCodes => {
  return getTitleDiscountCodes(discountCodes).join(',')
}

export const createRandomString = (length = 24) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

export const isCartDataChanged = (loadedInformation, cartData) => {
  const { initialCart } = loadedInformation
  const { lines, subtotal, discount, itemsCount } = cartData

  if (
    initialCart.lines.length !== lines.length ||
    initialCart.itemsCount !== itemsCount
  ) {
    return true
  }

  const areQuantitiesChanged = lines.some(line => !initialCart.lines.find(initialLine =>
    initialLine.id === line.id && initialLine.quantity === line.quantity
  ))

  const isCartTotalChanged = parseInt(subtotal - discount, 10) !== parseInt(initialCart.subtotal - initialCart.discount, 10)

  return areQuantitiesChanged || isCartTotalChanged
}
