import { useCallback, useRef, useEffect, useContext, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { DataContext } from '../context/DataContext'
import { useShopify } from './useShopify'
import { shippingAddressObject } from '../helpers/addressParser'
import { areDiscountCodesChanged, getTitleDiscountCodes, isCartDataChanged, isInfoChanged } from '../helpers/utils'
import { useCmsAdapter } from './useCmsAdapter'
import { SHOPIFY_PLUS_STORES } from '../constants/stores'
import { HOME_DELIVERY, LOCAL_PICKUP } from '../constants/deliveryTypes'

const URL = 'wss://9fxmslyqr0.execute-api.sa-east-1.amazonaws.com/production'

export const useSocket = () => {
  const [scrapperClient, setScrapperClient] = useState('')
  const {
    userInfo,
    addressInfo,
    companyInfo,
    selectedPaymentMethod,
    currentStep,
    isPaying,
    discountInfo,
    ecommerceInfo,
    setCollectedScrapperInfo,
    isUserValidated,
    cartData,
    configurations
  } = useContext(DataContext)
  const {
    createScrapperURL,
    redirectPermalink,
    getConfigurationsShopify,
    removeIframeShopify,
    redirectToCartShopify,
    completeCheckoutLog,
    saveCartTotalShopify,
    checkNewDiscountCodes
  } = useShopify()
  const { initScrapperCall, trackingSecondStep, trackingLeak, getLocation } = useCmsAdapter()
  const socket = useRef(null)
  const [uuidConnection] = useState(uuidv4())
  const [loadedInformation, setLoadedInformation] = useState({})
  const [isSocketConnected, setIsSocketConnected] = useState(false)
  const [isScrapperInitialized, setIsScrapperInitialized] = useState(false)
  const [responseScrapper, setResponseScrapper] = useState('')
  const [cookiesScrapper, setCookiesScrapper] = useState(null)
  const [appliedDiscountCodes, setAppliedDiscountCodes] = useState(null)
  const { discountCodes } = discountInfo

  const socketOpen = useCallback(() => {
    setIsSocketConnected(true)
    const name = `${uuidConnection}:pinmap`
    socket.current?.send(
      JSON.stringify({
        action: 'setName',
        name
      })
    )
  }, [])

  const socketClose = useCallback(() => {
    console.log('disconnect')
  }, [])

  const socketMessage = useCallback(dataStr => {
    const data = JSON.parse(dataStr)
    if (data.privateMessage?.id === 'secondStep') {
      const cookies = data.privateMessage.cookies
      cookies.forEach(cookie => {
        if (cookie.name === 'cart') setCookiesScrapper(cookie)
      })
    } else {
      setCollectedScrapperInfo(prevState => {
        return { ...prevState, socketMessages: [...prevState.socketMessages, { data, timestamp: new Date().toISOString() }] }
      })
    }
    if (data.joined) {
      setCollectedScrapperInfo(prevState => {
        return { ...prevState, joined: [...prevState.joined, { uuid: data.joined, timestamp: new Date().toISOString() }] }
      })
      setScrapperClient(data.joined)
      if (data.joined.includes('discountCodes')) {
        setAppliedDiscountCodes(data.joined.split('discountCodes').pop().split(';'))
      }
    }
    if (data.privateMessage?.paymentUrl) {
      setCollectedScrapperInfo(prevState => {
        return { ...prevState, paymentUrl: data.privateMessage.paymentUrl }
      })
      setResponseScrapper(data.privateMessage)
    }
  }, [])

  const onConnect = useCallback(() => {
    if (!isSocketConnected && socket.current?.readyState !== WebSocket.OPEN) {
      socket.current = new WebSocket(URL)
      socket.current.addEventListener('open', socketOpen)
      socket.current.addEventListener('close', socketClose)
      socket.current.addEventListener('message', event => {
        socketMessage(event.data)
      })
    }
  }, [])

  const onDisconnect = useCallback(() => {
    socket.current?.close()
    setIsSocketConnected(false)
    console.log('disconnect')
  }, [])

  const sendPrivateMessage = useCallback((to, message) => {
    setCollectedScrapperInfo(prevState => {
      return { ...prevState, privateMessages: [...prevState.privateMessages, { to, message, timestamp: new Date().toISOString() }] }
    })
    socket.current?.send(
      JSON.stringify({
        action: 'sendPrivate',
        message,
        to
      })
    )
  }, [])

  const initScrapper = async () => {
    // crear permalink solo con el carrito
    if (isScrapperInitialized) return

    const { domain } = ecommerceInfo

    const encryptedData = userInfo.isRegistered ? userInfo.encryptedData : false

    initScrapperCall(domain, createScrapperURL(), uuidConnection, encryptedData)
      .then(() => setIsScrapperInitialized(true))
      .catch((err) => {
        console.log(err)
        initScrapperCall(domain, createScrapperURL(), uuidConnection, encryptedData)
          .then(() => setIsScrapperInitialized(true))
          .catch((err) => {
            console.log(err)
            removeIframeShopify({}, {}, `Scrapper initialization error: ${JSON.stringify(err)} - redirect to checkout`)
          })
      })

    setLoadedInformation(prevState => {
      return { ...prevState, initialCart: cartData }
    })
  }

  const buildMessageShipping = async (wentBackwards = false) => {
    let locationName
    const { serviceName: shippingRate, deliveryType, cmsId, addressIndex } = addressInfo

    if (userInfo.isRegistered && !isUserValidated && deliveryType === HOME_DELIVERY) {
      if (wentBackwards) return { shippingRate, addressIndex, encryptedData: userInfo.encryptedData, deliveryType }
      return { shippingRate, addressIndex, deliveryType }
    }

    if (deliveryType === LOCAL_PICKUP && cmsId) {
      const location = await getLocation(cmsId)
      locationName = location.data.name
    }

    let storeConfig = configurations
    if (!Object.keys(configurations)?.length) storeConfig = getConfigurationsShopify()

    const addressObject = shippingAddressObject(
      userInfo,
      addressInfo,
      storeConfig
    )
    const { email } = userInfo

    const { address1, address2, city, company, province, firstName, lastName, zip, phone } = addressObject

    if (userInfo.isRegistered && !isUserValidated && deliveryType !== HOME_DELIVERY) {
      if (wentBackwards) return { countryCode: 'CL', address1, address2, city, company, province, shippingRate, deliveryType, locationName, encryptedData: userInfo.encryptedData }
      return { countryCode: 'CL', address1, address2, city, company, province, shippingRate, deliveryType, locationName }
    }

    return {
      countryCode: 'CL',
      address1,
      address2,
      city,
      company,
      province,
      first_name: firstName,
      last_name: lastName,
      zip,
      phone,
      email,
      shippingRate,
      deliveryType,
      locationName,
      requireShipping: addressInfo.requireShipping
    }
  }

  const reloadFinalScraper = async () => {
    if (!isCartDataChanged(loadedInformation, cartData)) return false
    saveCartTotalShopify()
    const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    await timeout(1000)
    return true
  }

  const sendShippingInfo = async () => {
    if (loadedInformation.shipping) return
    const shipping = await buildMessageShipping()
    const messageObject = { shipping }

    const titleDiscountCodes = getTitleDiscountCodes(discountCodes)
    messageObject.discountCodes = titleDiscountCodes

    sendPrivateMessage(scrapperClient, messageObject)
    setLoadedInformation(prevState => {
      return { ...prevState, ...messageObject }
    })
    completeCheckoutLog(userInfo)
  }

  const sendPaymentMethod = async () => {
    const message = { paymentMethod: selectedPaymentMethod }
    if (areDiscountCodesChanged(loadedInformation, discountCodes)) {
      const titleDiscountCodes = getTitleDiscountCodes(discountCodes)
      message.discountCodes = titleDiscountCodes
    }
    if (await reloadFinalScraper()) message.reloadPage = true

    sendPrivateMessage(scrapperClient, message)
  }

  const closeScrapper = () => {
    setIsSocketConnected(false)
    const message = { ready: true }

    sendPrivateMessage(scrapperClient, message)
    setTimeout(() => onDisconnect(), 2000)
  }

  const sendCompleteInformation = async () => {
    const wentBackwards = true
    const shipping = await buildMessageShipping(wentBackwards)
    const titleDiscountCodes = getTitleDiscountCodes(discountCodes)

    const message = {
      shipping,
      paymentMethod: selectedPaymentMethod,
      backToInformation: true,
      discountCodes: areDiscountCodesChanged(loadedInformation, discountCodes) ? titleDiscountCodes : undefined,
      reloadPage: await reloadFinalScraper()
    }
    sendPrivateMessage(scrapperClient, message)
  }

  useEffect(() => {
    let isUserInfoComplete
    if (userInfo.isRegistered) isUserInfoComplete = true
    else {
      isUserInfoComplete = !Object.keys(userInfo).some(
        key => (userInfo[key] === '' && key !== 'encryptedData')
      )
    }
    if (isUserInfoComplete && currentStep === 2 && ecommerceInfo.domain && !isScrapperInitialized) {
      saveCartTotalShopify()
      getConfigurationsShopify()
      trackingSecondStep({
        uuidConnection,
        timeOpenSecondStep: new Date(),
        flowType: userInfo.isRegistered ? 'soft-login' : undefined
      })
      if (SHOPIFY_PLUS_STORES.includes(companyInfo.companyName) || ecommerceInfo.cms !== 'shopify') return
      onConnect()
      initScrapper()
    }
  }, [userInfo, ecommerceInfo])

  useEffect(() => {
    if (cookiesScrapper) {
      trackingSecondStep({
        scrapperCheckoutId: cookiesScrapper.value
      })
    }
  }, [cookiesScrapper])

  useEffect(() => {
    const isAddressInfoComplete = !Object.keys(addressInfo).some(
      key => addressInfo[key] === '' && key !== 'aditionalInfo' && key !== 'courier' && key !== 'serviceType' && key !== 'sla'
    )
    const isInformationScrapper =
    scrapperClient === `${uuidConnection}:scrapper:information`

    const isGiftCardProduct = addressInfo.requireShipping === false

    if ((addressInfo.serviceName || isAddressInfoComplete || isGiftCardProduct) && isInformationScrapper) {
      sendShippingInfo()
    }
  }, [scrapperClient, addressInfo])

  useEffect(() => {
    const isPaymentScrapper =
      scrapperClient === `${uuidConnection}:scrapper:payment`
    if (isPaying && isPaymentScrapper) {
      buildMessageShipping().then(shipping => {
        if (isInfoChanged(loadedInformation, shipping) || isUserValidated) {
          sendCompleteInformation()
        } else {
          sendPaymentMethod()
        }
      })
    }
  }, [scrapperClient, isPaying])

  useEffect(() => {
    if (appliedDiscountCodes !== null) {
      checkNewDiscountCodes(appliedDiscountCodes)
      setLoadedInformation(prevState => ({
        ...prevState,
        discountCodes: appliedDiscountCodes
      }))
      sendPrivateMessage(scrapperClient, { appliedDiscountCodes: true })
    }
  }, [appliedDiscountCodes])

  useEffect(() => {
    if (responseScrapper !== '' && isSocketConnected) {
      closeScrapper()
      redirectPermalink(responseScrapper)
    }
  }, [responseScrapper])

  useEffect(() => {
    let timeoutScrapper
    if (isScrapperInitialized) {
      timeoutScrapper = setTimeout(() => {
        trackingLeak({
          causePinmapLeak: 'timeout scrapper - redirect to cart'
        })
        setTimeout(() => redirectToCartShopify(), 800)
      }, 360000)
    }

    return () => clearTimeout(timeoutScrapper)
  }, [isScrapperInitialized])

  useEffect(() => {
    return onDisconnect()
  }, [])

  return ({ scrapperClient })
}
