import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, SectionTitle, ModalServiceType } from '@pinflag/pinflag-ui-kit'
import { MAPBOX_STATIC_MAP_URL } from '../constants/url'
import { DataContext } from '../context/DataContext'
import ReturnPickupPoint from './ReturnPickupPoint'
import { useCmsAdapter } from '../hook/useCmsAdapter'
import { setServiceAndShippingcost, abreviatedCheckoutLines } from '../helpers/utils'
import { getAddressInformationObjectVtex, shippingAddressObject } from '../helpers/addressParser'
import { useWoocommerce } from '../hook/useWoocommerce'
import { numberParser } from '../helpers/numberParser'
import { gaPaymentPostMessage } from '../helpers/gaEventsHelper'
import { SHOPIFY_PLUS_STORES } from '../constants/stores'
import { useVtex } from '../hook/useVtex'
import { useShopify } from '../hook/useShopify'

export const AddressConfirmation = ({ selectedPointData, handleAddressInformation, mapCenter }) => {
  const {
    steps,
    setSteps,
    setCurrentStep,
    addressInfo,
    selectedServiceType,
    deliveryInfo,
    setDeliveryInfo,
    setSelectedServiceType,
    companyInfo,
    cartData,
    setCartData,
    userInfo,
    setPointsData,
    ecommerceInfo,
    setAddressInfo,
    isUserValidated,
    configurations
  } = useContext(DataContext)
  const { region, district, userAddress } = addressInfo
  const { primaryColor, secondaryColor, useTwoClick } = companyInfo
  const { createCheckoutLog, trackingThirdStep, setShippingDataVtex } = useCmsAdapter()
  const { shippingPostMessageWoocommerce, hideWoocommerceSummary } = useWoocommerce()
  const { getConfigurationsShopify } = useShopify()
  const { updateCartShippingDiscount } = useVtex()
  const [openServiceTypeModal, setOpenServiceTypeModal] = useState(false)
  const [mapboxCoordinates, setMapboxCoordinates] = useState(null)
  const [backgroundColor, setBackgroundColor] = useState(primaryColor)
  const [iconColor, setIconColor] = useState('white')

  const handleChangeClick = () => {
    hideWoocommerceSummary()
    setPointsData((prevState) => {
      prevState.localPointSelected = {}
      prevState.pinflagPointSelected = {}
      return prevState
    })
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = true
    updatedSteps[1].subSteps[1].completed = false
    updatedSteps[1].subSteps[2].completed = false
    setSteps(updatedSteps)
    setDeliveryInfo({})
  }

  const handlePayment = () => {
    trackingThirdStep({
      timeOpenThirdStep: new Date(),
      flowType: isUserValidated ? 'hard-login' : undefined
    })
    gaPaymentPostMessage()
    setCurrentStep(3)
    // setIsPaying(true)
  }

  useEffect(() => {
    if (SHOPIFY_PLUS_STORES.includes(companyInfo.companyName)) {
      let storeConfig = configurations
      if (!Object.keys(configurations)?.length) storeConfig = getConfigurationsShopify()
      const checkoutData = {
        email: userInfo.email,
        shippingAddress: shippingAddressObject(userInfo, addressInfo, storeConfig),
        lineItems: abreviatedCheckoutLines(cartData.lines)
      }
      createCheckoutLog(checkoutData)
    }
    if (ecommerceInfo.cms.includes('vtex')) {
      const preliminarAddressObject = getAddressInformationObjectVtex(addressInfo, companyInfo, userInfo)
      setShippingDataVtex(ecommerceInfo.orderFormId, preliminarAddressObject).then((response) => {
        updateCartShippingDiscount(response.data)
      })
    }
    const handleResize = () => {
      const isMobile = window.innerWidth < 768
      setBackgroundColor(isMobile ? '#F2F4F4' : companyInfo.primaryColor)
      setIconColor(isMobile ? '#1F2937' : 'white')
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (selectedPointData && selectedPointData.center) {
      setMapboxCoordinates(selectedPointData.center)
      setAddressInfo(prevState => {
        return {
          ...prevState,
          mapCoords: selectedPointData.center,
          sla: selectedServiceType.packageDeliveryTime
        }
      })
    } else {
      setMapboxCoordinates(mapCenter)
      setAddressInfo(prevState => {
        return {
          ...prevState,
          mapCoords: mapCenter,
          sla: selectedServiceType.packageDeliveryTime
        }
      })
    }
  }, [mapCenter, selectedPointData])

  const mapUrl = mapboxCoordinates
    ? `${MAPBOX_STATIC_MAP_URL}${mapboxCoordinates[0]},${mapboxCoordinates[1]},14,0,0/360x180?access_token=${companyInfo.mapboxToken}`
    : null

  const mapDesktopUrl = mapboxCoordinates
    ? `${MAPBOX_STATIC_MAP_URL}${mapboxCoordinates[0]},${mapboxCoordinates[1]},14.1,0,0/440x90?access_token=${companyInfo.mapboxToken}`
    : null

  const handleSelectServiceType = (service) => {
    setSelectedServiceType(service)
  }

  const handleGoConfirmation = (selectedService) => {
    setServiceAndShippingcost(selectedService, setSelectedServiceType, setCartData, cartData)
    handleAddressInformation({
      serviceType: selectedService.serviceType,
      price: selectedService.price,
      courier: selectedService.courier,
      serviceName: selectedService.name
    })
    setOpenServiceTypeModal(false)
    shippingPostMessageWoocommerce()
  }

  const handleCloseModal = () => {
    setOpenServiceTypeModal(false)
  }

  /* const handleChangeServiceType = () => {
    setOpenServiceTypeModal(true)
    hideWoocommerceSummary()
  } */

  return (
    <>
      <ModalServiceType
        services={deliveryInfo.services ? deliveryInfo.services : []}
        serviceSelected={selectedServiceType.serviceType ? selectedServiceType.serviceType : ''}
        handleSelectServiceType={handleSelectServiceType}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        handlePayment={() => handleGoConfirmation(selectedServiceType)}
        handleClose={handleCloseModal}
        isOpen={openServiceTypeModal}
        className='bg-white'
        isFreeShipping={cartData.isFreeShipping}
      />
      <div className={`${ecommerceInfo.cms !== 'woocommerce' && 'md:bg-slate-100'} w-full md:w-[537px] md:h-full md:pt-5 md:pb-4 md:overflow-auto pt-1 flex flex-col`}>
        <div className='text-xs mx-[20px] md:mx-5 rounded-[10px] bg-white'>
          <ReturnPickupPoint />
        </div>
        <div className={`mt-[8px] bg-white mx-[20px] md:mx-5 rounded-[10px] pb-2 md:mt-2 ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-slate-100'}`}>
          <div className='flex items-center justify-between text-sm mx-3 md:mx-[20px] md:mx-[20px]  md:pb-1 pt-3'>

            <SectionTitle iconName='home' title='Dirección' className='md:ml-0' iconCustomStyle={{ fill: iconColor }} primaryColor={backgroundColor} />

            <div className='hidden md:block'>
              <div className='flex flex-col px-3 md:px-0 md:pr-[51px]'>
                <div className='text-xs pl-2 md:text-[9px]'>
                  {userAddress}
                </div>
                <div className='text-xs pl-2 text-slate-400 md:text-[9px]'>
                  {district}, {region}.
                </div>
              </div>
            </div>
            <div className='py-2 md:py-0'>
              <Button
                label={<span className='hidden md:block'>Cambiar</span>}
                LeftIcon={<Icon name='edit' className='fill-gray-800 mr-2' width={9} />}
                onClick={handleChangeClick}
                className={`flex items-center bg-slate-100 pr-2 md:px-4 py-1 rounded-2xl text-[11px] font-light h-[23px] w-auto ${userInfo.isRegistered ? 'hidden' : ''}`}
                testId='change-delivery'
              />
            </div>
          </div>
          {selectedPointData && (
            <div className='mx-2 mt-2 md:mt-0 md:rounded-[12px] md:mx-7 relative'>
              <img className='md:hidden m-auto' src={mapUrl} alt='address-map' />
              <img
                className='hidden md:block rounded-[12px] md:h-[97px]'
                src={mapDesktopUrl}
                alt='address-map'
              />
              <Icon
                name='radar'
                className='absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2'
                customStyle={{
                  fill: secondaryColor,
                  stroke: secondaryColor
                }}
              />
            </div>
          )}

          <div className='flex flex-col py-1 px-4 md:hidden font-normal'>
            <div className='text-[11px] md:text-sm pl-1 text-zinc-800'>{userAddress}</div>
            <div className='text-[11px] md:text-sm pl-1 text-gray-500'>
              {district}, {region}.
            </div>
          </div>
        </div>
        <div className='pt-[8px] md:pt-[10px] w-full flex flex-col font-poppins grow justify-start'>
          <div className={`bg-[#FFFFFF] md:rounded-[10px] md:mx-[20px] px-[20px] md:px-[28px] py-[18px] md:mx-5 text-center flex h-fit justify-start md:justify-between flex-col items-center grow md:grow-0 ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-slate-100'}`}>
            <div className={`bg-[color:#F5F9FA] rounded-[10px] mx-[12px] md:mx-[28px] px-4 md:px-[20px] py-3 w-full md:mx-5 flex h-fit justify-start flex-row  items-center ${(useTwoClick > 0) && 'md:bg-[color:#DFF6EB] text-center'}`}>
              <div className='flex-none mr-[10px] md:mr-[20px] md:pl-[20px]'>
                <Icon customStyle={{ fill: 'currentColor' }} name="package" width={50} className={`text-[#000000] hidden md:block ${(useTwoClick > 0) && 'md:text-[color:#00A657]'}`} />
                <Icon customStyle={{ fill: 'currentColor' }} name="package" width={40} className={`text-[#000000] block md:hidden ${(useTwoClick > 0) && 'md:text-[color:#00A657]'}`} />
              </div>
              <div className='flex flex-col flex-initial text-sm text-zinc-800 items-start grow'>
                <span className='font-inter md:text-[color:#394860] font-semibold text-start text-[12px] md:text-[10px]'>{addressInfo.serviceName}{(selectedServiceType.description || addressInfo.sla) ? ':' : ''}</span>
                <span className='font-inter md:text-[color:#394860] font-normal text-start text-[12px] md:text-[10px]'>{selectedServiceType.description || addressInfo.sla}</span>
              </div>
              <div className={`flex flex-col flex-initial text-sm text-zinc-800 items-end min-w-[70px] ${(useTwoClick > 0) && 'md:items-start'}`}>
                <span className='font-inter md:text-[color:#394860] text-[11px] md:text-[10px]'>Costo envío</span>
                <span className={`font-inter text-[15px] md:text-[12px] font-bold ${(useTwoClick > 0) && 'md:text-[color:#00A657]'}`}>
                  ${cartData.isFreeShipping
                  ? 0
                  : selectedServiceType.price
                    ? numberParser(selectedServiceType.price)
                    : numberParser(addressInfo.price)}
                </span>
              </div>
              {/* <div className='flex flex-col ml-[10px] justify-start hidden md:block'>
                {deliveryInfo?.services?.length > 1 && (
                  <Button
                    label=''
                    LeftIcon={<Icon name='edit' className={`${(useTwoClick > 0) ? 'fill-white' : 'fill-gray-800'}`} width={9} />}
                    onClick={handleChangeServiceType}
                    className={`flex items-center py-1 rounded-2xl text-[11px] font-light h-[23px] w-auto ${(useTwoClick > 0) ? 'shadow-md px-[0.75rem] bg-[#394860]' : 'bg-white px-4 '}`}
                    testId='change-serviceType'
                  />
                )}
              </div> */}
            </div>

            <div className='flex flex-col md:flex-row w-full justify-end pt-4 md:ml-[-1.25rem]'>
              <Button
                className='md:w-1/3 bg-cyan-500 rounded-lg md:rounded-3xl text-base text-white font-bold h-[50px] md:h-[37px]'
                label='Continuar'
                onClick={handlePayment}
                customStyle={{ backgroundColor: secondaryColor }}
                testId='go-checkout'
                id="delivery-payment-button"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

AddressConfirmation.propTypes = {
  selectedPointData: PropTypes.object,
  handleAddressInformation: PropTypes.func,
  mapCenter: PropTypes.object
}
