import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import { AddressConfirmation } from '../AddressConfirmation'
import AddressUserData from './AddressUserData'
import { ManualDirection } from './ManualDirection'

const AddressTypeInputSelector = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [selectedPointData, setSelectedPointData] = useState(undefined)
  const {
    steps,
    setAddressInfo,
    setSteps,
    addressInfo,
    setSelectedServiceType,
    setCartData,
    setSelectedShippingMethod,
    userInfo,
    isUserValidated
  } = useContext(DataContext)

  const handleAddressInformation = ({
    serviceType,
    price,
    courier,
    serviceName
  }) => {
    setAddressInfo(prevState => {
      return {
        ...prevState,
        price,
        courier,
        serviceType,
        serviceName
      }
    })
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = true
    updatedSteps[1].subSteps[1].completed = true
    updatedSteps[1].subSteps[2].completed = true
    setSteps(updatedSteps)
  }

  const setGiftCardProduct = (arrayAddress, aditionalInfo) => {
    setAddressInfo(prevState => {
      return {
        ...prevState,
        userAddress: arrayAddress[0],
        district: arrayAddress[1],
        region: arrayAddress[2],
        aditionalInfo,
        deliveryType: 'No aplica despacho',
        requireShipping: false,
        price: 0
      }
    })
    setSelectedServiceType(prevState => ({
      ...prevState,
      price: 0
    }))
    setSelectedShippingMethod('unnecessary')
    setCartData(prevState => ({
      ...prevState,
      isFreeShipping: true
    }))
    const updatedSteps = [...steps]

    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = true
    updatedSteps[1].subSteps[1].completed = true
    updatedSteps[1].subSteps[2].completed = true
    setSteps(updatedSteps)
  }

  useEffect(() => {
    const updatedAddressInfo = { ...addressInfo, manualDelivery: (userInfo.isRegistered && !isUserValidated) ? false : !selectedPointData }
    setAddressInfo(updatedAddressInfo)
  }, [selectedPointData])

  return (
    <div className='md:h-full'>
      {steps[1].subSteps[1].completed
        ? <AddressConfirmation selectedPointData={selectedPointData} handleAddressInformation={handleAddressInformation} />
        : <>
          {!isEditing
            ? (
              <AddressUserData setter={setIsEditing} setSelectedPointData={setSelectedPointData} handleAddressInformation={handleAddressInformation} setGiftCardProduct={setGiftCardProduct} />
              )
            : (
              <ManualDirection setter={setIsEditing} handleAddressInformation={handleAddressInformation} setGiftCardProduct={setGiftCardProduct} />
              )}
        </>
      }
    </div>
  )
}

export default AddressTypeInputSelector
