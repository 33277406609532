import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DataContext } from '../context/DataContext'
import { setPaymentInformationApi } from '../helpers/magentoApi'
import { processPayment } from '../helpers/processPayment'
import { Spinner, GeneralSelector, SectionTitle, Button } from '@pinflag/pinflag-ui-kit'
import { paymentLogos } from '../constants/payment-logos'
import { useMagento } from '../hook/useMagento'
import { useCmsAdapter } from '../hook/useCmsAdapter'
import { useVtex } from '../hook/useVtex'
import { useWoocommerce } from '../hook/useWoocommerce'
import { SHOPIFY_PLUS_STORES } from '../constants/stores'
import { PAYMENT_METHOD_TRANSLATION } from '../constants/translation'
import { useShopify } from '../hook/useShopify'
import { scrollToBottom } from '../helpers/utils'
import { gaCompletedPaymentSelectionPostMessage } from '../helpers/gaEventsHelper'

const PaymentMethodSelection = ({ scrapperClient }) => {
  const {
    setIsPickup,
    ecommerceInfo,
    userInfo,
    addressInfo,
    companyInfo,
    setIsPaying,
    steps,
    setSteps,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentMethods,
    cartData
  } = useContext(DataContext)

  const { getPaymentMethodsVtexIO, saveShippingInformationVtex, addPaymentDataVtex } = useVtex()
  const [purchaseAmount, setPurchaseAmount] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { primaryColor, companyName } = companyInfo
  const [selectedPaymentMethodData, setSelectedPaymentMethodData] = useState('')
  const { setShippingInfoMagento } = useMagento()
  const { trackingThirdStep } = useCmsAdapter()
  const { getPaymentMethodsWoocommerce } = useWoocommerce()
  const { getConfigurationsShopify, removeIframeShopify } = useShopify()
  const { goToPaymentVtexIO } = useVtex()

  useEffect(() => {
    setIsPickup(false)
    if (ecommerceInfo.cms === 'shopify' && !paymentMethods.length) {
      getConfigurationsShopify().then((res) => {
        if (!res) {
          removeIframeShopify({}, {}, 'redirect to checkout')
        } else {
          setIsLoading(false)
        }
      })
    }
    if (ecommerceInfo.cms === 'magento') {
      setShippingInfoMagento(setPurchaseAmount, setIsLoading)
    }
    saveShippingInformationVtex().then(() => {
      const updatedSteps = [...steps]
      updatedSteps[1].completed = true
      updatedSteps[2].disabled = false
      setSteps(updatedSteps)
      getPaymentMethodsVtexIO(ecommerceInfo.orderFormId)
    })
  }, [])

  useEffect(() => {
    if (paymentMethods.length) {
      setSelectedPaymentMethod(paymentMethods[0].code)
      setSelectedPaymentMethodData(paymentMethods[0])
      ecommerceInfo.cms !== 'shopify' && setIsLoading(false)
    } else {
      getPaymentMethodsWoocommerce()
    }
  }, [paymentMethods])

  useEffect(() => {
    if (!paymentMethods.length) return
    if (SHOPIFY_PLUS_STORES.includes(companyName) || (ecommerceInfo.cms !== 'shopify' && !ecommerceInfo.cms.includes('vtex'))) setIsLoading(false)
    if (scrapperClient.includes('information') || scrapperClient.includes('discountCodes')) {
      setTimeout(() => setIsLoading(false), 2000)
    }
    if (scrapperClient.includes('payment')) {
      setIsLoading(false)
    }
  }, [scrapperClient])

  useEffect(() => {
    const limitScrapper = 12000
    const timer = setTimeout(() => {
      if (isLoading) {
        removeIframeShopify({}, {}, 'redirect to checkout - scraper disconnected')
      }
    }, limitScrapper)

    return () => clearTimeout(timer)
  }, [isLoading])

  const handlePaymentMethod = (paymentMethod) => {
    setSelectedPaymentMethodData(paymentMethod)
    setSelectedPaymentMethod(paymentMethod.code)
    if (['Depósito Bancario', 'Transferencia Bancaria'].includes(paymentMethod.code)) {
      setTimeout(() => scrollToBottom('div-payment-methods'), 300)
    }
  }

  const setShippingInformationMagento = async () => {
    if (ecommerceInfo.cms !== '' && ecommerceInfo.cms !== 'magento') return

    const paymentMethod = await setPaymentInformationApi(
      ecommerceInfo.domain,
      ecommerceInfo.magentoQuoteId,
      userInfo,
      addressInfo,
      selectedPaymentMethod
    )

    const paymentData = {
      amount: purchaseAmount,
      ecommerceInfo
    }

    await processPayment[paymentMethod](paymentData)
  }

  const setShippingInformation = async () => {
    let groupName
    gaCompletedPaymentSelectionPostMessage()
    setShippingInformationMagento()
    setIsPaying(true)
    trackingThirdStep({
      paymentMethod: selectedPaymentMethod,
      timeEndThirdStep: new Date()
    })
    if (cartData.total === 0 && cartData.giftCard.applied > 0) {
      groupName = 'gift-card'
    } else {
      groupName = `#payment-group-${selectedPaymentMethodData.groupName}`
    }
    await addPaymentDataVtex(selectedPaymentMethodData, cartData.total)

    await goToPaymentVtexIO(groupName)
    window.parent.postMessage(
      {
        type: 'go-to-payment',
        origin: 'pinflag-checkout-iframe',
        message: { groupName }
      },
      '*'
    )
  }

  return (
        <>
            {isLoading
              ? <>
                <div className='full_width_spinner md:w-[537px]'>
                    <div className='flex flex-col gap-y-4 justify-center items-center mb-10'>
                        <Spinner width={44} />
                        <div className='text-gray-500 text-sm'>Obteniendo los métodos de pago disponibles...</div>
                    </div>
                </div>
            </>
              : <div className='md:h-full md:w-[537px] md:text-[11px]'>
                    <div className='flex flex-col md:h-full'>
                        <div className='bg-slate-100 h-full pb-8'>
                            <div className='md:mx-4'>
                                <div className='bg-white rounded-md mx-2 md:mt-3 flex flex-col justify-around py-6 md:pt-3'>
                                <SectionTitle iconName='credit-card' title='Método de Pago' className='ml-10 mb-5' primaryColor={primaryColor} />
                                    <div className="flex flex-col px-4 gap-y-3 md:w-[70%] m-auto">
                                        <div className='flex flex-col md:max-h-[290px] md:h-full overflow-y-auto div-payment-methods'>
                                          {paymentMethods && paymentMethods.map((method) => {
                                            return (
                                              <GeneralSelector
                                                color={primaryColor}
                                                title={(PAYMENT_METHOD_TRANSLATION[companyName] && PAYMENT_METHOD_TRANSLATION[companyName][method.title]) || method.title}
                                                onClick={() => handlePaymentMethod(method)}
                                                isSelected={selectedPaymentMethod === method.code}
                                                image={paymentLogos(companyName)[method.code]?.logo}
                                                logoWidth={paymentLogos(companyName)[method.code]?.width}
                                                key={method.title}
                                                subtitle={['Depósito Bancario', 'Transferencia Bancaria'].includes(method.code) ? 'Te daremos los datos bancarios en el próximo paso' : undefined}
                                                classNameContainer='mb-2'
                                              />
                                            )
                                          })}
                                        </div>
                                        <div className='flex flex-col md:flex-row w-full justify-end pt-4'>
                                          <Button
                                            role='button'
                                            className='md:w-1/3 bg-cyan-500 rounded-lg md:rounded-3xl text-base text-white font-bold h-[50px] md:h-[37px] py-1 px-4 cursor-pointer outline-none rounded-[40px] font-semibold'
                                            label='Ir a pagar'
                                            onClick={() => setShippingInformation()}
                                            disabled={selectedPaymentMethod === ''}
                                            customStyle={{ backgroundColor: companyInfo.secondaryColor }}
                                            testId='go-payment'
                                          />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
  )
}

PaymentMethodSelection.propTypes = {
  scrapperClient: PropTypes.string
}

export default PaymentMethodSelection
