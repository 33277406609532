import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { DataContext } from '../../context/DataContext'
import { GenericModal, PopUpCode } from '@pinflag/pinflag-ui-kit'
import { useVerify } from '../../hook/useVerify'

export const PopUpSendCode = ({ setHardLoginToken }) => {
  const {
    isEditing,
    setIsEditing,
    customerInfo,
    userInfo,
    setIsUserValidated
  } = useContext(DataContext)

  const { sendCustomerCode, verifyCustomerCode, getCustomerDataVerify } = useVerify()

  const maskedEmail = customerInfo.email

  const { email } = userInfo

  const [codeWindow, setCodeWindow] = useState(false)

  const [sessionId, setSessionId] = useState('')

  const handleEdit = () => {
    setIsEditing(false)
  }

  const handleSendCode = () => {
    setCodeWindow(!codeWindow)
    sendCustomerCode(email).then((response) => {
      setSessionId(response.data.sessionId)
    })
  }

  const handleReSendCode = (setErrorMessage, setReset, reset) => {
    sendCustomerCode(email).then((response) => {
      setSessionId(response.data.sessionId)
      setReset(!reset)
      setErrorMessage(true)
    })
  }

  const handleReturn = (setToken, setDisabled, setErrorMessage, setReset, reset) => {
    setCodeWindow(!codeWindow)
    setToken('')
    setSessionId('')
    setDisabled(true)
    setReset(!reset)
    setErrorMessage(true)
  }

  const handleVerifyCode = (token, setErrorMessage, setIsLoading) => {
    setIsLoading(true)
    verifyCustomerCode(token, sessionId).then((response) => {
      if (response.message === 'Validated') {
        setHardLoginToken(response.token)
        getCustomerDataVerify(response.token)
        setCodeWindow(!codeWindow)
        setIsEditing(false)
        setIsUserValidated(true)
        setIsLoading(false)
      } else if (response.message === 'Token expired') {
        setErrorMessage('Tu codigo ha expirado')
        setIsLoading(false)
      } else if (response.message === 'Invalid OTP or SessionId') {
        setErrorMessage('Pin incorrecto')
        setIsLoading(false)
      } else if (response.message === 'Cannot validate OTP') {
        setErrorMessage('Algo salió mal. Por favor vuelva a intentar')
        setIsLoading(false)
      }
    })
  }

  return (
    <div className={`${isEditing ? '' : 'hidden'}`}>

      <GenericModal
        isOpenModal={!codeWindow}
        icon={'error'}
        modalTitle={'Verificación requerida'}
        modalText={'Para realizar este cambio necesitamos validar tu identidad.'}
        firstButton={'Cancelar'}
        secondButton={'Enviar Código'}
        handleClose={handleEdit}
        handleAction={handleSendCode}
      />
      <PopUpCode
        isOpenModal={codeWindow}
        handleReturn={handleReturn}
        codeTitle={'Código de Verificación'}
        codeText={`Ingresa el código que enviamos al siguiente correo ${maskedEmail} para editar tus datos.`}
        handleVerifyCode={handleVerifyCode}
        handleReSendCode={handleReSendCode}
        firstButtonText={'Volver'}
        secondButtonText={'Continuar'}
        thirdButtonText={'Reenviar Código'}
      />
    </div>
  )
}

PopUpSendCode.propTypes = {
  setHardLoginToken: PropTypes.func
}
