import { useContext } from 'react'
import { DataContext } from '../context/DataContext'
import _ from 'lodash'
import {
  abreviatedCartLines,
  abreviatedCheckoutLines,
  appliedDiscountObject,
  extraAttributesParser,
  isValidCode,
  itemsUrlParser,
  parseDiscountCodes,
  totalPriceParser
} from '../helpers/utils'
import {
  billingInputParser,
  billingUrlParser,
  defaultDocumentParser,
  normalizeRegionName,
  regionCode,
  shippingAddressObject,
  shippingAddressParser
} from '../helpers/addressParser'
import { numberParser } from '../helpers/numberParser'
import { STORES_REDIRECT_SHIPPING_STEP, STORES_STOCKABLES, STORES_WITH_DEFAULT_DOCUMENT, STORES_WITH_REFACTOR_SCRAPER, STORES_WITH_THIRD_PARTY_RATES } from '../constants/stores'
import { buildMessage, removeIframeMessage, sendBotMessage } from '../helpers/bot'
import { useCmsAdapter } from './useCmsAdapter'
import { checkLocations, matchProductsLocations } from '../helpers/filterPoints'
import { discountCodesToValidate } from '../helpers/discount'
import { gaOpenedPinmapPostMessage } from '../helpers/gaEventsHelper'
import { applyChangesInPrices } from '../helpers/pricesData'
import { getConfigurationsLocalStorage, saveConfigurationsLocalStorage } from '../helpers/localStorage'

export const useShopify = () => {
  const {
    setCartData,
    addressInfo,
    userInfo,
    setEcommerceInfo,
    ecommerceInfo,
    companyInfo,
    setUserInfo,
    setAddressInfo,
    setPaymentMethods,
    setConfigurations,
    cartNotes,
    giftNotes,
    isGiftWrapping,
    billingInfo,
    cartData,
    setShopifyLocations,
    discountInfo,
    setDiscountInfo,
    setIsRemoved,
    isSaving,
    collectedScrapperInfo,
    selectedPaymentMethod,
    setDeletedCheckoutLog,
    deletedCheckoutLog,
    configurations,
    extraAttributesShopify,
    setExtraAttributesShopify
  } = useContext(DataContext)

  const {
    getCheckout,
    createCheckout,
    setItemsCheckout,
    updateEmailCheckout,
    updateAddressCheckout,
    getShippingRates,
    setShippingRate,
    sendCartNotesShopify,
    updateCustomAttributesShopify,
    deleteCheckoutLog,
    getVariantLocations,
    validateDiscountCodesShopify,
    availableShippingRates,
    validateDiscountForCustomerShopify,
    trackingLeak,
    validateGiftCartShopify,
    getShopifyAutomaticDiscounts,
    getShopifyConfigurations
  } = useCmsAdapter()
  const { discountCodes } = discountInfo

  const setInitialInformationShopify = (message, origin, setInitialInfoReady) => {
    if (message.cms !== 'shopify') return
    gaOpenedPinmapPostMessage()
    const {
      cms,
      checkoutId,
      cartItems,
      cartToken,
      currentUrl,
      totalWeight,
      loggedIn,
      customerData,
      cookies,
      discounts,
      extraAttributes
    } = message

    if (loggedIn) {
      const {
        names,
        email,
        phone,
        rut,
        userAddress,
        aditionalInfo,
        region,
        district
      } = customerData
      setUserInfo(prevState => {
        return {
          ...prevState,
          email: email || prevState.email,
          names: names || prevState.names,
          phone: phone || prevState.phone,
          rut: rut || prevState.rut
        }
      })
      setAddressInfo(prevState => {
        return {
          ...prevState,
          userAddress: userAddress || prevState.userAddress,
          aditionalInfo: aditionalInfo || prevState.aditionalInfo,
          region: region || prevState.region,
          district: district || prevState.district
        }
      })
    }

    if (extraAttributes) setExtraAttributesShopify(extraAttributes)
    setEcommerceInfo(prevState => {
      return {
        ...prevState,
        cms,
        checkoutId,
        currentUrl,
        domain: origin,
        cartToken,
        siteCookies: cookies
      }
    })

    if (cartItems.length === 0) {
      reloadPage()
      return
    }

    const cartProducts = cartItems.map(product => {
      return {
        id: product.id,
        variantId: product.variant_id,
        productImage: product.image,
        productName: product.product_title,
        quantity: product.quantity,
        lineTotal: numberParser(product.final_price / 100),
        final_line_price: product.final_line_price,
        originalLinePrice: product.original_line_price,
        requires_shipping: product.requires_shipping,
        properties: product.properties
      }
    })
    setCartData(prevState => {
      return {
        ...prevState,
        total: totalPriceParser(cartItems),
        subtotal: totalPriceParser(cartItems),
        lines: cartProducts,
        itemsCount: cartProducts.reduce(
          (sum, { quantity }) => sum + quantity,
          0
        ),
        totalWeight: Math.ceil(totalWeight / 1000),
        cartId: cartToken.split('?key=')[0]
      }
    })

    const initialDiscountsShopify = [...new Set(cartItems.flatMap(item => item.discounts.map(discount => discount.title)))]

    if (discounts || initialDiscountsShopify.length) {
      setDiscountInfo(prevState => {
        return {
          ...prevState,
          initialDiscounts: {
            discounts: discounts ? [...new Set(initialDiscountsShopify.concat(discounts))] : initialDiscountsShopify,
            applied: false
          }
        }
      })
    }
    setInitialInfoReady(true)
  }

  const reloadPage = () => {
    window.parent.postMessage(
      {
        type: 'reload-page',
        origin: 'pinflag-shopify-pinmap-pro',
        message: true
      },
      '*'
    )
  }

  const removeIframeShopify = (extraUserInfo = {}, extraAddressInfo = {}, reason) => {
    if (ecommerceInfo.cms !== 'shopify') return
    trackingLeak({
      causePinmapLeak: reason
    })
    setIsRemoved(true)
    const permalink = createPermalink(extraUserInfo, extraAddressInfo)
    removeIframeMessage(companyInfo, permalink, reason, collectedScrapperInfo)
    window.parent.postMessage(
      {
        type: 'redirect-to-checkout',
        origin: 'pinflag-shopify-pinmap-pro',
        message: permalink
      },
      '*'
    )
    userInfo.email && !deletedCheckoutLog && completeCheckoutLog(userInfo)
  }

  const redirectToHomeShopify = (reason) => {
    if (ecommerceInfo.cms !== 'shopify') return
    trackingLeak({
      causePinmapLeak: reason
    })
    setIsRemoved(true)
    window.parent.postMessage(
      {
        type: 'redirect-to-home',
        origin: 'pinflag-shopify-pinmap-pro',
        message: true
      },
      '*'
    )
  }

  const redirectToCartShopify = () => {
    if (ecommerceInfo.cms !== 'shopify') return
    setIsRemoved(true)
    window.parent.postMessage(
      {
        type: 'redirect-to-cart',
        origin: 'pinflag-shopify-pinmap-pro',
        message: true
      },
      '*'
    )
  }

  const loadCheckout = () => {
    if (ecommerceInfo.cms !== 'shopify') return
    const { checkoutId } = ecommerceInfo
    getCheckout(checkoutId)
      .then(res => {
        if (res.data.checkout === null || res.data.checkout.completedAt !== null) return

        const { id, email, lineItems, orderStatusUrl } = res.data.checkout

        if (lineItems?.edges?.some(line => line.node.variant === null)) return

        const parsedLineItems = lineItems?.edges?.map(line => {
          return {
            quantity: line.node.quantity,
            variantId: line.node.variant.id,
            id: line.node.id
          }
        })

        setEcommerceInfo(prevState => {
          return {
            ...prevState,
            currentCheckout: {
              id,
              email,
              lineItems: parsedLineItems,
              orderStatusUrl
            }
          }
        })
      })
      .catch(err => {
        removeIframeShopify({}, {}, 'redirect to checkout')
        console.log(err)
      })
  }

  const evaluateCartsEquity = (cart1, cart2) => {
    return _.isEmpty(_.xorWith(cart1, cart2, _.isEqual))
  }

  const updateLineItemsCheckout = async (userData) => {
    const { cms, checkoutId, currentCheckout } = ecommerceInfo
    if (cms !== 'shopify') return

    const cartsAreEquals = evaluateCartsEquity(
      abreviatedCheckoutLines(cartData.lines),
      currentCheckout.lineItems.map(l => {
        return { variantId: l.variantId, quantity: l.quantity }
      })
    )
    if (!cartsAreEquals) {
      try {
        const oldLineItemsId = currentCheckout.lineItems.map(l => l.id)
        const newLineItems = abreviatedCheckoutLines(cartData.lines)
        const response = await setItemsCheckout(
          checkoutId,
          oldLineItemsId,
          newLineItems
        )
        const { checkoutUserErrors } = response.data
        if (checkoutUserErrors.length) removeIframeShopify(userData, {}, 'redirect to checkout')
      } catch (error) {
        removeIframeShopify(userData, {}, 'redirect to checkout')
        console.log(error)
      }
    }
  }

  const handleUserInformationShopify = async userData => {
    // IF currentCheckout.orderStatusUrl !== null --> Order is already completed
    // Reference: https://shopify.dev/api/storefront/2022-07/objects/Checkout
    if (ecommerceInfo.cms !== 'shopify') return
    const { checkoutId, currentCheckout } = ecommerceInfo
    if (
      userData?.email === currentCheckout?.email &&
      checkoutId === currentCheckout?.id &&
      !currentCheckout?.orderStatusUrl
    ) {
      const proceedWithExistingEmail = async id => {
        const cartsAreEquals = evaluateCartsEquity(
          abreviatedCheckoutLines(cartData.lines),
          currentCheckout.lineItems.map(l => {
            return { variantId: l.variantId, quantity: l.quantity }
          })
        )
        if (!cartsAreEquals) {
          try {
            const oldLineItemsId = currentCheckout.lineItems.map(l => l.id)
            const newLineItems = abreviatedCheckoutLines(cartData.lines)
            const response = await setItemsCheckout(
              id,
              oldLineItemsId,
              newLineItems
            )
            const { checkoutUserErrors } = response.data
            if (checkoutUserErrors.length) removeIframeShopify(userData, {}, 'redirect to checkout')
          } catch (error) {
            removeIframeShopify(userData, {}, 'redirect to checkout')
            console.log(error)
          }
        }
      }
      const existingEmail = await proceedWithExistingEmail(checkoutId)
      return existingEmail
    }

    // Proceed with new checkout if checkout id does not exists or currentCheckout is already complete
    if (!currentCheckout?.id || currentCheckout?.orderStatusUrl) {
      const proceedWithNewCheckout = async formData => {
        const { email } = formData
        const input = {
          email,
          lineItems: await abreviatedCheckoutLines(cartData.lines)
        }
        try {
          const response = await createCheckout(input)
          const { checkout, checkoutUserErrors } = response.data
          if (checkoutUserErrors.length) {
            if (
              checkoutUserErrors.length > 1 ||
              checkoutUserErrors[0].code !== 'BAD_DOMAIN'
            ) { return removeIframeShopify(formData, {}, 'redirect to checkout') }

            return { error: checkoutUserErrors[0] }
          }
          setEcommerceInfo(prevState => {
            return {
              ...prevState,
              checkoutId: checkout.id
            }
          })
          window.parent.postMessage(
            {
              type: 'receive-checkout-id',
              origin: 'pinflag-shopify-pinmap-pro',
              message: checkout.id
            },
            '*'
          )
        } catch (err) {
          console.log(err)
        }
      }
      const newCheckout = await proceedWithNewCheckout(userData)
      return newCheckout
    }

    const proceedWithUpdatedEmail = async userData => {
      try {
        const responseUpdateEmail = await updateEmailCheckout(
          checkoutId,
          userData.email
        )
        const { checkout, checkoutUserErrors } = responseUpdateEmail.data
        if (checkoutUserErrors.length) {
          if (
            checkoutUserErrors.length > 1 ||
            checkoutUserErrors[0].code !== 'BAD_DOMAIN'
          ) { return removeIframeShopify(userData, {}, 'redirect to checkout') }

          return { error: checkoutUserErrors[0] }
        }
        const { id } = checkout
        window.parent.postMessage(
          {
            type: 'receive-checkout-id',
            origin: 'pinflag-shopify-pinmap-pro',
            message: id
          },
          '*'
        )
        setEcommerceInfo(prevState => {
          return { ...prevState, checkoutId: id }
        })
        const oldLineItemsId = currentCheckout.lineItems.map(l => l.id)
        const newLineItems = abreviatedCheckoutLines(cartData.lines)
        const responseSetItems = await setItemsCheckout(
          id,
          oldLineItemsId,
          newLineItems
        )
        const { checkoutUserErrors: errorsAddItems } = responseSetItems.data
        if (errorsAddItems.length) removeIframeShopify(userData, {}, 'redirect to checkout')
      } catch (err) {
        console.log(err)
      }
    }
    const updatedEmail = await proceedWithUpdatedEmail(userData)
    return updatedEmail
  }

  const iframeLoadedShopify = (isCorrect) => {
    window.parent.postMessage(
      {
        type: 'iframe-loaded',
        origin: 'pinflag-shopify-pinmap-pro',
        message: isCorrect
      },
      '*'
    )
  }

  const saveShippingInformationShopify = async () => {
    const { cms, checkoutId } = ecommerceInfo
    if (cms !== 'shopify') return

    let storeConfig = configurations
    if (!Object.keys(configurations)?.length) storeConfig = getConfigurationsShopify()
    const shippingAddress = shippingAddressObject(
      userInfo,
      addressInfo,
      storeConfig
    )
    try {
      sendCartNotesShopify(checkoutId, cartNotes, isGiftWrapping, giftNotes)
      saveBillingDataShopify()
      await updateAddressCheckout(checkoutId, shippingAddress)
      const response = await getShippingRates(checkoutId)
      const { availableShippingRates, webUrl } = response.data.checkout
      if (availableShippingRates && availableShippingRates.ready) {
        const { shippingRates } = availableShippingRates
        const pinflagShippingRate = shippingRates
          ? shippingRates.find(rate => rate.handle.includes('Pinflag'))
          : undefined
        if (pinflagShippingRate) {
          handleSetShippingRates(pinflagShippingRate)
        } else {
          const message = buildMessage(shippingRates, cartData, companyInfo, addressInfo)
          sendBotMessage(message)
          const customUrl = webUrl.replace(
            '?key',
            '/shipping_rates?step=shipping_method&key'
          )
          window.parent.postMessage(
            {
              type: 'redirect-to-checkout',
              origin: 'pinflag-shopify-pinmap-pro',
              message: customUrl
            },
            '*'
          )
        }
      }
    } catch (err) {
      console.log('error handleFinish')
      removeIframeShopify({}, {}, 'error in final loader - redirect to checkout')
      console.log(err)
    }
  }

  const handleSetShippingRates = shippingRate => {
    setShippingRate(ecommerceInfo.checkoutId, shippingRate.handle).then(res => {
      if (!res.data) throw new Error('Checkout null')
      const { webUrl } = res.data.checkout
      const step = STORES_REDIRECT_SHIPPING_STEP.includes(
        companyInfo.companyName
      )
        ? 'shipping'
        : 'payment'
      const customUrl = webUrl.replace(
        '?key',
        `/shipping_rates?step=${step}_method&key`
      )
      window.parent.postMessage(
        {
          type: 'redirect-to-checkout',
          origin: 'pinflag-shopify-pinmap-pro',
          message: customUrl
        },
        '*'
      )
    })
  }

  const saveBillingDataShopify = async () => {
    if (!billingInfo.corporateName) return

    const input = billingInputParser(billingInfo)
    await updateCustomAttributesShopify(ecommerceInfo.checkoutId, input)
  }

  const createBillingUrl = (companyName) => {
    return billingInfo.corporateName
      ? `&${billingUrlParser(billingInfo, companyName)}`
      : STORES_WITH_DEFAULT_DOCUMENT.includes(companyName)
        ? defaultDocumentParser(userInfo.rut, companyName)
        : ''
  }

  const createPermalink = (extraUserInfo, extraAddressInfo) => {
    const user = { ...userInfo, ...extraUserInfo }
    const address = { ...addressInfo, ...extraAddressInfo }
    const { email } = user
    const { domain } = ecommerceInfo
    const { serviceName } = address
    const { companyName, useTwoClick } = companyInfo

    let storeConfig = configurations
    if (!Object.keys(configurations)?.length) storeConfig = getConfigurationsShopify()
    const shippingAddress = shippingAddressObject(user, address, storeConfig)

    if (Object.keys(cartData.lines).length === 0) return `https://${domain}/checkout`

    const isGiftCardProduct = cartData.lines.every(item => !item.requires_shipping)

    const baseUrl = `https://${domain}/cart/`
    const itemsUrl = itemsUrlParser(cartData.lines)
    const emailUrl = email ? `?checkout[email]=${email}` : '?'
    const shippingAddressUrl = shippingAddressParser(shippingAddress, isGiftCardProduct ? 'billing_address' : 'shipping_address')
    const billingUrl = createBillingUrl(companyName)
    const serviceNameCustomUrl = serviceName ? `&attributes[serviceName]=${serviceName}` : ''
    const cartNote = cartNotes ? `&note=${cartNotes}` : ''
    const payment = selectedPaymentMethod ? `&attributes[paymentMethod]=${selectedPaymentMethod}` : ''
    const discount = discountCodes.length ? `&discounts=${parseDiscountCodes(discountCodes)}` : ''
    const giftCardProduct = isGiftCardProduct ? '&isGiftCardProduct=true' : ''
    const userAgreement = useTwoClick > 0 ? `&attributes[userAgreement]=${isSaving}` : ''
    const extraAttributes = extraAttributesParser(extraAttributesShopify)

    return `${baseUrl}${itemsUrl}${emailUrl}${shippingAddressUrl}${billingUrl}${serviceNameCustomUrl}${cartNote}${payment}${discount}${userAgreement}${giftCardProduct}${extraAttributes}`
  }

  const createCookieValue = () => {
    const { district, region } = addressInfo

    return JSON.stringify({
      state: regionCode[normalizeRegionName(region)],
      city: district
    })
  }

  const createScrapperURL = () => {
    const { companyName, useTwoClick } = companyInfo

    const baseUrl = '/checkout?'
    const itemsUrl = itemsUrlParser(cartData.lines)
    const billingUrl = createBillingUrl(companyName)
    const cartNote = cartNotes ? `&note=${cartNotes}` : ''
    const userAgreement = useTwoClick > 0 ? `&attributes[userAgreement]=${isSaving}` : ''
    const extraAttributes = extraAttributesParser(extraAttributesShopify)

    if (STORES_WITH_REFACTOR_SCRAPER.includes(companyName)) return `/cart/${itemsUrl}?${billingUrl}${userAgreement}${cartNote}${extraAttributes}`
    return `${baseUrl}${billingUrl}${userAgreement}${cartNote}${extraAttributes}`
  }

  const saveCartTotalShopify = () => {
    const { subtotal, discount } = cartData

    window.parent.postMessage(
      {
        type: 'save-cart-total',
        origin: 'pinflag-shopify-pinmap-pro',
        message: { cartTotal: parseInt(subtotal - discount, 10) }
      },
      '*'
    )
  }

  const redirectPermalink = async (response) => {
    let permalink, cookies
    if (response) {
      permalink = response.paymentUrl
      cookies = response.cookies
    } else {
      permalink = createPermalink()
      cookies = createCookieValue()
    }

    const { email } = userInfo
    window.parent.postMessage(
      {
        type: 'redirect-to-checkout',
        origin: 'pinflag-shopify-pinmap-pro',
        message: { permalink, cookies, email }
      },
      '*'
    )
  }

  const parsePaymentMethods = (paymentMethods) => {
    return paymentMethods.map(method => ({ title: method, code: method }))
  }

  const fetchConfigurations = async () => {
    const { pinflagApiKey } = companyInfo
    try {
      const response = await getShopifyConfigurations()
      const paymentMethods = parsePaymentMethods(response.data.shopifyConfiguration.paymentMethods)

      const configurations = { ...response.data.shopifyConfiguration, paymentMethods }

      setPaymentMethods(paymentMethods)
      setConfigurations(configurations)
      saveConfigurationsLocalStorage(configurations, pinflagApiKey)

      return configurations
    } catch {
      return false
    }
  }

  const getConfigurationsShopify = async () => {
    if (ecommerceInfo.cms !== 'shopify') return
    const { pinflagApiKey } = companyInfo

    const localStorageInfo = getConfigurationsLocalStorage(pinflagApiKey)

    if (localStorageInfo) {
      setConfigurations(localStorageInfo)
      setPaymentMethods(localStorageInfo.paymentMethods)
      return localStorageInfo
    }

    const configurations = await fetchConfigurations()

    return configurations
  }

  const completeCheckoutLog = async (userInfo) => {
    if (ecommerceInfo.cms !== 'shopify') return
    setDeletedCheckoutLog(true)
    const { email } = userInfo
    await deleteCheckoutLog(email)
  }

  const checkUpdatedCart = (message) => {
    if (message.error) {
      console.log('error', message.error)
      return
    }

    setCartData(prevState => {
      const cartProducts = message.items.map(product => {
        if (product.quantity > 0) {
          return {
            id: product.id,
            variantId: product.variant_id,
            productImage: product.image,
            productName: product.product_title,
            quantity: product.quantity,
            lineTotal: numberParser(product.final_price / 100),
            final_line_price: product.final_line_price,
            originalLinePrice: product.original_line_price,
            requires_shipping: product.requires_shipping,
            properties: product.properties
          }
        }
        return false
      }).filter(product => product)
      return {
        ...prevState,
        total: (message.total_price / 100),
        subtotal: (message.total_price / 100),
        lines: cartProducts,
        itemsCount: message.item_count,
        discount: 0,
        totalWeight: Math.ceil(message.total_weight / 1000)
      }
    })

    if (message.discounts) {
      setDiscountInfo(prevState => {
        return {
          ...prevState,
          discountCodes: [],
          initialDiscounts: { discounts: message.discounts, applied: false }
        }
      })
    }
  }

  const availableShopifyLocations = async () => {
    const { cms } = ecommerceInfo
    const { companyName } = companyInfo

    if (cms !== 'shopify' || !STORES_STOCKABLES.includes(companyName)) return { disabled: false, locations: [] }

    if (cartData.lines.length > 6) {
      setShopifyLocations({ disabled: true, locations: [] })
      return { disabled: true, locations: [] }
    }
    const enabledLocations = []
    try {
      for (let index = 0; index < cartData.lines.length; index++) {
        const product = cartData.lines[index]
        const response = await getVariantLocations(product.variantId)
        const { edges } =
          response.data.data.productVariant.inventoryItem.inventoryLevels
        const points = checkLocations(edges, product.quantity)
        enabledLocations.push(points)
      }
      const locations = matchProductsLocations(enabledLocations)

      if (!locations.length) {
        setShopifyLocations({ disabled: true, locations: [] })
        return { disabled: true, locations: [] }
      }
      let filteredLocations
      if (companyName === 'Atakama Outdoor' && locations.includes('gid://shopify/Location/74951786731')) {
        filteredLocations = locations.filter(location => location !== 'gid://shopify/Location/35426271328')
      } else {
        filteredLocations = locations
      }
      setShopifyLocations({
        disabled: false,
        locations: filteredLocations
      })
      return { disabled: false, locations: filteredLocations }
    } catch (err) {
      console.error(err)
      return { disabled: false, locations: [] }
    }
  }

  const addDiscountCodeShopify = async (code) => {
    if (ecommerceInfo.cms !== 'shopify') return

    if (!companyInfo.useDiscountCode) {
      return { valid: false, error: 'Código inválido' }
    }

    const lineItems = abreviatedCartLines(cartData.lines)
    const titleCodes = discountCodes
      .filter(discount => discount.type === 'coupon')
      .map(discount => discount.code)
    try {
      const response = await validateDiscountCodesShopify([...titleCodes, code], lineItems)
      const { cart, userErrors } = response.data
      if (userErrors.length) return { valid: false, error: 'Inténtelo más tarde' }

      updateCartSummary(cart)

      if (cart.discountCodes.some(discountCode => isValidCode(discountCode, code))) {
        return { valid: true, error: false }
      }
      return { valid: false, error: 'Código inválido' }
    } catch (error) {
      return { valid: false, error: 'Inténtelo más tarde' }
    }
  }

  const isAutomaticDiscountCombinable = (discountCodes, newAutomaticDiscounts) => {
    const discountCodesTypes = discountCodes.map(discountCode => discountCode.discountClass)

    return newAutomaticDiscounts.some(automaticDiscount => {
      if (discountCodesTypes.includes('ORDER') && !automaticDiscount.combinesWith.orderDiscounts) {
        return false
      }
      if (discountCodesTypes.includes('PRODUCT') && !automaticDiscount.combinesWith.productDiscounts) {
        return false
      }
      return true
    })
  }

  const isFreeShippingWithAutomaticDiscounts = (discountCodes, isFreeShipping, newAutomaticDiscounts) => {
    if (!newAutomaticDiscounts.length || !discountCodes.length || isFreeShipping) return isFreeShipping

    if (discountCodes.some(discountCode => !discountCode.combinesWith.shippingDiscounts)) {
      return false
    }

    return isAutomaticDiscountCombinable(discountCodes, newAutomaticDiscounts)
  }

  const updateCartSummary = async (cart) => {
    const newAutomaticDiscounts = await searchShopifyAutomaticDiscounts()

    const newDiscountCodes = cart.discountCodes
      .filter(discountCode => discountCode.applicable)
      .map(discountCode => ({ ...discountCode, type: 'coupon' }))
    setDiscountInfo(prevState => {
      const giftCardArray = prevState.discountCodes.filter(discountCode => discountCode.type === 'gift-card')
      return {
        ...prevState,
        discountCodes: [...newDiscountCodes, ...giftCardArray]
      }
    })
    const newTotal = parseInt(cart.cost.totalAmount.amount)
    const discount = cartData.subtotal - newTotal
    const isFreeShippingCode = newDiscountCodes.some(discountCode => discountCode.isFreeShipping)
    const isFreeShipping = newAutomaticDiscounts.length
      ? isFreeShippingWithAutomaticDiscounts(cart.discountCodes, isFreeShippingCode, newAutomaticDiscounts)
      : isFreeShippingCode

    setCartData(prevState => {
      const isInfoChanged = discount !== prevState.discount || isFreeShipping !== prevState.isFreeShipping
      const appliedShipping = (!prevState.shippingCost || isFreeShipping) ? 0 : prevState.shippingCost
      return {
        ...prevState,
        discount,
        isFreeShipping,
        total: !isInfoChanged ? newTotal + appliedShipping : prevState.total
      }
    })
  }

  const removeDiscountCodeShopify = async (selectedCode) => {
    if (ecommerceInfo.cms !== 'shopify') return

    const lineItems = abreviatedCartLines(cartData.lines)
    const filteredCodes = discountCodes
      .filter(discount => discount.type === 'coupon')
      .filter(discount => discount.code !== selectedCode)
    const filteredTitleCodes = filteredCodes.map(discount => discount.code)
    try {
      const response = await validateDiscountCodesShopify(filteredTitleCodes, lineItems)
      const { cart } = response.data
      updateCartSummary(cart)
    } catch (error) {
      console.log('error', error)
    }
  }

  const calculateShippingRates = async (addressInfo, companyName, encryptedAddressData) => {
    try {
      if (!STORES_WITH_THIRD_PARTY_RATES.includes(companyName)) throw new Error('Store not found')
      let storeConfig = configurations
      if (!Object.keys(configurations)?.length) storeConfig = getConfigurationsShopify()
      const response = await availableShippingRates(
        {
          appliedDiscount: appliedDiscountObject(cartData.discount),
          lineItems: abreviatedCheckoutLines(cartData.lines),
          shippingAddress: shippingAddressObject(userInfo, addressInfo, storeConfig)
        },
        encryptedAddressData
      )
      const { availableShippingRates: shippingRates } = response.data.calculatedDraftOrder
      return shippingRates
    } catch (error) {
      return []
    }
  }

  const checkNewDiscountCodes = async (newDiscountCodes) => {
    const oldDiscountCodes = discountCodes.filter(discount => discount.type === 'coupon')
    const lineItems = abreviatedCartLines(cartData.lines)
    if (newDiscountCodes.length !== oldDiscountCodes.length) {
      setDiscountInfo(prevState => {
        return {
          ...prevState,
          discountNotification: 'Los códigos de descuentos han sido modificados'
        }
      })
      setTimeout(() => setDiscountInfo(prevState => {
        return {
          ...prevState,
          discountNotification: ''
        }
      }), 5000)
    }

    try {
      const response = await validateDiscountCodesShopify(newDiscountCodes, lineItems)
      const { cart } = response.data
      updateCartSummary(cart)
    } catch (error) {
      console.log('error:', error)
    }
  }

  const validateDiscountCodesForCustomer = async (email) => {
    if (ecommerceInfo.cms !== 'shopify') return

    const coupons = discountCodes.filter(discount => discount.type === 'coupon')

    if (!coupons.length) {
      await applyFreeShippingAutomaticDiscount()
      return
    }

    try {
      const validatedDiscountCodes = await Promise.all(
        discountCodesToValidate(coupons).map(async (discount) => {
          const { discountInfo: discountData, discountType } = discount
          if (discountType === 'multiple-for-all') return discountData
          if (discountType === 'multiple-for-customer') {
            const applicable = discountData.customers.some(customer => customer.node.email === email)
            return { ...discountData, applicable }
          }
          const response = await validateDiscountForCustomerShopify(discountData, email, discountType)
          return response.data
        }))

      const filteredCodes = validatedDiscountCodes.filter(discount => discount.applicable).map(discount => discount.code)
      await checkNewDiscountCodes(filteredCodes)
    } catch (error) {
      console.log('error', error)
    }
  }

  const addGiftCardShopify = async (code) => {
    if (ecommerceInfo.cms !== 'shopify') return

    if (!companyInfo.useGiftCard) {
      return { valid: false, error: 'Código inválido' }
    }

    try {
      const lastCharacters = code.slice(-4)
      const response = await validateGiftCartShopify(lastCharacters)
      const { isValid, giftCard } = response.data
      if (isValid) {
        setDiscountInfo(prevState => {
          return {
            ...prevState,
            discountCodes: [...prevState.discountCodes, { code: `••••${lastCharacters.toUpperCase()}`, type: 'gift-card', originalCode: code }]
          }
        })
        applyChangesInPrices(giftCard, null, cartData, setCartData)
        return { valid: true, error: false }
      }
      return { valid: false, error: 'Código inválido' }
    } catch (error) {
      return { valid: false, error: 'Inténtelo más tarde' }
    }
  }

  const removeGiftCardShopify = (code) => {
    if (ecommerceInfo.cms !== 'shopify') return

    const lastCharacters = code.slice(-4).toLowerCase()
    const { giftCard } = cartData
    const cardInfo = giftCard.cards.find(card => card.last_characters === lastCharacters)
    applyChangesInPrices(null, cardInfo, cartData, setCartData)
  }

  const applyFreeShippingAutomaticDiscount = async () => {
    const discounts = await searchShopifyAutomaticDiscounts()

    const isFreeShipping = discounts.some(discount => discount.__typename === 'DiscountAutomaticFreeShipping')

    setCartData(prevState => ({
      ...prevState,
      isFreeShipping
    }))
  }

  const searchShopifyAutomaticDiscounts = async () => {
    // IMPORTANT: ONLY FREE SHIPPING AUTOMATIC DISCOUNTS ARE CONSULTED HERE
    // THE ENDPOINT AND THIS CODE CAN BE EXTENDED FOR OTHER AUTOMATIC DISCOUNTS TYPES
    try {
      const discounts = await getShopifyAutomaticDiscounts(cartData.subtotal, cartData.itemsCount)

      return discounts.data
    } catch {
      return []
    }
  }

  return {
    setInitialInformationShopify,
    handleUserInformationShopify,
    saveShippingInformationShopify,
    redirectToHomeShopify,
    loadCheckout,
    redirectToCartShopify,
    iframeLoadedShopify,
    removeIframeShopify,
    redirectPermalink,
    getConfigurationsShopify,
    createPermalink,
    completeCheckoutLog,
    sendCartNotesShopify,
    updateLineItemsCheckout,
    checkUpdatedCart,
    availableShopifyLocations,
    addDiscountCodeShopify,
    removeDiscountCodeShopify,
    calculateShippingRates,
    validateDiscountCodesForCustomer,
    createScrapperURL,
    addGiftCardShopify,
    removeGiftCardShopify,
    saveCartTotalShopify,
    checkNewDiscountCodes,
    applyFreeShippingAutomaticDiscount
  }
}
