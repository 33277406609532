import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {
  const [steps, setSteps] = useState([
    {
      label: 'Identificación',
      stepId: 1,
      completed: false,
      disabled: false,
      subSteps: []
    },
    {
      label: 'Entrega',
      stepId: 2,
      completed: false,
      disabled: true,
      subSteps: [
        {
          label: 'Method',
          subStepId: 2.1,
          completed: false
        },
        {
          label: 'Address',
          subStepId: 2.2,
          completed: false
        },
        {
          label: 'Confirmation',
          subStepId: 2.3,
          completed: false
        }
      ]
    },
    {
      label: 'Pago',
      stepId: 3,
      completed: false,
      disabled: true,
      subSteps: []
    }
  ])

  const [selectedShippingMethod, setSelectedShippingMethod] = useState(null)

  const [selectedServiceType, setSelectedServiceType] = useState({})

  const [deliveryInfo, setDeliveryInfo] = useState({}) // services

  const [currentStep, setCurrentStep] = useState(1)

  const [userInfo, setUserInfo] = useState({
    email: '',
    names: '',
    phone: '',
    rut: '',
    saveData: false,
    isRegistered: false,
    encryptedData: ''
  })

  const [addressInfo, setAddressInfo] = useState({
    userAddress: '',
    aditionalInfo: '',
    region: '',
    district: '',
    price: '',
    deliveryType: '',
    courier: '',
    serviceType: '',
    sla: '',
    manualDelivery: null,
    serviceName: '',
    requireShipping: true
  })

  const [customerInfo, setCustomerInfo] = useState({})

  const [billingInfo, setBillingInfo] = useState({
    corporateName: '',
    category: '',
    corporateRut: '',
    corporatePhone: '',
    address: '',
    district: ''
  })

  const [isPaying, setIsPaying] = useState(false)

  const [ecommerceInfo, setEcommerceInfo] = useState({
    cms: '',
    vtexOrderFormId: '',
    checkoutId: '',
    shopifyCurrentCheckout: {},
    domain: '',
    magentoQuoteId: '',
    currentUrl: '',
    loggedIn: false,
    siteCookies: ''
  })

  const [companyInfo, setCompanyInfo] = useState({
    logoUrl: '',
    primaryColor: '#394860',
    secondaryColor: '#00B7F9',
    companyName: '',
    userId: ''
  })

  const [cartData, setCartData] = useState({
    lines: [],
    subtotal: 0,
    total: 0,
    shippingCost: null,
    itemsCount: 0,
    totalWeight: 0,
    discount: 0,
    giftCard: { total: 0, applied: 0, cards: [] },
    isFreeShipping: false
  })

  const [isPickup, setIsPickup] = useState(false)

  const [pointsData, setPointsData] = useState({
    localPoints: [],
    pinflagPoints: [],
    localPointSelected: {},
    pinflagPointSelected: {}
  })

  const [paymentMethods, setPaymentMethods] = useState([])

  const [configurations, setConfigurations] = useState({})

  const [cartNotes, setCartNotes] = useState('')

  const [giftNotes, setGiftNotes] = useState('')

  const [isGiftWrapping, setIsGiftWrapping] = useState(false)

  const [isEditingDiscountCode, setIsEditingDiscountCode] = useState(false)

  const [shopifyLocations, setShopifyLocations] = useState({
    disabled: false,
    locations: []
  })

  const [loaderInfo, setLoaderInfo] = useState([
    {
      imageUrl: 'https://did-you-know-image.s3.sa-east-1.amazonaws.com/woman-loader.png',
      backgroundColor: '#F4F7FA',
      body: 'La generación X compra más online que los Baby Boomers y Millennials',
      title: 'Sabías que?'
    }
  ])

  const [isLoadingLoaderInfo, setIsLoadingLoaderInfo] = useState(true)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')

  const [isSummaryOpen, setIsSummaryOpen] = useState(false)

  const [discountInfo, setDiscountInfo] = useState({
    discountCodes: [],
    discountNotification: '',
    discountError: undefined,
    initialDiscounts: {
      discounts: [],
      applied: true
    }
  })

  const [isRemoved, setIsRemoved] = useState(false)

  const [isSaving, setIsSaving] = useState(true)

  const [isEditing, setIsEditing] = useState(false)

  const [isShowingMap, setIsShowingMap] = useState(false)

  const [isUserValidated, setIsUserValidated] = useState(false)

  const [isAddingAddress, setIsAddingAddress] = useState(false)

  const [count, setCount] = useState(0)

  const [options, setOptions] = useState([])
  const [collectedScrapperInfo, setCollectedScrapperInfo] = useState({
    joined: [],
    privateMessages: [],
    paymentUrl: '',
    socketMessages: []
  })

  const [deletedCheckoutLog, setDeletedCheckoutLog] = useState(false)

  const [extraAttributesShopify, setExtraAttributesShopify] = useState({})

  return (
    <DataContext.Provider
      value={{
        steps,
        setSteps,
        currentStep,
        setCurrentStep,
        userInfo,
        setUserInfo,
        ecommerceInfo,
        setEcommerceInfo,
        isPaying,
        setIsPaying,
        cartData,
        setCartData,
        addressInfo,
        setAddressInfo,
        selectedShippingMethod,
        setSelectedShippingMethod,
        isPickup,
        setIsPickup,
        pointsData,
        setPointsData,
        cartNotes,
        setCartNotes,
        billingInfo,
        setBillingInfo,
        paymentMethods,
        setPaymentMethods,
        configurations,
        setConfigurations,
        shopifyLocations,
        setShopifyLocations,
        companyInfo,
        setCompanyInfo,
        loaderInfo,
        setLoaderInfo,
        selectedServiceType,
        setSelectedServiceType,
        deliveryInfo,
        setDeliveryInfo,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        giftNotes,
        setGiftNotes,
        isGiftWrapping,
        setIsGiftWrapping,
        isSummaryOpen,
        setIsSummaryOpen,
        isRemoved,
        setIsRemoved,
        customerInfo,
        setCustomerInfo,
        isSaving,
        setIsSaving,
        isEditing,
        setIsEditing,
        isShowingMap,
        setIsShowingMap,
        isUserValidated,
        setIsUserValidated,
        options,
        setOptions,
        collectedScrapperInfo,
        setCollectedScrapperInfo,
        isAddingAddress,
        setIsAddingAddress,
        count,
        setCount,
        deletedCheckoutLog,
        setDeletedCheckoutLog,
        discountInfo,
        setDiscountInfo,
        isEditingDiscountCode,
        setIsEditingDiscountCode,
        isLoadingLoaderInfo,
        setIsLoadingLoaderInfo,
        extraAttributesShopify,
        setExtraAttributesShopify
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

DataProvider.propTypes = {
  children: PropTypes.node.isRequired
}
