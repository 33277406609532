export const discountCodesToValidate = (discountCodes) => {
  return discountCodes.map(discount => {
    let type = ''
    discount.isOnePerCustomer ? type += 'one-per-customer-' : type += 'multiple-'
    if (discount.isForAllCustomer) type += 'for-all'
    if (discount.customers.length) type += 'for-customer'
    if (discount.segments.length) type += 'for-segment'

    return { discountType: type, discountInfo: discount }
  })
}
