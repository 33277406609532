import axios from 'axios'

import { API_BASE_URL } from '../constants/url'

const apiInstance = (pinflagApiKey) =>
  axios.create({
    baseURL: API_BASE_URL,
    headers: { 'x-api-key': pinflagApiKey },
    timeout: 7000
  })

export default apiInstance
