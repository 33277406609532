import React, { useContext } from 'react'
import { DataContext } from '../../context/DataContext'
import { PopUpLocation } from '@pinflag/pinflag-ui-kit'
import { MAPBOX_STATIC_MAP_URL } from '../../constants/url'

export const PopUpUniqueStore = () => {
  const {
    isShowingMap,
    setIsShowingMap,
    pointsData,
    companyInfo
  } = useContext(DataContext)

  const { secondaryColor } = companyInfo

  const {
    localPoints
  } = pointsData

  const handleShowMap = () => {
    setIsShowingMap(!isShowingMap)
  }

  return (
    <PopUpLocation
      isOpenModal={isShowingMap}
      handleClose={handleShowMap}
      mapboxUrl={MAPBOX_STATIC_MAP_URL}
      secondaryColor={secondaryColor}
      pointsData={pointsData}
      mapboxToken={companyInfo.mapboxToken}
      localPoints={localPoints}
    />
  )
}
