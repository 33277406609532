import { useContext } from 'react'

import { backupStates } from '../constants/states'
import { DataContext } from '../context/DataContext'
import { normalizeRegionName, regionIdApi, regionBillingCode } from '../helpers/addressParser'
import companyApiInstance from '../helpers/companyApiInstance'
import apiInstance from '../helpers/apiInstance'
import { filterPoints, splitPointsByType } from '../helpers/filterPoints'
import {
  companyInfoAvailable,
  isLocalStorageAvailable,
  setCompanyLocalStorage,
  getUserInfoLocalStorage,
  getLoaderInfoLocalStorage,
  setLoaderInfoLocalStorage
} from '../helpers/localStorage'
import { getBackupRate, getPinflagApiKey, decryptToken } from '../helpers/utils'
import { filterServices, joinShippingRates } from '../helpers/filterServices'
import { STORES_DEFAULT_WEIGHT } from '../constants/stores'
import { errorMessage } from '../helpers/bot'
import { loadPinmap } from '../helpers/abTest'

import { useShopify } from './useShopify'

export const useApiPinflag = () => {
  const {
    cartData,
    setPointsData,
    shopifyLocations,
    setCompanyInfo,
    companyInfo,
    setLoaderInfo,
    setIsSummaryOpen,
    userInfo,
    setUserInfo,
    setCustomerInfo,
    isAddingAddress,
    isUserValidated,
    setIsLoadingLoaderInfo
  } = useContext(DataContext)

  const { iframeLoadedShopify, availableShopifyLocations, calculateShippingRates } = useShopify()

  const preloadUserInfo = () => {
    setUserInfo(prevState => ({
      ...prevState,
      ...getUserInfoLocalStorage()
    }))
  }

  const getCompanyInfo = () => {
    const pinflagApiKey = getPinflagApiKey()
    getFinalLoaderInfo(pinflagApiKey)
    if (isLocalStorageAvailable()) {
      const companyLocalStorage = companyInfoAvailable(pinflagApiKey)
      if (companyLocalStorage && companyLocalStorage.mapboxToken) {
        if (companyLocalStorage.useTwoClick === 0) preloadUserInfo()
        iframeLoadedShopify(loadPinmap(companyLocalStorage.checkout))
        return setCompanyInfo((prevState) => {
          return {
            ...prevState,
            ...companyLocalStorage
          }
        })
      }
    }
    if (pinflagApiKey) {
      companyApiInstance(pinflagApiKey)
        .get('/company/personalization', {
          cache: {
            maxAge: 5 * 60 * 1000, // override default cache duration for this request
            exclude: { query: false } // don't include query parameters in cache key
          }
        })
        .then((res) => {
          iframeLoadedShopify(loadPinmap(res.data.checkout))
          let labelPricePickup = res.data.storePickupPriceLabel
          if (!isNaN(parseInt(res.data.storePickupPriceLabel))) labelPricePickup = '$' + res.data.storePickupPriceLabel
          const companyObject = {
            logoUrl: res.data.logoUrl,
            primaryColor: res.data.primaryColor || '#394860',
            secondaryColor: res.data.secondaryColor || '#00B7F9',
            companyName: res.data.company.nombreempresa,
            userId: res.data.company.userId,
            pinflagApiKey,
            ccDiscountPercentageLabel: res.data.ccDiscountPercentageLabel,
            storePickupPriceLabel: labelPricePickup,
            storePickupTypeLabel: res.data.storePickupTypeLabel,
            checkout: res.data.checkout,
            useBillingData: res.data.useBillingData,
            useTwoClick: res.data.useTwoClick,
            useSsn: res.data.useSsn,
            useDiscountCode: res.data.useDiscountCode,
            useGiftCard: res.data.useGiftCard,
            mapboxToken: decryptToken(res.data.mapboxToken, process.env.REACT_APP_TOKEN_ENCRYPTATION_KEY)
          }
          isLocalStorageAvailable() && setCompanyLocalStorage(companyObject)
          return setCompanyInfo((prevState) => {
            return {
              ...prevState,
              ...companyObject
            }
          })
        })
        .catch((err) => {
          iframeLoadedShopify(false)
          setCompanyInfo((prevState) => {
            return {
              ...prevState,
              pinflagApiKey
            }
          })
          console.error(err)
        })
    }
  }

  const getFinalLoaderInfo = (pinflagApiKey) => {
    setIsLoadingLoaderInfo(true)
    const loaderInfoLocalStorage = getLoaderInfoLocalStorage(pinflagApiKey)
    if (loaderInfoLocalStorage) {
      setLoaderInfo(loaderInfoLocalStorage)
      setIsLoadingLoaderInfo(false)
    } else {
      companyApiInstance(pinflagApiKey)
        .get('/did-you-know')
        .then((res) => {
          if (!res.data.length) return
          setLoaderInfoLocalStorage(res.data, pinflagApiKey)
          setLoaderInfo(res.data)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setIsLoadingLoaderInfo(false)
        })
    }
  }

  const serviceClickAndCollect = async (point) => {
    const { district, stateId, address } = point
    const { pinflagApiKey } = companyInfo
    const { totalWeight, subtotal, discount } = cartData
    const body = {
      address,
      cityName: district,
      stateId,
      price: parseInt(subtotal - discount, 10),
      weight: totalWeight
    }
    try {
      const response = await companyApiInstance(pinflagApiKey).post('/delivery-cost', body)
      return response.data.services[0]
    } catch (err) {
      body.source = 'CNC'
      errorMessage(err, companyInfo, '/delivery-cost', body)
      return { name: 'Despacho normal' }
    }
  }

  const calculateShippingPrice = async (
    arrayAddress,
    setData,
    setIsLoading,
    handleConfirmation,
    source,
    customerAddressId
  ) => {
    const [userAddress, district, region] = arrayAddress
    const { totalWeight, subtotal, discount } = cartData
    const { companyName, pinflagApiKey } = companyInfo

    let stateId, encryptedAddressData
    const body = {
      price: parseInt(subtotal - discount, 10),
      weight: STORES_DEFAULT_WEIGHT[companyName] || totalWeight || undefined
    }
    if (userInfo.isRegistered && !isAddingAddress && !isUserValidated) {
      stateId = 13
      body.customerAddressId = customerAddressId
      encryptedAddressData = { encryptedData: userInfo.encryptedData, customerAddressId }
    } else if (isUserValidated && !isAddingAddress) {
      stateId = parseInt(regionIdApi[regionBillingCode[region]])
      body.cityName = district
      body.stateId = stateId
    } else {
      stateId = parseInt(regionIdApi[normalizeRegionName(region)], 10)
      body.cityName = district
      body.stateId = stateId
    }

    try {
      const [thirdPartyRates, pinflagServices] = await Promise.all([
        calculateShippingRates({ userAddress, district, region }, companyName, encryptedAddressData),
        companyApiInstance(pinflagApiKey).post('/delivery-cost', body)
      ])
      let servicesData = pinflagServices.data
      if (thirdPartyRates) servicesData = joinShippingRates(thirdPartyRates, servicesData, companyName, body.stateId)
      if (servicesData.services[0].price === null) throw new Error('Price null')
      servicesData = filterServices(servicesData, shopifyLocations, { companyName })
      if (servicesData.services.length === 1) handleConfirmation(servicesData.services[0])
      setData(servicesData)
    } catch (error) {
      body.source = source
      errorMessage(error, companyInfo, '/delivery-cost', body)
      const data = getBackupRate(stateId)
      setData(data)
      handleConfirmation(data.services[0])
    }
    setIsLoading(false)
  }

  const getRegions = (setData, setIsLoading) => {
    const { pinflagApiKey } = companyInfo
    companyApiInstance(pinflagApiKey)
      .get('/states-cities')
      .then((res) => {
        setData(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err, 'error')
        setData(backupStates)
        setIsLoading(false)
      })
  }

  const getPoints = (setIsLoading) => {
    const { totalWeight, subtotal, discount } = cartData
    const price = Number(subtotal - discount)
    const searchParams = new URLSearchParams(document.location.search)
    const pinflagApiKey =
      searchParams.get('token') || process.env.REACT_APP_API_KEY

    companyApiInstance(pinflagApiKey)
      .get(`/map?${totalWeight ? 'weight=' + totalWeight + '&' : ''}price=${price || 10000}`)
      .then(async (res) => {
        const shopifyLocations = await availableShopifyLocations()
        const points = filterPoints(res.data.features, shopifyLocations)
        const splitPoints = splitPointsByType(points)
        setPointsData((prevState) => {
          return {
            ...prevState,
            localPoints: splitPoints[0],
            pinflagPoints: splitPoints[1]
          }
        })
        setIsLoading && setIsLoading(false)
      })
      .catch((err) => {
        errorMessage(err, companyInfo, `/map?${totalWeight ? 'weight=' + totalWeight + '&' : ''}price=${price || 10000}`)
        setPointsData((prevState) => {
          return {
            ...prevState,
            localPoints: [],
            pinflagPoints: []
          }
        })
        setIsLoading && setIsLoading(false)
        console.log(err)
      })
  }

  const isEmailRegistered = async (email, setLoadingRegistered) => {
    const { pinflagApiKey } = companyInfo

    try {
      const data = await apiInstance(pinflagApiKey).post('/auth/login-customer', {
        email
      })
      if (data.data.error === undefined && data.data.error !== 'User not found') {
        setUserInfo(prevState => {
          return {
            ...prevState,
            isRegistered: true
          }
        })
        setLoadingRegistered(true)
        setIsSummaryOpen(true)
        return await getCustomerInfo(data.data.access_token)
      }
      return { error: 'hubo un error' }
    } catch (error) {
      console.log('error', error)
      return { error: 'hubo un error' }
    }
  }

  const getCustomerInfo = async (accessToken) => {
    const { pinflagApiKey } = companyInfo
    const data = await apiInstance(pinflagApiKey).post('/customer', {
      access_token: accessToken
    })
    setCustomerInfo(data.data.maskedData)
    setUserInfo(prevState => {
      return {
        ...prevState,
        email: data.data.maskedData.email,
        names: data.data.maskedData.fullName,
        phone: data.data.maskedData.phone,
        rut: data.data.maskedData.ssn,
        encryptedData: data.data.encryptedData
      }
    })
    return data.data.encryptedData
  }

  const deleteCustomerAddresses = async (addresses, token) => {
    const { pinflagApiKey } = companyInfo
    const data = await apiInstance(pinflagApiKey).delete('/customer/address', {
      data: {
        customerAddresses: addresses
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  }

  return {
    getCompanyInfo,
    calculateShippingPrice,
    getRegions,
    getFinalLoaderInfo,
    getPoints,
    serviceClickAndCollect,
    isEmailRegistered,
    getCustomerInfo,
    deleteCustomerAddresses
  }
}
