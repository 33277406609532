/* eslint-disable multiline-ternary */
import {
  GenericModal,
  Icon,
  Map,
  PickupPointsList,
  PointDataConfirmation,
  Spinner
} from '@pinflag/pinflag-ui-kit'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import Autocomplete from '../Autocomplete'
import { DataContext } from '../../context/DataContext'
import ReturnPickupPoint from '../ReturnPickupPoint'
import { getUserLocation } from '../../helpers/initialGeolocatorHelper'
import { sortGeojson } from '../../helpers/sortGeojson'
import { scrollToTop } from '../../helpers/utils'
import { useCmsAdapter } from '../../hook/useCmsAdapter'
import { STORES_DISCOUNT_CYC } from '../../constants/stores'
import {
  editDeliveryTimeLocalPickup
} from '../../helpers/filterPoints'
import { useApiPinflag } from '../../hook/useApiPinflag'
import { gaPaymentPostMessage } from '../../helpers/gaEventsHelper'
import { LOCAL_PICKUP, PICKUP_POINT } from '../../constants/deliveryTypes'
import { sendTimeoutMessage } from '../../helpers/bot'
import MapErrorWrapper from '../MapErrorWrapper'

const PickupMap = ({ reloadPoints }) => {
  const {
    selectedShippingMethod,
    setIsPickup,
    steps,
    setSteps,
    pointsData,
    setPointsData,
    setAddressInfo,
    setCurrentStep,
    companyInfo,
    setCartData,
    ecommerceInfo,
    userInfo,
    isUserValidated,
    cartData,
    setSelectedShippingMethod
  } = useContext(DataContext)

  const { companyName, secondaryColor } = companyInfo

  const { trackingThirdStep } = useCmsAdapter()

  const { serviceClickAndCollect } = useApiPinflag()

  const {
    pinflagPoints,
    localPoints,
    localPointSelected,
    pinflagPointSelected
  } = pointsData

  const isLocalPickup = selectedShippingMethod === 'local-pickup'

  const points = isLocalPickup
    ? editDeliveryTimeLocalPickup(localPoints, companyName)
    : pinflagPoints

  const pointSelected = isLocalPickup
    ? localPointSelected
    : pinflagPointSelected

  const [markerSelectedId, setMarkerSelectedId] = useState(
    pointSelected?.properties ? pointSelected.properties.id : null
  )

  const initialPoint =
    companyInfo.companyName === 'Atakama Outdoor'
      ? [-70.585861, -33.401396]
      : [-70.610672, -33.417752]

  const [mapCenter, setMapCenter] = useState(
    pointSelected?.properties
      ? pointSelected.geometry.coordinates
      : initialPoint
  )

  const [orderedPoints, setOrderedPoints] = useState([])

  const [isReady, setIsReady] = useState(false)

  const [pointInfo, setPointInfo] = useState(
    pointSelected?.properties ? pointSelected.properties : null
  )

  const [pressed, setPressed] = useState(true)

  const [filteredPoints, setFilteredPoints] = useState([])

  const [pointsDidNotLoad, setPointsDidNotLoad] = useState(false)

  const [mapDidNotLoad, setMapDidNotLoad] = useState(false)

  const [mapError, setMapError] = useState(false)

  useEffect(() => {
    setIsPickup(true)
    setOrderedPoints(sortGeojson(points, mapCenter))
    !markerSelectedId && getUserLocation(setInitialPosition)
  }, [])

  useEffect(() => {
    orderedPoints.length && setFilteredPoints(orderedPoints.slice(0, 7))
  }, [orderedPoints])

  const handlePlaceSelected = (place) => {
    const newPoints = sortGeojson(orderedPoints, place.center)
    setFilteredPoints(newPoints.slice(0, 7))
    setOrderedPoints(newPoints)
    setMapCenter(place.center)
    setMarkerSelectedId(null)
  }

  const handlePointSelection = (data) => {
    // returns a list of all the points. if not filtered, creates and sets on first position.
    if (data?.properties?.id) {
      orderPointsList(data.properties.id)
      setMarkerSelectedId(data.properties.id)
    }
    if (data?.properties) {
      setPointInfo(data.properties)
    }
    setMapCenter(data.geometry.coordinates)

    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = true
    updatedSteps[1].subSteps[1].completed = true
    updatedSteps[1].subSteps[2].completed = true
    setSteps(updatedSteps)

    setPointsData((prevState) => {
      prevState[isLocalPickup ? 'localPointSelected' : 'pinflagPointSelected'] =
        data
      return prevState
    })
    scrollToTop()
  }

  const orderPointsList = (selectedId) => {
    const masterIndex = orderedPoints.findIndex(
      (p) => p.properties.id === selectedId
    )
    const filteredIndex = filteredPoints.findIndex(
      (p) => p.properties.id === selectedId
    )
    // This function should execute when the point is not currently on the list
    if (filteredIndex !== -1) {
      filteredPoints.unshift(filteredPoints[filteredIndex])
      filteredPoints.splice(filteredIndex + 1, 1)
      setFilteredPoints(filteredPoints)
    } else {
      const updatedList = [...filteredPoints]
      updatedList.unshift(orderedPoints[masterIndex])
      setFilteredPoints(updatedList)
    }
  }

  const setInitialPosition = (location) => {
    const { longitude, latitude } = location.coords
    setMapCenter([longitude, latitude])
    setOrderedPoints(sortGeojson(points, [longitude, latitude]))
  }

  const handleDeploymentPickupList = (e) => {
    if (e.target) {
      setPressed(true)
    } else {
      setPressed(false)
    }
  }

  const handlePointConfirmation = (point) => {
    serviceClickAndCollect(point).then((service) => {
      setAddressInfo(prevState => {
        return {
          ...prevState,
          userAddress: point.address,
          aditionalInfo: '',
          region: point.region,
          district: point.district,
          price: point.price,
          deliveryType: isLocalPickup ? LOCAL_PICKUP : PICKUP_POINT,
          sla: point.deliveryTime,
          courier: point.company,
          serviceName: service.name,
          cmsId: point.isLocalPickup ? point.cmsId : undefined
        }
      })
      gaPaymentPostMessage()
      setCartData(prevState => {
        return {
          ...prevState,
          shippingCost: point.price
        }
      })
      trackingThirdStep({
        timeOpenThirdStep: new Date(),
        flowType: isUserValidated ? 'hard-login' : undefined
      })
      setCurrentStep(3)
    })
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!userInfo.isRegistered && !orderedPoints.length) {
        sendTimeoutMessage(companyInfo, 'Timeout after loading points in map', userInfo, {
          orderedPoints,
          isLocalPickup,
          pointsData: { localPoints: localPoints.length, pinflagPoints: pinflagPoints.length }
        })
        setPointsDidNotLoad(true)
      }
    }, 7000)
    return () => {
      clearTimeout(timeout)
    }
  }, [orderedPoints])

  useEffect(() => {
    if (!userInfo.isRegistered && !orderedPoints.length) return

    const timeout = setTimeout(() => {
      if (!isReady) {
        sendTimeoutMessage(companyInfo, 'Timeout 10s loading map', userInfo)
        setMapDidNotLoad(true)
      }
    }, 10000)

    return () => {
      clearTimeout(timeout)
    }
  }, [isReady, orderedPoints])

  if (!userInfo.isRegistered && !orderedPoints.length) {
    return (
      <div className='full_width_spinner md:w-[537px]'>
        {!pointsDidNotLoad &&
          <div className='md:flex md:justify-center'>
            <Spinner width={44} />
          </div>
        }
        <GenericModal
          isOpenModal={pointsDidNotLoad}
          icon='error'
          modalTitle='Los puntos no cargaron'
          modalText='Los puntos de retiro no cargaron correctamente. Puedes elegir envío a domicilio o intentarlo nuevamente.'
          firstButton='Retroceder'
          secondButton='Envío a domicilio'
          handleClose={reloadPoints}
          handleAction={() => {
            setIsPickup(false)
            setSelectedShippingMethod('home-delivery')
          }}
        />
      </div>
    )
  }

  return (
    <>
      <GenericModal
        isOpenModal={mapDidNotLoad}
        icon='error'
        modalTitle='El mapa no cargó'
        modalText='El mapa no cargó correctamente. Puedes elegir envío a domicilio o intentarlo nuevamente.'
        firstButton='Retroceder'
        secondButton='Envío a domicilio'
        handleClose={reloadPoints}
        handleAction={() => {
          setIsPickup(false)
          setSelectedShippingMethod('home-delivery')
        }}
      />
      <div className='md:hidden'>
        <div className='w-full bg-slate-100 flex flex-col items-center py-2'>
          <div className='flex md:absolute md:left-1/2 md:-translate-x-1/2 z-10 w-11/12 md:border-2 rounded-xl text-xs bg-white'>
            <ReturnPickupPoint />
          </div>
          <Autocomplete
            onPlaceSelected={handlePlaceSelected}
            showNoResultsAction={true}
          />
        </div>
        <div className='h-full' onClick={(e) => handleDeploymentPickupList(e)}>
          <MapErrorWrapper setMapError={setMapError}>
            <Map
              markerSource={{ features: orderedPoints }}
              markerSelectedId={markerSelectedId}
              onPointSelection={handlePointSelection}
              center={mapCenter}
              height={'17rem'}
              setIsReady={setIsReady}
            />
          </MapErrorWrapper>
        </div>
        <div>
          {markerSelectedId || !pressed ? (
            <div className='rounded-xl bg-white bottom-0 z-10 w-full relative -mt-24 ease-linear duration-100'>
              {!isReady && !mapError && <div className='flex flex-col justify-center h-[250px]'> <Spinner /> </div>}
              {isReady && !mapError &&
                <PickupPointsList
                  points={filteredPoints}
                  onPointSelection={handlePointSelection}
                  markerSelectedId={markerSelectedId}
                  setMarkerSelectedId={setMarkerSelectedId}
                  isLocalPickup={isLocalPickup}
                  pressed={pressed}
                  setPressed={setPressed}
                  handlePointConfirmation={handlePointConfirmation}
                  secondaryColor={secondaryColor}
                  discount={STORES_DISCOUNT_CYC[companyName] || 40}
                  height={'47vh'}
                  id="map-payment-button"
                  isFreeShipping={cartData.isFreeShipping}
                />
              }
            </div>
          ) : (
            <div className='rounded-xl bg-white bottom-0 z-10 w-full relative -mt-1.5 ease-linear duration-100'>
              {!isReady && !mapError && <div className='flex flex-col justify-center h-[250px]'> <Spinner /> </div>}
              {isReady && !mapError && <PickupPointsList
                  points={filteredPoints}
                  onPointSelection={handlePointSelection}
                  markerSelectedId={markerSelectedId}
                  setMarkerSelectedId={setMarkerSelectedId}
                  isLocalPickup={isLocalPickup}
                  pressed={pressed}
                  setPressed={setPressed}
                  handlePointConfirmation={handlePointConfirmation}
                  secondaryColor={secondaryColor}
                  discount={STORES_DISCOUNT_CYC[companyName] || 40}
                  height={'34vh'}
                  id="map-payment-button"
                  isFreeShipping={cartData.isFreeShipping}
                />
              }
            </div>
          )}
        </div>
      </div>
      <div className='hidden md:block h-full'>
        <div className={`flex flex-col ${ecommerceInfo.cms === 'woocommerce' ? 'bg-slate-100 md:bg-white' : 'bg-slate-100'} md:h-full md:w-[785px]`}>
          <div className='border-2 rounded-xl my-1 ml-4 md:w-[747px] md:h-[44px] bg-white'>
            {selectedShippingMethod && (
              <ReturnPickupPoint />
            )}
          </div>
          <div className={`flex flex-row pl-3 ${ecommerceInfo.cms === 'woocommerce' ? 'bg-slate-100 md:bg-white' : 'bg-slate-100'}`}>
            <div
              className='w-[366px] h-[300px] shadow-[10px_10px_15px_3px_rgba(0,0,0,0.1)]'
              style={{ zIndex: 1500000 }}
            >
              <Autocomplete
                onPlaceSelected={handlePlaceSelected}
                showNoResultsAction={true}
              />
              <div className='bg-white rounded w-full h-[254px]'>
                {!isReady && !mapError && <div className='flex flex-col justify-center h-[250px]'> <Spinner /> </div>}
                {mapError && <div className='flex flex-col justify-center h-[250px] items-center'> <Icon name='error' width='40' /> </div>}
                {isReady && !mapError &&
                  <PickupPointsList
                    points={filteredPoints}
                    onPointSelection={handlePointSelection}
                    markerSelectedId={markerSelectedId}
                    setMarkerSelectedId={setMarkerSelectedId}
                    isLocalPickup={isLocalPickup}
                    handlePointConfirmation={handlePointConfirmation}
                    secondaryColor={secondaryColor}
                    discount={STORES_DISCOUNT_CYC[companyName] || 40}
                    isFreeShipping={cartData.isFreeShipping}
                  />
                }
              </div>
            </div>
            <div className='w-[381px] h-[250px] relative'>
              <div className='rounded-xl border-2'>
                <MapErrorWrapper setMapError={setMapError}>
                  <Map
                    markerSource={{ features: orderedPoints }}
                    markerSelectedId={markerSelectedId}
                    onPointSelection={handlePointSelection}
                    center={mapCenter}
                    height='294px'
                    setIsReady={setIsReady}
                  />
                </MapErrorWrapper>
              </div>
              {markerSelectedId && (
                <div className='absolute -bottom-10 z-50 left-1/2 -translate-x-1/2'>
                  <PointDataConfirmation
                    pointInfo={pointInfo}
                    showDiscountPrice={true}
                    label='Elegir Punto'
                    setMarkerSelectedId={setMarkerSelectedId}
                    secondaryColor={secondaryColor}
                    handlePointConfirmation={handlePointConfirmation}
                    isLocalPickup={isLocalPickup}
                    discount={STORES_DISCOUNT_CYC[companyName] || 40}
                    testId='select-point'
                    id="payment-button-map"
                    isFreeShipping={cartData.isFreeShipping}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

PickupMap.propTypes = {
  reloadPoints: PropTypes.func
}

export default PickupMap
