/* eslint-disable react/prop-types */
import React from 'react'
import { sendBotMessage } from './helpers/bot'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch (error, info) {
    if (!info.componentStack.includes('localhost') && !info.componentStack.includes('ngrok')) {
      sendBotMessage(error.stack, document.location.href)
    }
  }

  render () {
    if (this.state.hasError) {
      return this.props.fallback
    }
    return this.props.children
  }
}

export default ErrorBoundary
