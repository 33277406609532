import { useContext } from 'react'
import { DataContext } from '../context/DataContext'
import { numberParser } from '../helpers/numberParser'
import { useCmsAdapter } from './useCmsAdapter'
import {
  getOrderFormId,
  getPinflagApiKey,
  getPostalCode,
  getTotalPriceSLA,
  parseDeliveryTime
} from '../helpers/utils'
import { ONLY_CHECKOUT, DIFFERENT_REGION_NAME } from '../constants/stores'
import {
  changedRegionName,
  normalizeRegionName,
  setCitySelect,
  regionCode,
  getAddressInformationObjectVtex
} from '../helpers/addressParser'
import { applyChangesInPrices } from '../helpers/pricesData'
import { getGiftCardObjectVtex, getPaymentDataObjectVtex } from '../helpers/paymentData'

export const useVtex = () => {
  const {
    addressInfo,
    ecommerceInfo,
    setCartData,
    setEcommerceInfo,
    userInfo,
    setUserInfo,
    companyInfo,
    cartData,
    selectedServiceType,
    setPaymentMethods,
    setDiscountInfo,
    discountInfo
  } = useContext(DataContext)
  const {
    ignoreProfileDataVtex,
    getOrderFormVtex,
    setClientDataVtex,
    setShippingDataVtex,
    applyDiscountCodeVtex,
    setPaymentDataVtex
  } = useCmsAdapter()

  const setInitialInformationVtex = async (message, setInitialInfoReady) => {
    const {
      cms,
      orderFormId,
      currentUrl
    } = message
    if (!cms.includes('vtex')) return
    try {
      const pinflagApiKey = getPinflagApiKey()
      const response = await getOrderFormVtex(orderFormId, pinflagApiKey)
      const { loggedIn, items, clientProfileData: clientData, totalizers } = response.data
      setEcommerceInfo(prevState => {
        return { ...prevState, cms, orderFormId, currentUrl, loggedIn }
      })
      if (clientData && loggedIn) {
        const names = `${clientData.firstName} ${clientData.lastName}`
        setUserInfo(prevState => {
          return {
            ...prevState,
            email: clientData.email,
            names: names.includes('null') ? '' : names,
            phone: clientData.phone,
            rut: clientData.document
          }
        })
      }

      const lines = []
      let itemsCount = 0
      items.forEach(item => {
        lines.push({
          id: `${item.uniqueId}`,
          productName: item.name,
          productImage: item.imageUrl,
          quantity: item.quantity,
          lineTotal: numberParser((item.price / 100) * item.quantity)
        })
        itemsCount += item.quantity
      })

      const itemsTotal = totalizers.find(total => total.id === 'Items')
      setCartData(prevState => {
        return {
          ...prevState,
          lines,
          itemsCount,
          cartId: orderFormId,
          subtotal: itemsTotal.value / 100,
          total: itemsTotal.value / 100
        }
      })
      updateCartSummary(response.data)
      setInitialInfoReady(true)
      /* if (addressData?.street) {
        setAddressInfo(prevState => {
          return {
            ...prevState,
            userAddress: `${addressData.street} ${addressData.number}`,
            aditionalInfo: addressData.complement,
            region: addressData.state,
            district: addressData.neighborhood,
            deliveryType: addressData.addressType,
          }
        })
      } */
    } catch (error) {
      console.log('error', error)
    }
  }

  const iframeLoadedVtexIO = async (setInitialInfoReady) => {
    const orderFormId = getOrderFormId()
    const pinflagApiKey = getPinflagApiKey()
    if (!orderFormId || orderFormId === 'default-order-form' || !pinflagApiKey) return
    try {
      const cms = 'vtex-io'
      setInitialInformationVtex({
        cms,
        orderFormId
        // currentUrl
      },
      setInitialInfoReady
      )
    } catch (error) {
      console.log('error', error)
    }
  }

  const getPaymentMethodsVtexIO = async (orderFormId) => {
    const pinflagApiKey = getPinflagApiKey()
    if (!orderFormId || orderFormId === 'default-order-form' || !pinflagApiKey) return
    try {
      const response = await getOrderFormVtex(orderFormId, pinflagApiKey)
      const paymentMethods = response.data.paymentData.paymentSystems
      const availablePaymentMethods = paymentMethods
        .filter(method => method.groupName !== 'creditCardPaymentGroup' && method.groupName !== 'giftCardPaymentGroup')
        .map(method => ({ id: method.id, title: method.name, code: method.name, groupName: method.groupName }))
      setPaymentMethods(availablePaymentMethods)
      return paymentMethods
    } catch (error) {
      console.log('error', error)
    }
  }

  const saveUserInformationVtex = async (userData, billingData) => {
    const { cms, orderFormId } = ecommerceInfo
    if (!cms.includes('vtex')) return

    try {
      if (!ecommerceInfo.loggedIn) {
        await ignoreProfileDataVtex(ecommerceInfo.orderFormId)
      }
    } catch (err) {
      console.log(err)
    }
    const firstname = userData.names.substring(0, userData.names.indexOf(' '))
    const lastname = userData.names.substring(userData.names.indexOf(' ') + 1)
    const userInformation = {
      email: userData.email,
      firstName: firstname,
      lastName: lastname,
      documentType: 'rutCHL',
      document: userData.rut,
      phone: userData.phone,
      isCorporate: false
    }
    if (billingData) {
      const billingInformation = {
        corporateDocument: billingData.corporateRut,
        corporateName: billingData.corporateName,
        corporatePhone: billingData.corporatePhone,
        isCorporate: true,
        tradeName: billingData.category,
        stateInscription: billingData.corporatePhone
      }
      Object.assign(userInformation, billingInformation)
    }
    await setClientDataVtex(orderFormId, userInformation)
    await setClientDataVtex(orderFormId, userInformation)
  }

  const saveShippingInformationVtex = async (addressObject) => {
    const { cms, orderFormId } = ecommerceInfo
    if (!cms.includes('vtex')) return

    const addressInformation = getAddressInformationObjectVtex(addressObject || addressInfo, companyInfo, userInfo)

    let logisticsInfo, response
    if (ONLY_CHECKOUT.includes(companyInfo.companyName)) {
      logisticsInfo = cartData.lines.map((_, index) => ({
        itemIndex: index,
        selectedDeliveryChannel: addressObject?.deliveryChannel || selectedServiceType.deliveryChannel,
        selectedSla: addressObject?.id || selectedServiceType.name
      }))
      response = await setShippingDataVtex(orderFormId, addressInformation, logisticsInfo)
      if (addressInfo.deliveryType !== 'Despacho a domicilio') {
        updateCartShippingDiscount(response.data)
      }
      if (cms === 'vtex') {
        return window.parent.postMessage(
          {
            type: 'go-to-payment',
            origin: 'pinflag-checkout-iframe'
          },
          '*'
        )
      }
    } else {
      response = await setShippingDataVtex(orderFormId, addressInformation)
      if (addressInfo.deliveryType !== 'Despacho a domicilio') {
        updateCartShippingDiscount(response.data)
      }
    }
  }

  const removeIframeVTEX = () => {
    window.parent.postMessage(
      {
        type: 'remove-iframe',
        origin: 'pinflag-checkout-iframe'
      },
      '*'
    )
  }

  const getSlasVtex = async (address, district, region, aditionalInfo, geoCoordinates) => {
    const { cms, orderFormId } = ecommerceInfo
    if (!cms.includes('vtex')) return
    let street, number
    const codeRegion = regionCode[normalizeRegionName(region)]
    const newDistrict = district ? setCitySelect(codeRegion, district) : undefined
    const postcode = getPostalCode(newDistrict || region)
    if (address) {
      street = address.split(' ').slice(0, -1).join(' ')
      number = address.split(' ').pop()
    }
    const addressInformation = {
      postalCode: postcode,
      state: DIFFERENT_REGION_NAME.includes(companyInfo.companyName) ? changedRegionName(region) : region,
      country: 'CHL',
      street,
      number,
      neighborhood: DIFFERENT_REGION_NAME.includes(companyInfo.companyName) ? newDistrict : district,
      complement: aditionalInfo,
      addressType: 'search',
      receiverName: userInfo.names,
      geoCoordinates
    }
    const response = await setShippingDataVtex(orderFormId, addressInformation)
    return response.data
  }

  const getShippingInfoVtex = async (address, district, region, aditionalInfo, setDeliveryInfo, setIsLoading, handleConfirmation) => {
    const { cms } = ecommerceInfo
    if (!cms.includes('vtex')) return

    const response = await getSlasVtex(address, district, region, aditionalInfo)
    if (response.totalizers.length > 0) {
      const deliveryInfo = []
      try {
        const slas = response.shippingData.logisticsInfo[0].slas
        for (let i = 0; i < slas.length; i++) {
          if (slas[i].availableDeliveryWindows.length === 0) {
            const currentSla = {
              name: slas[i].id,
              description: parseDeliveryTime(slas[i].shippingEstimate),
              packageDeliveryTime: parseDeliveryTime(slas[i].shippingEstimate),
              price: getTotalPriceSLA(response.shippingData.logisticsInfo, i),
              serviceType: slas[i].id,
              deliveryChannel: slas[i].deliveryChannel
            }
            deliveryInfo.push(currentSla)
          }
        }
        setDeliveryInfo({ services: deliveryInfo })
        if (deliveryInfo.length === 1) handleConfirmation(deliveryInfo[0])
        setIsLoading(false)
      } catch (err) {
        removeIframeVTEX()
      }
    } else {
      removeIframeVTEX()
    }
  }

  const updateCartSummary = orderForm => {
    const { discountCodes } = discountInfo
    const newDiscountCodes = { code: orderForm.marketingData?.coupon, type: 'coupon' }
    const giftCards = discountCodes.filter(code => code.type === 'gift-card')

    setDiscountInfo(prevState => {
      return {
        ...prevState,
        discountCodes: orderForm.marketingData?.coupon ? giftCards.concat([newDiscountCodes]) : giftCards
      }
    })
    const { totalizers } = orderForm
    const discount = totalizers.find(total => total.id === 'Discounts')

    if (discount) {
      setCartData(prevState => {
        return {
          ...prevState,
          discount: (discount.value * -1) / 100
        }
      })
    } else {
      setCartData(prevState => {
        return {
          ...prevState,
          discount: 0
        }
      })
    }
  }

  const addDiscountCodeVtex = async (code) => {
    const { cms, orderFormId } = ecommerceInfo
    if (!cms.includes('vtex')) return

    if (!companyInfo.useDiscountCode) {
      return { valid: false, error: 'Código inválido' }
    }

    try {
      const response = await applyDiscountCodeVtex(orderFormId, code)

      const orderForm = response.data
      const isValidCode = orderForm.messages.every(message => !message?.text.includes(code))
      if (isValidCode) {
        const discountItem = orderForm.items.find(item => item.priceTags.some(tag => tag.name.toLowerCase().includes('gift')))
        if (discountItem) updateCartData(orderForm.items)
        updateCartSummary(orderForm)
        return { valid: true, error: false }
      }
      return { valid: false, error: 'Código inválido' }
    } catch {
      return { valid: false, error: 'Inténtelo más tarde' }
    }
  }

  const updateCartShippingDiscount = (orderForm) => {
    const { marketingData, ratesAndBenefitsData, totalizers } = orderForm
    const { discountCodes } = discountInfo
    const onlyDiscountCodes = discountCodes.filter(code => code.type === 'coupon')
    const discountIdentifier = ratesAndBenefitsData.rateAndBenefitsIdentifiers.find(rate => (
      rate.matchedParameters['couponCode@Marketing'] === marketingData.coupon)
    )

    if (!discountIdentifier && onlyDiscountCodes.length > 0) {
      removeDiscountCodeVtex()
      setDiscountInfo(prevState => {
        return {
          ...prevState,
          discountNotification: 'Los códigos de descuentos han sido modificados'
        }
      })
      setTimeout(() => setDiscountInfo(prevState => {
        return {
          ...prevState,
          discountNotification: ''
        }
      }), 5000)
      return
    }

    if (discountCodes.some(discount => discount.code === discountIdentifier?.matchedParameters['couponCode@Marketing'])) {
      const totalizerShipping = totalizers.find(totalizer => totalizer.id === 'Shipping')
      if (totalizerShipping) {
        const isFreeShipping = totalizerShipping.value === 0
        setCartData(prevState => {
          return {
            ...prevState,
            isFreeShipping,
            shippingCost: totalizerShipping.value / 100
          }
        })
        setDiscountInfo(prevState => {
          return {
            ...prevState,
            discountNotification: 'Se modificaron los valores del carro'
          }
        })
        setTimeout(() => setDiscountInfo(prevState => {
          return {
            ...prevState,
            discountNotification: ''
          }
        }), 5000)
      }
    }
  }

  const removeDiscountCodeVtex = async () => {
    const { cms, orderFormId } = ecommerceInfo
    if (!cms.includes('vtex')) return
    try {
      const response = await applyDiscountCodeVtex(orderFormId, '')
      const orderForm = response.data
      updateCartData(orderForm.items)
      updateCartSummary(orderForm)
    } catch (error) {
      console.log('error', error)
    }
  }

  const updateCartData = (items) => {
    const lines = []
    let itemsCount = 0
    items.forEach(item => {
      lines.push({
        id: `${item.uniqueId}`,
        productName: item.name,
        productImage: item.imageUrl,
        quantity: item.quantity,
        lineTotal: numberParser((item.price / 100) * item.quantity)
      })
      itemsCount += item.quantity
    })
    setCartData(prevState => {
      return {
        ...prevState,
        lines,
        itemsCount
      }
    })
  }

  const addGiftCardVtex = async (giftCardCode, paymentData) => {
    const { cms, orderFormId } = ecommerceInfo
    if (!cms.includes('vtex')) return

    if (!companyInfo.useGiftCard) {
      return { valid: false, error: 'Código inválido' }
    }

    const { discountCodes } = discountInfo
    const isGiftCardApplied = discountCodes.some(code => code.code === giftCardCode)
    if (isGiftCardApplied) return { valid: true, error: false }

    const currentGiftCards = discountCodes.filter(code => code.type === 'gift-card')
    const giftCards = currentGiftCards.map(card => getGiftCardObjectVtex(card.code)).concat([getGiftCardObjectVtex(giftCardCode)])

    try {
      const response = await setPaymentDataVtex(orderFormId, paymentData, giftCards)
      const giftCardValid = response.data.paymentData.giftCards.find(card => card.redemptionCode === giftCardCode)

      if (giftCardValid) {
        const giftCardData = {
          balance: giftCardValid.balance / 100,
          code: giftCardValid.redemptionCode,
          type: 'gift-card',
          last_characters: giftCardValid.redemptionCode
        }
        setDiscountInfo(prevState => {
          return {
            ...prevState,
            discountCodes: [...prevState.discountCodes, { code: giftCardData.code, type: giftCardData.type, originalCode: giftCardData.code }]
          }
        })
        applyChangesInPrices(giftCardData, null, cartData, setCartData)
        return { valid: true, error: false }
      } else {
        return { valid: false, error: 'GiftCard inválida' }
      }
    } catch (error) {
      return { valid: false, error: 'GiftCard inválida' }
    }
  }

  const removeGiftCardVtex = async (giftCardCode, paymentData = []) => {
    const { cms, orderFormId } = ecommerceInfo
    if (!cms.includes('vtex')) return

    const { discountCodes } = discountInfo
    const { giftCard } = cartData
    const cardInfo = giftCard.cards.find(card => card.code === giftCardCode)

    const currentGiftCards = discountCodes.filter(code => code.type === 'gift-card' && code.code !== giftCardCode)
    const giftCards = currentGiftCards.map(card => getGiftCardObjectVtex(card.code))
    try {
      await setPaymentDataVtex(orderFormId, paymentData, giftCards)
      applyChangesInPrices(null, cardInfo, cartData, setCartData)
    } catch (error) {
      return { valid: false, error: 'GiftCard inválida' }
    }
  }

  const addPaymentDataVtex = async (paymentData) => {
    const { cms, orderFormId } = ecommerceInfo
    if (!cms.includes('vtex')) return

    const { discountCodes } = discountInfo
    const paymentDataParsed = getPaymentDataObjectVtex(paymentData, cartData.total)

    const currentGiftCards = discountCodes.filter(code => code.type === 'gift-card')

    if (currentGiftCards.length > 0) {
      const giftCards = currentGiftCards.map(card => getGiftCardObjectVtex(card.code))
      await setPaymentDataVtex(orderFormId, paymentDataParsed, giftCards)
    }
  }

  const goToPaymentVtexIO = async (paymentMethodGroupName) => {
    const { cms, orderFormId } = ecommerceInfo
    if (!cms.includes('vtex-io')) return

    const addressInformation = getAddressInformationObjectVtex(addressInfo, companyInfo, userInfo)
    const shippingInformation = { ...addressInformation, receiverName: `${userInfo.names}.${paymentMethodGroupName}` }

    let logisticsInfo
    if (ONLY_CHECKOUT.includes(companyInfo.companyName)) {
      logisticsInfo = cartData.lines.map((_, index) => ({
        itemIndex: index,
        selectedDeliveryChannel: selectedServiceType.deliveryChannel,
        selectedSla: selectedServiceType.name
      }))
    }
    await setShippingDataVtex(orderFormId, shippingInformation, logisticsInfo)
  }

  return {
    setInitialInformationVtex,
    iframeLoadedVtexIO,
    saveUserInformationVtex,
    saveShippingInformationVtex,
    getPaymentMethodsVtexIO,
    getShippingInfoVtex,
    getSlasVtex,
    removeIframeVTEX,
    addDiscountCodeVtex,
    removeDiscountCodeVtex,
    updateCartShippingDiscount,
    updateCartData,
    addGiftCardVtex,
    removeGiftCardVtex,
    addPaymentDataVtex,
    goToPaymentVtexIO
  }
}
