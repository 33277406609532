export const MAPBOX_STATIC_MAP_URL = 'https://api.mapbox.com/styles/v1/mapbox/light-v10/static/'
export const BOT_URL = 'https://botji-api.pinflag.cl'
export const BOT_URL_DEV = 'https://botji-api-stage.pinflag.cl'
export const CMS_ADAPTER_URL = 'https://cms-api.pinflag.cl'
export const CMS_ADAPTER_URL_DEV = 'https://cms-api-stage.pinflag.cl'
export const PAYMENT_BUCKET_URL = 'https://pinmap-payment-methods-assets.s3.sa-east-1.amazonaws.com'
export const COMPANY_API_BASE_URL = 'https://company-api-v2.pinflag.cl'
export const COMPANY_API_BASE_URL_DEV = 'https://company-api-v2-stage.pinflag.cl'
export const API_BASE_URL = 'https://company-api.pinflag.cl/api'
export const API_BASE_URL_DEV = 'https://pinflag-admin-api-dev-4d1b576c28cb.herokuapp.com/api'
