import { useContext } from 'react'
import { DataContext } from '../context/DataContext'
import cmsInstance from '../helpers/cmsInstance'

export const useCmsAdapter = () => {
  const { userInfo, ecommerceInfo, companyInfo, addressInfo, cartData } =
    useContext(DataContext)
  const { pinflagApiKey, companyName } = companyInfo

  const getVariantLocations = (variantId) => {
    return cmsInstance.post('/shopify/locations', {
      pinflagApiKey,
      variantId: `gid://shopify/ProductVariant/${variantId}`
    })
  }

  const getCheckout = checkoutId => {
    return cmsInstance.get(
      `/shopify/checkout?pinflagApiKey=${pinflagApiKey}&checkoutId=${checkoutId}`
    )
  }

  const createCheckout = input => {
    return cmsInstance.post('/shopify/checkout', {
      input,
      pinflagApiKey
    })
  }

  const updateEmailCheckout = (checkoutId, email) => {
    return cmsInstance.post('/shopify/checkout/update-email', {
      checkoutId,
      email,
      pinflagApiKey
    })
  }

  const setItemsCheckout = (checkoutId, oldLineItemsId, newLineItems) => {
    return cmsInstance.post('/shopify/checkout/set-items', {
      checkoutId,
      oldLineItemsId,
      newLineItems,
      pinflagApiKey
    })
  }

  const updateAddressCheckout = (checkoutId, address) => {
    return cmsInstance.post('/shopify/checkout/update-address', {
      checkoutId,
      address,
      pinflagApiKey
    })
  }

  const getShippingRates = checkoutId => {
    return cmsInstance.get(
      `/shopify/checkout/get-shipping-rates?pinflagApiKey=${pinflagApiKey}&checkoutId=${checkoutId}`
    )
  }

  const setShippingRate = (checkoutId, shippingRateHandle) => {
    return cmsInstance.post('/shopify/checkout/set-shipping-rate', {
      checkoutId,
      shippingRateHandle,
      pinflagApiKey
    })
  }

  const sendCartNotesShopify = (checkoutId, notes, isGiftWrapping, giftNote) => {
    const { cms } = ecommerceInfo
    if (cms !== 'shopify') return
    return cmsInstance.post('/shopify/checkout/update-attributes', {
      checkoutId,
      pinflagApiKey,
      input: isGiftWrapping
        ? {
            note: notes,
            customAttributes: giftNote
              ? [
                  {
                    key: 'gift-wrapping',
                    value: 'on'
                  },
                  {
                    key: 'Gift Note',
                    value: giftNote
                  }
                ]
              : [
                  {
                    key: 'gift-wrapping',
                    value: isGiftWrapping ? 'on' : 'off'
                  }
                ]
          }
        : { note: notes }
    })
  }

  const updateCustomAttributesShopify = (checkoutId, input) => {
    const { cms } = ecommerceInfo
    if (cms !== 'shopify') return
    return cmsInstance.post('/shopify/checkout/update-attributes', {
      checkoutId,
      pinflagApiKey,
      input
    })
  }

  const ignoreProfileDataVtex = orderFormId => {
    return cmsInstance.post('/vtex/order/ignore-personal-data', {
      orderFormId,
      pinflagApiKey
    })
  }

  const getOrderFormVtex = (orderFormId, pinflagApiKey) => {
    return cmsInstance.get(`/vtex/order-form?pinflagApiKey=${pinflagApiKey}&orderFormId=${orderFormId}`)
  }

  const getPaymentUrl = (permalink, uuid) => {
    return cmsInstance.post('/shopify/scrapper', {
      url: permalink,
      uuid
    })
  }

  const getPaymentMethods = (cms) => {
    if (cms === 'shopify') return cmsInstance.get(`/shopify/store-payment-methods?pinflagApiKey=${pinflagApiKey}`)
    if (cms.includes('woocommerce')) return cmsInstance.get(`/woocommerce/store-payment-methods?pinflagApiKey=${pinflagApiKey}`)
  }

  const getShopifyConfigurations = () => {
    return cmsInstance.get(`/shopify/configurations?pinflagApiKey=${pinflagApiKey}`)
  }

  const createCheckoutLog = (checkoutData) => {
    if (checkoutData.shippingAddress && Object.keys(checkoutData.shippingAddress).length > 0) {
      delete checkoutData.shippingAddress.shippingRate
    }
    if (ecommerceInfo.cms === 'shopify') {
      return cmsInstance.post('/shopify/checkout-log', {
        checkoutData,
        pinflagApiKey,
        cartToken: ecommerceInfo.cartToken
      })
    }
  }

  const deleteCheckoutLog = (email) => {
    if (ecommerceInfo.cms === 'shopify') {
      return cmsInstance.delete(`/shopify/checkout-log?email=${email}&pinflagApiKey=${pinflagApiKey}`)
    }
  }

  const initScrapperCall = (domain, url, uuidConnection, encryptedData) => {
    return cmsInstance.post('/shopify/scrapper', {
      domain,
      url,
      uuid: uuidConnection,
      encryptedData,
      cartToken: ecommerceInfo.cartToken,
      siteCookies: ecommerceInfo.siteCookies
    })
  }

  const validateDiscountCodesShopify = (discountCodes, lineItems) => {
    return cmsInstance.post('/shopify/cart/discount-codes', {
      pinflagApiKey,
      discountCodes,
      lineItems
    })
  }

  const availableShippingRates = (input, encryptedAddressData) => {
    return cmsInstance.post('/shopify/available-shipping-rates', {
      input,
      pinflagApiKey,
      encryptedAddressData
    })
  }

  const validateDiscountForCustomerShopify = (discountCode, email, type) => {
    return cmsInstance.post('/shopify/cart/customer-discount-code', {
      pinflagApiKey,
      discountCode,
      email,
      type
    })
  }

  const trackingPinmap = async (body) => {
    const oldCurrentUrl = ecommerceInfo.currentUrl || body?.currentUrl || '/'
    const getSlice = oldCurrentUrl.indexOf('?') === -1 ? oldCurrentUrl.length : oldCurrentUrl.indexOf('?')
    const currentUrl = oldCurrentUrl.slice(0, getSlice)

    try {
      await cmsInstance.post('/tracking/pinmap', {
        id: cartData.cartId,
        storeName: companyName,
        device: navigator.userAgent,
        maxTouchPoints: navigator.maxTouchPoints,
        wasPinmapLeak: false,
        timeOpenFirstStep: new Date(),
        cms: ecommerceInfo.cms,
        cartData: {
          total: cartData.total,
          subtotal: cartData.subtotal,
          lines: cartData.lines,
          itemsCount: cartData.itemsCount,
          totalWeight: cartData.totalWeight,
          cartId: cartData.cartId
        },
        pinflagApiKey: companyInfo.pinflagApiKey,
        ...body,
        currentUrl
      })
    } catch (err) {
      console.log('hubo un error en el log', err)
    }
  }

  const trackingSecondStep = async (body) => {
    try {
      await cmsInstance.post('/tracking/pinmap/update', {
        id: cartData.cartId,
        storeName: companyName,
        cms: ecommerceInfo.cms,
        cartData,
        userInfo,
        pinflagApiKey: companyInfo.pinflagApiKey,
        ...body
      })
    } catch (err) {
      console.log('hubo un error en el log', err)
    }
  }

  const trackingThirdStep = async (body) => {
    try {
      await cmsInstance.post('/tracking/pinmap/update', {
        id: cartData.cartId,
        storeName: companyName,
        cms: ecommerceInfo.cms,
        cartData,
        shippingInfo: addressInfo,
        pinflagApiKey: companyInfo.pinflagApiKey,
        ...body
      })
    } catch (err) {
      console.log('hubo un error en el log', err)
    }
  }

  const trackingLeak = (body) => {
    cmsInstance.post('/tracking/pinmap/update', {
      id: cartData.cartId,
      storeName: companyName,
      cms: ecommerceInfo.cms,
      cartData,
      wasPinmapLeak: true,
      timePinmapLeak: new Date(),
      pinflagApiKey: companyInfo.pinflagApiKey,
      ...body
    }).catch((err) => {
      console.log('hubo un error en el log', err)
    })
  }

  const validateEmail = async (email) => {
    try {
      const response = await cmsInstance.post('/validate/email', { email })
      return response?.data.validated
    } catch (err) {
      return false
    }
  }

  const getLocation = async (locationId) => {
    return cmsInstance.post('/shopify/location', {
      pinflagApiKey: companyInfo.pinflagApiKey,
      locationId
    })
  }

  const validateDiscountForCustomerWoocomerce = (email, codes) => {
    return cmsInstance.post(`/woocommerce/coupons?pinflagApiKey=${pinflagApiKey}`, {
      codes,
      email
    })
  }

  const setClientDataVtex = (orderFormId, clientData) => {
    return cmsInstance.post('/vtex/order-form/client-data', {
      orderFormId,
      pinflagApiKey,
      clientData
    })
  }

  const setShippingDataVtex = async (orderFormId, shippingData, logisticsInfo) => {
    try {
      const response = await cmsInstance.post('/vtex/order-form/shipping-data', {
        orderFormId,
        pinflagApiKey,
        shippingData,
        logisticsInfo
      })
      return response
    } catch (err) {
      console.log('Este es el error', err)
      return false
    }
  }

  const applyDiscountCodeVtex = (orderFormId, code) => {
    return cmsInstance.post('/vtex/order-form/apply-discount-code', {
      pinflagApiKey,
      orderFormId,
      code
    })
  }

  const validateGiftCartShopify = (lastCharacters) => {
    return cmsInstance.post('/shopify/gift-card/validate', {
      pinflagApiKey,
      lastCharacters
    })
  }

  const setPaymentDataVtex = (orderFormId, paymentData, giftCards) => {
    return cmsInstance.post('/vtex/order-form/payment-data', {
      orderFormId,
      pinflagApiKey,
      paymentData,
      giftCards
    })
  }

  const getShopifyAutomaticDiscounts = (subtotal, itemsQuantity) => {
    return cmsInstance.post('/shopify/automatic-discounts', {
      pinflagApiKey,
      subtotal,
      itemsQuantity
    })
  }

  return {
    getVariantLocations,
    getCheckout,
    createCheckout,
    updateEmailCheckout,
    setItemsCheckout,
    updateAddressCheckout,
    getShippingRates,
    setShippingRate,
    ignoreProfileDataVtex,
    getPaymentUrl,
    getPaymentMethods,
    createCheckoutLog,
    deleteCheckoutLog,
    initScrapperCall,
    sendCartNotesShopify,
    updateCustomAttributesShopify,
    validateDiscountCodesShopify,
    availableShippingRates,
    validateDiscountForCustomerShopify,
    trackingPinmap,
    trackingSecondStep,
    trackingThirdStep,
    trackingLeak,
    validateEmail,
    getLocation,
    validateDiscountForCustomerWoocomerce,
    setClientDataVtex,
    getOrderFormVtex,
    setShippingDataVtex,
    applyDiscountCodeVtex,
    validateGiftCartShopify,
    setPaymentDataVtex,
    getShopifyAutomaticDiscounts,
    getShopifyConfigurations
  }
}
