import React, { useContext, useEffect, useRef, useState } from 'react'
import { CommerceHeader, SideBar, Icon } from '@pinflag/pinflag-ui-kit'
import { DataContext } from '../context/DataContext'
import { useMagento } from '../hook/useMagento'
import { useShopify } from '../hook/useShopify'
import { useWoocommerce } from '../hook/useWoocommerce'
import { applyChangesInPrices } from '../helpers/pricesData'
import CartSection from './CartSection'

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { cartData, isPickup, companyInfo, ecommerceInfo, discountInfo, setCartData, setDiscountInfo } = useContext(DataContext)
  const { primaryColor, logoUrl } = companyInfo
  const { redirectToHomeMagento } = useMagento()
  const { redirectToHomeShopify, addDiscountCodeShopify } = useShopify()
  const { wooCommerceClosePinmap } = useWoocommerce()
  const { cms } = ecommerceInfo

  const cartDataRef = useRef(cartData)

  const handleCartIconClick = () => {
    setIsOpen(!isOpen)
  }

  const handleClosePinmap = () => {
    wooCommerceClosePinmap()
    window.parent.postMessage(
      {
        type: 'close-iframe',
        origin: 'pinflag-shopify-pinmap-pro',
        message: true
      },
      '*'
    )
  }

  const redirectToHome = () => {
    redirectToHomeMagento()
    redirectToHomeShopify('user action - redirect home')
  }

  useEffect(() => {
    cartData.lines.length === 0 && redirectToHomeShopify('cartData empty - redirect home')
    if (cartData.shippingCost !== cartDataRef.current.shippingCost ||
      cartData.subtotal !== cartDataRef.current.subtotal ||
      cartData.discount !== cartDataRef.current.discount ||
      cartData.isFreeShipping !== cartDataRef.current.isFreeShipping
    ) {
      applyChangesInPrices(null, null, cartData, setCartData)
      cartDataRef.current = cartData
    }
  }, [cartData])

  useEffect(() => {
    const setInitalDiscounts = async (discounts) => {
      for (const discount of discounts) await addDiscountCodeShopify(discount)
    }

    if (!discountInfo.initialDiscounts.applied) {
      setDiscountInfo(prevState => {
        return {
          ...prevState,
          initialDiscounts: {
            ...prevState.initialDiscounts,
            applied: true
          }
        }
      })
      setInitalDiscounts(discountInfo.initialDiscounts.discounts)
    }
  }, [discountInfo])

  return (
    <>
      <div className='h-14 mx-4 md:mx-0 md:w-[537px] mt-6 md:mt-0 max-[350px]:mt-[0.5rem]'>
        <CommerceHeader
          logoUrl={logoUrl}
          onCartIconClick={isPickup ? handleCartIconClick : undefined}
          onLogoClick={redirectToHome}
          primaryColor={primaryColor}
          closePinmap={handleClosePinmap}
        />
      </div>
      <SideBar isOpen={isOpen} isPickup={isPickup} className={`bg-slate-100 md:w-[37%] md:text-md z-100 ${cms === 'woocommerce' ? 'md:pt-4' : ''}`}>
        <div className={isPickup ? 'hidden md:flex pl-2' : 'hidden'}>
          <Icon name='shopping-cart' width={22} className='fill-slate-200 mr-5' />
        </div>
        <CartSection setIsOpen={setIsOpen} />
      </SideBar>
    </>
  )
}
