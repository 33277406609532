import React from 'react'
import { createRoot } from 'react-dom/client'
import PinmapPro from './App'
import { DataProvider } from './context/DataContext'
import ErrorBoundary from './ErrorBoundary'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <DataProvider>
        <PinmapPro />
      </DataProvider>
    </ErrorBoundary>
  </React.StrictMode>

)
