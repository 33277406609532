import axios from 'axios'

import { CMS_ADAPTER_URL } from '../constants/url'

const cmsInstance = axios.create({
  baseURL: CMS_ADAPTER_URL,
  headers: { 'x-api-key': process.env.REACT_APP_CMS_KEY }
})

export default cmsInstance
