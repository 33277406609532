import axios from 'axios'
import { BOT_URL } from '../constants/url'

export const sendBotMessage = (message, subMessage) => {
  axios
    .post(`${BOT_URL}/message`, {
      origin: 'Pinmap pro',
      message,
      sub_message: subMessage,
      type: 'checkoutError'
    })
    .catch(err => {
      console.error(err)
    })
}

export const buildMessage = (shippingRates, cartData, companyInfo, addressInfo) => {
  const { lines, total, totalWeight } = cartData
  const { companyName } = companyInfo
  const { userAddress, district, region } = addressInfo
  let message = 'Error: '
  message += shippingRates
    ? 'Pinflag Shipping Rate did not provide a rate\n'
    : 'Shopify did not provide any rate\n'
  message += `Company: ${companyName}\n`
  message += `Address: ${userAddress}, ${district}, ${region}\n`
  message += `CartData: Total: $${total}, Weight: ${totalWeight}\n`
  for (let index = 0; index < lines.length; index++) {
    message += ` - Product ${index + 1}: id: ${lines[index].id}, name: ${lines[index].productName}, quantity: ${lines[index].quantity}\n`
  }
  return message
}

export const errorMessage = (errorResponse, companyInfo, endpoint, body) => {
  const { companyName } = companyInfo
  let message = `Error: ${errorResponse?.message}\n`
  message += `Message: error calling ${endpoint}\n`
  message += `Company: ${companyName}\n`
  let subMessage = `${JSON.stringify(errorResponse)}\n`
  subMessage += body ? JSON.stringify(body) : ''
  sendBotMessage(message, subMessage)
}

export const removeIframeMessage = (companyInfo, permalink, reason, collectedScrapperInfo) => {
  const { companyName } = companyInfo
  let message = 'Error: Redirect to native checkout\n'
  message += `Case: ${reason}\n`
  message += `Company: ${companyName}\n`
  let subMessage = `Permalink: ${permalink}`
  if (collectedScrapperInfo.joined.length) {
    subMessage += '\n\nCollected Scrapper Info:'
    subMessage += `\nJoined: ${JSON.stringify(collectedScrapperInfo.joined)}`
    subMessage += `\nPrivate Messages: ${JSON.stringify(collectedScrapperInfo.privateMessages)}`
    subMessage += `\nPayment Url: ${JSON.stringify(collectedScrapperInfo.paymentUrl)}`
    subMessage += `\n\nSocket Messages: ${JSON.stringify(collectedScrapperInfo.socketMessages)}`
  }
  sendBotMessage(message, subMessage)
}

export const timeoutScrapperMessage = (companyInfo, cartData, userInfo, addressInfo) => {
  const { companyName } = companyInfo
  let message = 'Error: Redirect to cart\n'
  message += 'Case: Exceeded 6 minutes timeout of scrapper\n'
  message += `Company: ${companyName}\n`
  let subMessage = `${JSON.stringify(cartData)}\n`
  subMessage += `${JSON.stringify(userInfo)}\n`
  subMessage += `${JSON.stringify(addressInfo)}\n`
  sendBotMessage(message, subMessage)
}

export const sendTimeoutMessage = (companyInfo, caseText, userInfo, extraInfo) => {
  const { companyName } = companyInfo
  let message = 'Error: Timeout\n'
  message += `Case: ${caseText}\n`
  message += `Company: ${companyName}\n`
  let subMessage = `User Info: ${JSON.stringify(userInfo)}`
  if (extraInfo) {
    subMessage += `\n\nExtra Info: ${JSON.stringify(extraInfo)}`
  }
  sendBotMessage(message, subMessage)
}
