import axios from 'axios'
import PropTypes from 'prop-types'
import { Icon } from '@pinflag/pinflag-ui-kit'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { addressParserUnnormalized } from '../helpers/addressParser'
import { DataContext } from '../context/DataContext'

const Autocomplete = ({ onPlaceSelected, showNoResultsAction }) => {
  const {
    companyInfo
  } = useContext(DataContext)
  const [searchResults, setSearchResults] = useState([])
  const [address, setAddress] = useState('')
  const debounceRef = useRef()
  const [active, setActive] = useState(false)

  const onQueryChanged = event => {
    setAddress(event.target.value)

    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }

    debounceRef.current = setTimeout(() => {
      searchPlaces(event.target.value)
    }, 300)
  }

  const searchAPI = axios.create({
    baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
    params: {
      limit: 3,
      language: 'es',
      autocomplete: false,
      country: 'cl',
      access_token: companyInfo.mapboxToken,
      types: 'address,place,region'
    }
  })

  const searchPlaces = async addressQuery => {
    if (addressQuery.length > 3) {
      try {
        const resp = await searchAPI.get(`/${addressQuery}.json`)
        const noResults = {
          id: 'No results action ID',
          title: 'No encuentras tu dirección?',
          type: 'action'
        }
        resp.data.features.length > 0
          ? setSearchResults(resp.data.features)
          : showNoResultsAction
            ? setSearchResults([noResults])
            : setSearchResults([])
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    address.length === 0 && setSearchResults({})
  }, [address])

  const clearSearch = () => {
    setAddress('')
  }

  const handleClickAddress = data => {
    onPlaceSelected && onPlaceSelected(data)
    setAddress(data.place_name_es)
    setSearchResults({})
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return (
    <>
      <div className='w-full md:hidden'>
        <div className='flex h-[44px] border-2 justify-center items-center rounded-xl text-sm w-5/6 absolute z-10 mt-4 py-2 px-4 drop-shadow-xl left-1/2 bg-white -translate-x-2/4'>
          <Icon name='lens' className={`font-bold mr-2 ${active ? 'fill-cyan-500' : 'fill-slate-500'}`} width={13} />
          <input
            type='text'
            className='bg-white w-11/12 focus:outline-none'
            placeholder='Buscar Dirección'
            value={address}
            onChange={onQueryChanged}
            onKeyDown={handleKeyDown}
          />
          <div
            className='cursor-pointer -translate-x-2/4 left-1/2 text-slate-500 font-bold'
            onClick={clearSearch}
          >
            <Icon name='close' className='fill-gray-300' width={18} />
          </div>
        </div>
        {searchResults.length > 0 && (
          <ul className='border-2 text-sm rounded-l w-5/6 absolute mt-[3.8rem] drop-shadow-xl left-1/2  bg-white p-2 -translate-x-2/4 z-10'>
            {searchResults?.map(result => {
              return (
                <>
                  {result.type && result.type === 'action'
                    ? (
                      <div className='flex bg-amber-50 p-2'>
                        <Icon
                          name='error'
                          width={20}
                          className='h-full fill-amber-400 mr-2 min-h-[20px] min-w-[20px]'
                        />
                        <div>
                          <li className='text-sm leading-[125.02%] text-gray-800 font-normal mt-1 mb-2'>No se encontraron Resultados</li>
                          <li className='text-xs leading-[125.02%] text-slate-300 font-normal'>Te recomendamos seleccionar una opción de la lista o volver a ingresarla.</li>
                        </div>
                      </div>
                      )
                    : (
                      <li
                        className='z-10 flex text-xs py-2 cursor-pointer px-4'
                        onClick={() => handleClickAddress(result)}
                        key={result.id}
                      >
                        {addressParserUnnormalized(result.place_name_es)[0]},
                        {addressParserUnnormalized(result.place_name_es)[1]},
                        {addressParserUnnormalized(result.place_name_es)[2]?.slice(
                          0,
                          -8
                        )}
                      </li>
                      )}
                </>
              )
            })}
          </ul>
        )}
      </div>
      <div className='hidden md:flex border-2 md:h-10 z-50 rounded-xl text-sm p-2 pl-4 mx-1 mt-1 relative bg-white'>
        <Icon
          name='lens'
          width={20}
          className={`font-bold mt-1 mr-2 ${active ? 'fill-cyan-500' : 'fill-slate-500'}`}
        />
        <input
          type='text'
          className='bg-white w-11/12 focus:outline-none'
          placeholder='Buscar Dirección'
          value={address}
          onChange={onQueryChanged}
          onFocus={(e) => setActive(true)}
          onBlur={(e) => setActive(false)}
        />
        <Icon
          name='close'
          width={20}
          className='cursor-pointer -translate-x-2/4 left-1/2 fill-slate-500 bg-white font-bold'
          onClick={clearSearch}
        />
        {searchResults.length > 0 && (
          <ul className='border-2 text-sm rounded-l w-full mt-[5.5rem] absolute drop-shadow-xl -top-12 -left-1 bg-white p-2 z-10'>
            {searchResults?.map(result => {
              return (
                <>
                  {result.type && result.type === 'action'
                    ? (
                      <div className='bg-amber-50 p-4 flex'>
                        <Icon
                          name='error'
                          width={20}
                          className='h-full fill-amber-400 mr-2 min-h-[20px] min-w-[20px]'
                        />
                        <div>
                          <li className='text-sm leading-[125.02%] text-gray-800 font-normal mt-1 mb-2'>No se encontraron Resultados</li>
                          <li className='text-xs leading-[125.02%] text-slate-300 font-normal'>Te recomendamos seleccionar una opción de la lista o volver a ingresarla.</li>
                        </div>
                      </div>
                      )
                    : (
                      <li
                        className='z-10 flex py-2 cursor-pointer px-4'
                        onClick={() => handleClickAddress(result)}
                        key={result.id}
                      >
                        {addressParserUnnormalized(result.place_name_es)[0]},
                        {addressParserUnnormalized(result.place_name_es)[1]},
                        {addressParserUnnormalized(result.place_name_es)[2].slice(
                          0,
                          -8
                        )}
                      </li>
                      )}
                </>
              )
            })}
          </ul>
        )}
      </div>
    </>
  )
}

Autocomplete.propTypes = {
  onPlaceSelected: PropTypes.func,
  showNoResultsAction: PropTypes.bool
}

export default Autocomplete
