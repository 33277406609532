import { LOCAL_PICKUP_DELIVERY_TIME } from '../constants/stores'

export const filterPoints = (points, shopifyLocations) => {
  if (!shopifyLocations.disabled && !shopifyLocations.locations.length) {
    return points
  } else if (shopifyLocations.disabled) {
    return points.filter((p) => p?.properties?.type !== 'owned')
  } else {
    const finalPoints = points.filter((point) => {
      if (point.properties.type !== 'owned') {
        return true
      }
      if (!point.properties.cmsId) return true

      const match = shopifyLocations.locations.some(
        (shopifyLocation) => point.properties.cmsId === shopifyLocation
      )
      return match
    })

    return finalPoints
  }
}

export const checkLocations = (edges, variantQuantity) => {
  const points = edges
    .map((point) => {
      if (point.node.available >= variantQuantity) {
        return point.node.location.id
      }
      return false
    })
    .filter((point) => point)
  return points
}

export const matchProductsLocations = (points) => {
  const match = points.reduce((a, b) => a.filter((c) => b.includes(c)))
  return match
}

export const editDeliveryTimeLocalPickup = (points, companyName) => {
  return points.map((point) => {
    if (LOCAL_PICKUP_DELIVERY_TIME[companyName]) {
      if (companyName === 'Atakama Outdoor' && point.properties.title.toLowerCase().includes('flapp')) {
        point.properties.deliveryTime = LOCAL_PICKUP_DELIVERY_TIME[companyName]
      } else if (companyName !== 'Atakama Outdoor') {
        point.properties.deliveryTime = LOCAL_PICKUP_DELIVERY_TIME[companyName]
      }
    }
    return point
  })
}

export const splitPointsByType = (points) =>
  points.reduce(
    (acc, point) => {
      acc[point.properties.type === 'owned' ? 0 : 1].push(point)
      return acc
    },
    [[], []]
  )

export const changePointsDetails = (points) => {
  if (points) {
    points.forEach((point) => {
      point.properties.schedules.map((schedule, index) => {
        return (point.properties.schedules[index] = schedule.replace(
          'Cerrado',
          'No Disponible'
        ))
      })
    })
  }

  return points
}
