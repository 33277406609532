import { regionId, regionCode } from './addressParser'
import { createRandomString } from './utils'

export const billingAddress = (addressInfo, userInfo) => ({
  city: addressInfo.district,
  country_id: 'CL',
  custom_attributes: [
    {
      attribute_code: 'rut',
      value: userInfo.rut
    },
    {
      attribute_code: 'pinflag_shipping_method',
      value: addressInfo.deliveryType
    },
    {
      attribute_code: 'pinflag_shipping_price',
      value: addressInfo.price
    },
    {
      attribute_code: 'pinflag_address_comment',
      value: addressInfo.aditionalInfo
    },
    {
      attribute_code: 'pinflag_save_customer_info',
      value: userInfo.saveData
    }
  ],
  firstname: userInfo.names.substring(0, userInfo.names.indexOf(' ')),
  lastname: userInfo.names.substring(userInfo.names.indexOf(' ') + 1),
  postcode: `${addressInfo.price}`,
  region: addressInfo.region,
  region_code: regionCode[addressInfo.region],
  region_id: `${regionId[regionCode[addressInfo.region]]}`,
  saveInAddressBook: null,
  street: [addressInfo.userAddress],
  email: userInfo.email,
  telephone: userInfo.phone
})

export const shippingObject = (addressInfo, userInfo) => ({
  addressInformation: {
    billing_address: billingAddress(addressInfo, userInfo),
    shipping_address: {
      city: addressInfo.district,
      country_id: 'CL',
      custom_attributes: [
        {
          attribute_code: 'rut',
          value: userInfo.rut
        },
        {
          attribute_code: 'pinflag_shipping_method',
          value: addressInfo.deliveryType
        },
        {
          attribute_code: 'pinflag_shipping_price',
          value: addressInfo.price
        },
        {
          attribute_code: 'pinflag_address_comment',
          value: addressInfo.aditionalInfo
        },
        {
          attribute_code: 'pinflag_save_customer_info',
          value: userInfo.saveData
        }
      ],
      extension_attributes: {
        rut: userInfo.rut,
        pinflag_shipping_method: addressInfo.deliveryType,
        pinflag_shipping_price: addressInfo.price,
        pinflag_address_comment: addressInfo.aditionalInfo,
        pinflag_save_customer_info: userInfo.saveData
      },
      firstname: userInfo.names.substring(0, userInfo.names.indexOf(' ')),
      lastname: userInfo.names.substring(userInfo.names.indexOf(' ') + 1),
      postcode: `${addressInfo.price}`,
      region: addressInfo.region,
      region_code: regionCode[addressInfo.region],
      region_id: `${regionId[regionCode[addressInfo.region]]}`,
      street: [addressInfo.userAddress],
      telephone: userInfo.phone,
      email: userInfo.email
    },
    shipping_carrier_code: 'pinflag',
    shipping_method_code: 'pinflag'
  }
})

export const getGiftCardObjectVtex = (giftCardCode) => (
  {
    id: createRandomString(),
    inUse: true,
    isSpecialCard: false,
    provider: 'VtexGiftCard',
    redemptionCode: giftCardCode
  }
)

export const getPaymentDataObjectVtex = (parcialPaymentData, cartTotal) => (
  {
    group: parcialPaymentData.groupName,
    installments: 1,
    installmentsInterestRate: 0,
    installmentsValue: cartTotal,
    paymentSystem: parcialPaymentData.id,
    paymentSystemName: parcialPaymentData.code,
    referenceValue: cartTotal,
    value: cartTotal
  }
)
