import pagoFacilLogo from '../assets/pago-facil-logo.png'
import depositoBancarioLogo from '../assets/deposito-bancario-logo.png'
import webpayLogo from '../assets/logo-webpay.png'
import mercadoPagoLogo from '../assets/mercado-pago.png'
import ventiPayLogo from '../assets/ventipay-logo.png'
import flowLogo from '../assets/logo-visa-mastercard.png'
import mercadoPagoSelfieLogo from '../assets/mercado-pago-selfie.png'
import webpayPlusLogo from '../assets/webpay-plus.png'
import webpayOneclickLogo from '../assets/webpay-oneclick.png'
import fintocLogo from '../assets/logo-fintoc.png'
import bitcoin from '../assets/Bitcoin.png'

export const paymentLogos = (companyName) => ({
  'Pago Facil by Bci Pagos': { logo: pagoFacilLogo, width: '40px' },
  transbank_webpay: { logo: webpayLogo },
  Webpay: { logo: webpayLogo },
  'Mercado Pago': companyName === 'SELFIE'
    ? { logo: mercadoPagoSelfieLogo, width: '120px' }
    : { logo: mercadoPagoLogo, width: '50px' },
  checkmo: { logo: depositoBancarioLogo, width: '50px' },
  VentiPay: { logo: ventiPayLogo, width: '30px' },
  Venti: { logo: ventiPayLogo, width: '30px' },
  'Depósito Bancario': { logo: depositoBancarioLogo, width: '50px' },
  'Checkout Mercado Pago': { logo: mercadoPagoLogo, width: '50px' },
  'Depósito bancario': { logo: depositoBancarioLogo, width: '50px' },
  FLOW: { logo: flowLogo },
  Flow: { logo: flowLogo },
  Wibond: { logo: depositoBancarioLogo, width: '50px' },
  'Transferencia Bancaria Rápida | Fintoc': { logo: fintocLogo, width: '30px' },
  Fintoc: { logo: fintocLogo, width: '30px' },
  'Webpay Oneclick': { logo: webpayOneclickLogo, width: '80px' },
  'Transbank Webpay Plus': { logo: webpayPlusLogo, width: '80px' },
  'Webpay Plus': { logo: webpayPlusLogo, width: '80px' },
  MercadoPago: { logo: mercadoPagoLogo, width: '50px' },
  Bitcoin: { logo: bitcoin, width: '50px' }
})
