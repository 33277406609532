import React, { useContext, useState, useEffect } from 'react'
import { DataContext } from '../context/DataContext'
import { BUTTON_TEXT_TRANSLATION_DELIVERY } from '../constants/translation'
import {
  Icon,
  EditableSelector,
  Button,
  Spinner,
  CardOptionSummary
} from '@pinflag/pinflag-ui-kit'
import { useApiPinflag } from '../hook/useApiPinflag'
import PickupMap from './organism/PickupMap'
import AddressTypeInputSelector from './organism/AddressTypeInputSelector'
import { PopUpSendCode } from './organism/PopUpSendCode'
import { PopUpUniqueStore } from './organism/PopUpUniqueStore'
import {
  getRegionById,
  regionCode
} from '../helpers/addressParser'
import { gaPaymentPostMessage } from '../helpers/gaEventsHelper'
import { useCmsAdapter } from '../hook/useCmsAdapter'
import { findLowestPrice, setServiceAndShippingcost } from '../helpers/utils'
import { HOME_DELIVERY, LOCAL_PICKUP } from '../constants/deliveryTypes'

export const Summary = () => {
  const {
    ecommerceInfo,
    customerInfo,
    setCustomerInfo,
    steps,
    setSteps,
    setCurrentStep,
    selectedShippingMethod,
    setSelectedShippingMethod,
    pointsData,
    setSelectedServiceType,
    setAddressInfo,
    isUserValidated,
    setIsEditing,
    options,
    setOptions,
    setIsAddingAddress,
    count,
    setCount,
    isShowingMap,
    setIsShowingMap,
    setCartData,
    cartData,
    setIsSummaryOpen
  } = useContext(DataContext)

  const { fullName, addresses } = customerInfo

  const [selectedOption, setSelectedOption] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [isLoadingPointsData, setIsLoadingPointsData] = useState(true)
  const [pickUp, setPickUp] = useState([])
  const [services, setServices] = useState([])
  const [selectedAddress, setSelectedAddress] = useState('')
  const [buttonText, setButtonText] = useState('Continuar')
  const { localPoints, pinflagPoints } = pointsData
  const [arrayAddress, setArrayAddress] = useState([])
  const [customerAddressId, setCustomerAddressId] = useState('')
  const [hardLoginToken, setHardLoginToken] = useState('')

  const areDisabled = {
    localPickup: !localPoints.length,
    cnc: !pinflagPoints.length,
    homeDelivery: false
  }

  const { calculateShippingPrice, getPoints, serviceClickAndCollect, deleteCustomerAddresses } = useApiPinflag()

  const { trackingThirdStep } = useCmsAdapter()

  const handleOptionSelect = (option) => {
    setSelectedOption(option)
  }

  const handleConfirmation = (test) => {
    setSelectedServiceType(test)
  }

  const handleUpdateSteps = () => {
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    setSteps(updatedSteps)
    setCurrentStep(2)
  }

  const handleClick = () => {
    setIsSummaryOpen(false)
    if (isUserValidated) {
      setAddressInfo(prevState => {
        return {
          ...prevState,
          userAddress: selectedAddress.label,
          district: selectedAddress.city,
          region: getRegionById((selectedAddress.stateId).toString()),
          serviceName: selectedOption.name,
          price: selectedOption.price,
          deliveryType: HOME_DELIVERY,
          serviceType: selectedOption.serviceType
        }
      })
    } else {
      setAddressInfo(prevState => {
        return {
          ...prevState,
          userAddress: selectedAddress.label,
          district: selectedAddress.city,
          region: '',
          serviceName: selectedOption.name,
          addressIndex: selectedAddress.addressIndex,
          price: selectedOption.price,
          deliveryType: HOME_DELIVERY,
          serviceType: selectedOption.serviceType
        }
      })
    }

    if (selectedOption.serviceType === 'localPickup') {
      setSelectedShippingMethod('local-pickup')
    } else if (selectedOption.serviceType === 'clickAndCollect') {
      setSelectedShippingMethod('click-and-collect')
    } else {
      setSelectedShippingMethod('home-delivery')
      setServiceAndShippingcost(selectedOption, setSelectedServiceType, setCartData, cartData)
      const updatedSteps = [...steps]
      updatedSteps[0].completed = true
      updatedSteps[1].disabled = false
      updatedSteps[1].subSteps[0].completed = true
      updatedSteps[1].subSteps[1].completed = true
      updatedSteps[1].subSteps[2].completed = true
      setSteps(updatedSteps)
    }
  }

  const handlePointConfirmation = (point) => {
    setSelectedShippingMethod('local-pickup')
    serviceClickAndCollect(point).then((service) => {
      setAddressInfo(prevState => {
        return {
          ...prevState,
          userAddress: point.address,
          aditionalInfo: '',
          region: point.region,
          district: point.district,
          price: point.price,
          deliveryType: LOCAL_PICKUP,
          sla: point.deliveryTime,
          courier: point.company,
          serviceName: service.name,
          cmsId: point.isLocalPickup ? point.cmsId : undefined
        }
      })
      gaPaymentPostMessage()
      setCartData(prevState => {
        const shippingCost = point.price
        return {
          ...prevState,
          shippingCost
        }
      })
      setIsSummaryOpen(false)
      trackingThirdStep({
        timeOpenThirdStep: new Date(),
        flowType: isUserValidated ? 'hard-login' : undefined
      })
      const updatedSteps = [...steps]
      updatedSteps[0].completed = true
      updatedSteps[1].disabled = false
      updatedSteps[1].subSteps[0].completed = true
      updatedSteps[1].subSteps[1].completed = true
      updatedSteps[1].subSteps[2].completed = true
      setSteps(updatedSteps)
      setCurrentStep(3)
    })
  }

  const handleAddressSelect = (address) => {
    if (count === 0) {
      setCount(1)
    } else {
      setIsLoadingOptions(true)
      setSelectedAddress(address)
      if (!isUserValidated) {
        setCustomerAddressId(address.id)
      } else {
        setArrayAddress([address.label, address.city, regionCode[getRegionById((address.stateId).toString())]])
        setCustomerAddressId(address.id)
      }
    }
  }

  const handleShowMap = () => {
    setIsShowingMap(!isShowingMap)
  }

  const handleAddNewAddress = () => {
    if (isUserValidated) {
      setIsSummaryOpen(false)
      setSelectedShippingMethod('home-delivery')
      const updatedSteps = [...steps]
      updatedSteps[0].completed = true
      updatedSteps[1].disabled = false
      updatedSteps[1].subSteps[0].completed = true
      updatedSteps[1].subSteps[1].completed = false
      updatedSteps[1].subSteps[2].completed = false
      setSteps(updatedSteps)
      setIsAddingAddress(true)
    } else {
      setIsEditing(true)
    }
  }

  const handleDeleteAddress = (data) => {
    deleteCustomerAddresses([data.id], hardLoginToken)
    setCustomerInfo(prevState => {
      return {
        ...prevState,
        addresses: addresses.filter(object => object.id !== data.id)
      }
    })
  }

  useEffect(() => {
    setCount(0)
    setIsAddingAddress(false)
    const optionsAux = []
    for (let i = 0; i < addresses.length; i++) {
      const option = {
        label: addresses[i].address,
        value: addresses[i].address,
        id: addresses[i].id,
        addressIndex: i,
        city: addresses[i].city
      }
      optionsAux.push(option)
    }
    setSelectedAddress(optionsAux[0])
    setOptions(optionsAux)
    getPoints(setIsLoadingPointsData)
    handleUpdateSteps()
    if (!isUserValidated) {
      setCustomerAddressId(addresses[0].id)
    } else {
      setArrayAddress([addresses[0].label, addresses[0].city, regionCode[getRegionById((addresses[0].stateId).toString())]])
      setCustomerAddressId(addresses[0].id)
    }
  }, [])

  useEffect(() => {
    if (!isLoadingPointsData) {
      setPickUp([
        {
          price: localPoints.length === 1 ? localPoints[0].properties.price : findLowestPrice(localPoints),
          packageDeliveryTime: 'Retira en tienda',
          icon: 'white-point',
          serviceType: 'localPickup',
          isExactPrice: localPoints.length === 1
        },
        {
          price: pinflagPoints.length === 1 ? pinflagPoints[0].properties.price : findLowestPrice(pinflagPoints),
          packageDeliveryTime: 'Retira en punto',
          icon: 'here',
          serviceType: 'clickAndCollect',
          isExactPrice: pinflagPoints.length === 1
        }
      ])
    }
  }, [isLoadingPointsData])

  useEffect(() => {
    setIsLoading(true)
  }, [isUserValidated])

  useEffect(() => {
    if (isUserValidated) {
      setCount(0)
      const optionsAux = []
      for (let i = 0; i < addresses.length; i++) {
        const option = {
          label: addresses[i].address,
          value: addresses[i].address,
          id: addresses[i].id,
          stateId: addresses[i].stateId,
          city: addresses[i].city
        }
        optionsAux.push(option)
      }
      setSelectedAddress(optionsAux[0])
      setOptions(optionsAux)

      setArrayAddress([addresses[0].label, addresses[0].city, regionCode[getRegionById((addresses[0].stateId).toString())]])
      setCustomerAddressId(addresses[0].id)
    }
  }, [customerInfo])

  useEffect(() => {
    setIsLoadingOptions(false)
    setSelectedOption('')
  }, [services])

  useEffect(() => {
    if (pickUp.length > 0) {
      calculateShippingPrice(arrayAddress, setServices, setIsLoading, handleConfirmation, 'twoclick', customerAddressId)
    }
  }, [arrayAddress, pickUp, customerAddressId])

  useEffect(() => {
    if (selectedOption.serviceType === 'localPickup') {
      setButtonText(BUTTON_TEXT_TRANSLATION_DELIVERY[selectedOption.serviceType][localPoints.length > 1 ? 1 : 0])
    } else {
      setButtonText(BUTTON_TEXT_TRANSLATION_DELIVERY[selectedOption.serviceType] || 'Continuar')
    }
  }, [selectedOption])

  return (
    <>
      <div className={` md:h-full ${(selectedShippingMethod === null || selectedShippingMethod === 'home-delivery') ? 'md:pb-9 h-full' : 'grow'} md:w-[537px] bg-slate-100 ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-white'} pt-2`}>
        { isLoading
          ? (
            <Spinner/>
            )
          : (!selectedShippingMethod || localPoints.length === 1)
              ? (

            <div>
              <PopUpSendCode
                setHardLoginToken={setHardLoginToken}
              />
              <PopUpUniqueStore/>
              <div
              className={`mt-1 mx-2 md:mx-5 rounded-[10px] pb-2 md:mt-2 ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-slate-100'}`}
              >

                <div className='flex flex-col items-left justify-between text-sm mx-6 md:mx-4  md:pb-1 pt-3'>
                  <span className='hidden md:block pl-2 text-sm font-semibold text-gray-800'>{`Hola de nuevo ${fullName}`}</span>
                  <span className='block md:hidden pl-2 text-sm font-semibold text-gray-800'>{`Hola ${fullName}`}</span>
                  <div className='text-xs pl-2 text-slate-400 md:text-[9px]'>
                    Elige el modo de entrega que prefieras
                  </div>
                </div>

              </div>
              <div className={`md:bg-white mt-1 mx-[0px] md:mx-5 rounded-t-[10px] pb-2 md:mt-2 md:flex ${areDisabled.localPickup ? 'md:flex-col' : 'md:flex-col-reverse'} ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-slate-100'} ${addresses.length === 0 ? 'hidden' : 'block'}`}>

                <div className='bg-white flex flex-col items-left justify-between rounded md:pl-0 md:pr-0 pl-3 pr-3 pb-2 text-sm mx-[20px] md:mx-4 mb-2 md:pb-1 pt-3 flex-1'>
                  <div className='flex flex-row items-left pb-4 md:pb-0'>
                    <Icon customStyle={{ fill: 'gray' }} name="home-delivery" width={18} className='md:hidden' />
                    <span className='pl-2 text-xs font-semibold text-gray-800'>Despacho a domicilio</span>
                  </div>
                  <div>
                    <div className='md:hidden text-xs md:pl-2 text-slate-400 md:text-[9px]'>
                      Mi Dirección
                    </div>
                    <EditableSelector
                      label= 'Select label'
                      defaultValue= { options[0] }
                      options= {options}
                      name= 'test input'
                      containerClass= 'bg-gray-100 mt-2 !py-0 !px-0'
                      optionsContainerClass= 'bg-gray-100 shadow-sm py-2'
                      optionClass= 'hover:bg-green-200'
                      optionSelectClass='relative md:static ml-6'
                      onChange= {(address) => { handleAddressSelect(address) }}
                      handleAdd= {handleAddNewAddress}
                      handleDelete={handleDeleteAddress}
                      buttonText= "Ingresa otra dirección"
                      buttonClass= 'flex items-center !px-0 !py-0 mt-1 mr-4 text-sm font-light text-[color: #000000] underline decoration-[1.5px]'
                      buttonContainer= 'flex flex-row pb-2 px-5'
                      arrowIconClass= 'md:bg-[#FFFFFF] md:rounded-full ml-3'
                      setterOptions= {setOptions}
                      isValidated= {isUserValidated}
                      setIsEditing= {setIsEditing}
                    />
                  </div>
                  <div className='flex flex-col md:flex-row md:space-x-3'>

                    {isLoadingOptions
                      ? (
                        <div className='flex h-[50px] md:h-[90px] pt-2 pb-2 justify-center px-1 items-center mt-2 w-full'>
                          <Spinner/>
                        </div>
                        )
                      : (
                          services.services.map((option) => {
                            const isSelected = selectedOption.serviceType === option.serviceType
                            return (
                              <div key={option.serviceType} className='w-full md:w-[225px]'>
                                <CardOptionSummary
                                  isSelected= {isSelected}
                                  isLongCard= {services.services.length <= 2}
                                  showPrice= {true}
                                  option= {option}
                                  handleOptionSelect= {handleOptionSelect}
                                  isExactPrice= {true}
                                />
                              </div>

                            )
                          })

                        )
                    }

                  </div>

                </div>

                <div className= 'flex flex-col md:flex-row items-left justify-between px-5 md:px-4 '>

                  {pickUp.map((option) => {
                    const isSelected = selectedOption.icon === option.icon
                    return (
                      <div key={option.icon} className= {`bg-white flex flex-col items-left justify-between rounded md:pl-0 md:pr-0 pl-3 pr-3 pb-2 mb-2 md:pb-1 pt-3 flex-1 md:max-w-[225px] ${(areDisabled.localPickup && option.serviceType === 'localPickup') ? 'hidden' : 'block'} ${(areDisabled.cnc && option.serviceType === 'clickAndCollect') ? 'hidden' : 'block'}`}>
                        <div className='flex flex-row'>
                          <Icon customStyle={{ fill: 'gray' }} name={option.icon === 'white-point' ? 'local-pickup' : 'click-and-collect' } width={18} className='md:hidden' />
                          <div className={`flex flex-row justify-between ${option.icon === 'white-point' ? 'w-full' : 'md:w-fit'}`}>
                            <span className={`${option.icon === 'white-point' ? 'block' : 'hidden'} pl-2 text-xs font-semibold text-gray-800`}>{(localPoints.length === 1) ? 'Retira en nuestra tienda' : 'Retira en nuestras tiendas'}</span>
                            <div className={`${(option.icon === 'white-point' && localPoints.length === 1) ? 'block' : 'hidden'} pr-2 text-[9px] font-semibold text-gray-800 justify-between`}>
                              <Button
                                role='button'
                                className='font-inter text-[color:#00A657] md:text-[color:#394860] font-medium text-[9px] underline decoration-1 leading-[16px] !px-0 !py-0'
                                label='Ver Mapa'
                                disabled={false}
                                customStyle={{ }}
                                onClick={handleShowMap}
                              />
                            </div>
                          </div>
                          <span className={`${option.icon === 'white-point' ? 'hidden' : 'block'} pl-2 text-xs font-semibold text-gray-800`}>Retira en un punto</span>
                        </div>
                        <CardOptionSummary
                          isSelected= {isSelected}
                          isLongCard= {true}
                          showPrice= {true}
                          option= {option}
                          handleOptionSelect= {handleOptionSelect}
                          isExactPrice= {option.isExactPrice || (!option.isExactPrice && option.price === 0)}
                        />
                      </div>
                    )
                  })}

                </div>
              </div>
              <div className='mx-[20px] pb-[20px] md:flex md:justify-end md:bg-white md:rounded-b-[10px]'>
                <Button
                  role='button'
                  className='h-[3rem] bg-black text-white text-base w-full rounded-[7px] mt-3 mb-2 md:mr-4 pb-1 justify-center disabled:bg-slate-100 disabled:text-gray-500 md:h-[37px] md:w-[149px] md:px-[15px] md:my-0.5 md:rounded-[40px]'
                  label={buttonText}
                  disabled={selectedOption === ''}
                  loading={false}
                  customStyle={{ backgroundColor: '#000000' }}
                  testId='go-delivery'
                  onClick={ (localPoints.length === 1 && selectedOption.serviceType === 'localPickup') ? () => handlePointConfirmation(localPoints[0].properties) : handleClick}
                />
              </div>
            </div>
                )
              : selectedShippingMethod === 'home-delivery'
                ? (
                  <AddressTypeInputSelector/>
                  )
                : (
                  <div className='h-full'>
                  <PickupMap />
                  </div>
                  )}
      </div>
    </>
  )
}
