import axios from 'axios'
import { useContext } from 'react'
import { DataContext } from '../context/DataContext'
import { setPaymentInformationApi, setShippingInformationApi } from '../helpers/magentoApi'
import { normalizeRegionName } from '../helpers/addressParser'
import { transformCart, transformCartTotals } from '../helpers/utils'
import { processPayment } from '../helpers/processPayment'

export const useMagento = () => {
  const {
    userInfo,
    addressInfo,
    setIsPaying,
    setEcommerceInfo,
    setCartData,
    ecommerceInfo,
    setPaymentMethods
  } = useContext(DataContext)

  const setInitialInformationMagento = async (message, setInitialInfoReady) => {
    const { cms, quoteId, domain, currentUrl } = message
    if (cms !== undefined && cms !== 'magento') return
    setEcommerceInfo(prevState => {
      return { ...prevState, cms, magentoQuoteId: quoteId, domain, currentUrl }
    })
    const cart = await axios(`${domain}/rest/V1/guest-carts/${quoteId}`)
    const cartTotals = await axios(
      `${domain}/rest/V1/guest-carts/${quoteId}/totals`
    )
    setCartData(prevState => {
      return {
        ...prevState,
        lines: transformCart(cart.data.items),
        ...transformCartTotals(cartTotals.data)
      }
    })
    setInitialInfoReady(true)
  }

  const iframeLoadedMagento = () => {
    window.parent.postMessage(
      {
        type: 'iframe-mounted',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const removeIframeMagento = () => {
    window.parent.postMessage(
      {
        type: 'remove-iframe',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const redirectToHomeMagento = () => {
    window.parent.postMessage(
      {
        type: 'redirect-to-home',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const submitFormMagento = () => {
    setTimeout(() => {
      window.parent.postMessage(
        {
          type: 'submit-form',
          origin: 'pinflag-checkout-iframe',
          message: true
        },
        '*'
      )
    }, 3000)

    setTimeout(() => setIsPaying(false), 8000)
  }

  const shippingPostMessageMagento = () => {
    if (ecommerceInfo.cms !== undefined && ecommerceInfo.cms !== 'magento') { return }
    const firstname = userInfo.names.substring(0, userInfo.names.indexOf(' '))
    const lastname = userInfo.names.substring(userInfo.names.indexOf(' ') + 1)
    window.parent.postMessage(
      {
        type: 'set-form-data',
        origin: 'pinflag-checkout-iframe',
        message: {
          'custom_attributes[pinflag_address_comment]':
            addressInfo.aditionalInfo,
          'custom_attributes[pinflag_shipping_method]':
            addressInfo.deliveryType,
          'custom_attributes[pinflag_shipping_price]': `${addressInfo.price}`,
          'custom_attributes[pinflag_save_customer_info]': userInfo.saveData,
          'custom_attributes[rut]': userInfo.rut,
          'street[0]': addressInfo.userAddress,
          city_id: addressInfo.district,
          firstname,
          lastname,
          region_id: normalizeRegionName(addressInfo.region),
          telephone: userInfo.phone,
          username: userInfo.email
        }
      },
      '*'
    )
  }

  const setShippingInformationMagento = async () => {
    if (ecommerceInfo.cms !== '' && ecommerceInfo.cms !== 'magento') return

    const shippingInformation = await setShippingInformationApi(
      ecommerceInfo.domain,
      ecommerceInfo.magentoQuoteId,
      userInfo,
      addressInfo
    )

    // const availiblePaymentMethods = shippingInformation.payment_methods

    const availablePaymentMethods = [
      {
        code: 'transbank_webpay',
        title: 'Webpay',
        carrier_code: 'transbank_webpay'
      },
      {
        code: 'cashondelivery',
        title: 'Efectivo',
        carrier_code: 'cashondelivery'
      }
    ]

    setPaymentMethods(availablePaymentMethods)

    // ------------- View to select payment method -------------

    const hardcodedPaymentMethod = availablePaymentMethods[0].code // 'transbank_webpay'

    const paymentMethod = await setPaymentInformationApi(
      ecommerceInfo.domain,
      ecommerceInfo.magentoQuoteId,
      userInfo,
      addressInfo,
      hardcodedPaymentMethod
    )

    const paymentData = {
      amount: shippingInformation.totals.base_grand_total,
      ecommerceInfo
    }

    await processPayment[paymentMethod](paymentData)
  }

  const setShippingInfoMagento = (setPurchaseAmount, setIsLoading) => {
    if (ecommerceInfo.cms !== '' && ecommerceInfo.cms !== 'magento') return
    setIsLoading(true)
    setShippingInformationApi(ecommerceInfo.domain, ecommerceInfo.magentoQuoteId, userInfo, addressInfo).then(response => {
      const { payment_methods: paymentMethods, totals: { base_grand_total: amount } } = response.data
      setPaymentMethods(paymentMethods)
      setPurchaseAmount(amount)
      setIsLoading(false)
    }).catch(error => (console.log(error)))
  }

  return {
    setInitialInformationMagento,
    iframeLoadedMagento,
    removeIframeMagento,
    shippingPostMessageMagento,
    submitFormMagento,
    redirectToHomeMagento,
    setShippingInformationMagento,
    setShippingInfoMagento
  }
}
