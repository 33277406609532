/* eslint-disable react/prop-types */
import React from 'react'

class MapErrorWrapper extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch () {
    this.props.setMapError(true)
  }

  render () {
    if (this.state.hasError) {
      return (
        <div style={{ position: 'relative' }}>
          <div className="h-[400px] flex flex-col gap-1 px-6 text-center font-semibold justify-center">
            <h2 className="">Tu navegador no tiene disponible WebGL</h2>
            <div className="text-sm"> Intenta habilitarlo, utilizar otro dispositivo, o bien elegir despacho a domicilio.</div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default MapErrorWrapper
