import axios from 'axios'
import cache from 'axios-cache-plugin'

import { COMPANY_API_BASE_URL } from '../constants/url'

const cacheAdapter = cache.adapter

const companyApiInstance = (pinflagApiKey) =>
  axios.create({
    baseURL: COMPANY_API_BASE_URL,
    headers: { 'x-api-key': pinflagApiKey },
    timeout: 7000,
    adapter: cacheAdapter,
    cache: cache({
      maxAge: 15 * 60 * 1000 // cache for 15 minutes
    })
  })

export default companyApiInstance
