import awsInstance from '../helpers/VerifyInstace'
import axios from 'axios'
import { useContext } from 'react'
import { DataContext } from '../context/DataContext'

export const useVerify = () => {
  const {
    setCustomerInfo,
    setUserInfo
  } = useContext(DataContext)

  const sendCustomerCode = async (email) => {
    try {
      const body = {
        email
      }
      const response = await awsInstance.post('/generate-otp', body)
      return response.data
    } catch (error) {
      console.log('Error:', error)
    }
  }

  const verifyCustomerCode = async (token, sessionId) => {
    const body = {
      token,
      sessionId
    }
    try {
      const response = await awsInstance.post('/verify-otp', body)
      return response.data
    } catch (error) {
      console.log('error', error.response.data)
      return error.response.data
    }
  }

  const getCustomerDataVerify = async (token) => {
    try {
      const data = await axios.get('https://company-api.pinflag.cl/api/customer/verified', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      setCustomerInfo(data.data)
      setUserInfo(prevState => {
        return {
          ...prevState,
          email: data.data.email,
          names: data.data.fullName,
          phone: data.data.phone,
          rut: data.data.ssn
        }
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  return {
    sendCustomerCode,
    verifyCustomerCode,
    getCustomerDataVerify
  }
}
