import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Input, Button, Icon, SectionTitle, Select, ToggleInput } from '@pinflag/pinflag-ui-kit'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { InputValidator } from '../helpers/Validator'
import { DataContext } from '../context/DataContext'
import { prettifyRut } from 'react-rut-formatter'
import { useVtex } from '../hook/useVtex'
import { useCmsAdapter } from '../hook/useCmsAdapter'
import { useApiPinflag } from '../hook/useApiPinflag'
import { scrollToBottom, onlyLetters, onlyNumbers, abreviatedCheckoutLines, onlySomeNumbers } from '../helpers/utils'
import { setUserInfoLocalStorage } from '../helpers/localStorage'
import { resetDataSelected } from '../helpers/resetContext'
import { useShopify } from '../hook/useShopify'
import { regionBillingCode } from '../helpers/addressParser'
import { PopUpSendCode } from './organism/PopUpSendCode'
import { gaCompletedUserInfoPostMessage } from '../helpers/gaEventsHelper'
import { useWoocommerce } from '../hook/useWoocommerce'
import InputDiscountCode from './organism/InputDiscountCode'
import { labelDiscountCodeGiftCard } from '../helpers/labelDiscountCodeGiftCard'

const UserForm = ({ className }) => {
  const {
    userInfo: userData,
    billingInfo: billingData,
    setIsPickup,
    setUserInfo,
    steps,
    setSteps,
    setCurrentStep,
    setBillingInfo,
    ecommerceInfo,
    companyInfo,
    cartData,
    setPointsData,
    setSelectedShippingMethod,
    setSelectedServiceType,
    setDeliveryInfo,
    setCartData,
    setIsSaving,
    isSaving,
    isEditing,
    setIsEditing,
    isUserValidated,
    setCustomerInfo,
    deletedCheckoutLog,
    currentStep,
    discountInfo,
    isEditingDiscountCode,
    setIsEditingDiscountCode
  } = useContext(DataContext)
  const { primaryColor, secondaryColor, companyName, useBillingData, useTwoClick, useSsn, useDiscountCode, useGiftCard } = companyInfo
  const { discountCodes } = discountInfo
  const { createCheckoutLog, validateEmail } = useCmsAdapter()
  const { validateDiscountCodesForCustomer } = useShopify()
  const { saveUserInformationVtex, addDiscountCodeVtex } = useVtex()
  const { isEmailRegistered } = useApiPinflag()
  const { getPaymentMethodsWoocommerce, validateCouponsWoocommerce } = useWoocommerce()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm({
    mode: 'all'
  })
  const isShopify = ecommerceInfo.cms === 'shopify'
  const [customErrors, setCustomErrors] = useState({})
  const [loadingButton, setLoadingButton] = useState(false)
  const [loadingRegistered, setLoadingRegistered] = useState(false)
  const [isConsultingRegisteredEmail, setIsConsultingRegisteredEmail] = useState(false)
  const [warningDiscountCode, setWarningDiscountCode] = useState(false)
  const [isBilling, setIsBilling] = useState(
    !!billingData.corporateName
  )

  let timer = 0
  const [inputValue, setInputValue] = useState('')
  const handleOnBlurEmail = async (email) => {
    const data = {
      email,
      names: '',
      phone: '',
      rut: '',
      saveData: false
    }
    if (!isUserValidated && useTwoClick > 0) {
      isRegisteredEmail(email)
    }
    if (!deletedCheckoutLog) {
      const checkoutData = {
        email,
        lineItems: abreviatedCheckoutLines(cartData.lines)
      }
      createCheckoutLog(checkoutData)
    }
    await saveUserInformationVtex(data, isBilling ? billingData : false)
    const onlyDiscountCodes = discountCodes.filter(code => code.type === 'coupon')
    if (onlyDiscountCodes.length > 0) {
      await addDiscountCodeVtex(onlyDiscountCodes[0].code)
    }
  }

  const userInfo = {
    email: {
      ...register('email', {
        required: 'Campo requerido',
        validate: value =>
          InputValidator.isEmailValid(value) || 'Ingresa un correo válido',
        onBlur: (e) => {
          setCustomErrors({ ...customErrors, email: errors?.email })

          if (InputValidator.isEmailValid(e.target.value)) {
            handleOnBlurEmail(e.target.value)
          }
        },
        onChange: (e) => {
          setInputValue(e.target.value)
          if (!isConsultingRegisteredEmail && useTwoClick > 0) setIsConsultingRegisteredEmail(true)
        }
      }),
      label: 'Correo electrónico'
    },
    names: {
      ...register('names', {
        required: !userData.isRegistered ? 'Campo requerido' : false,
        validate: value =>
          InputValidator.isNameValid(value) || 'Nombre y/o Apellido inválidos',
        onBlur: () =>
          setCustomErrors({ ...customErrors, names: errors?.names }),
        onChange: e => {
          setValue('names', onlyLetters(e.target.value))
          if (isUserValidated) {
            const updatedSteps = [...steps]
            updatedSteps[1].disabled = true
            setSteps(updatedSteps)
          }
        }
      }),
      label: 'Nombre y Apellido '
    },
    phone: {
      ...register('phone', {
        required: !userData.isRegistered ? 'Campo requerido' : false,
        validate: value =>
          InputValidator.isPhoneValid(value, ecommerceInfo.cms) || 'Ingresa un número válido',
        onBlur: () =>
          setCustomErrors({ ...customErrors, phone: errors?.phone }),
        onChange: e => {
          setValue('phone', onlySomeNumbers(onlyNumbers(e.target.value)))
          if (isUserValidated) {
            const updatedSteps = [...steps]
            updatedSteps[1].disabled = true
            setSteps(updatedSteps)
          }
        }
      }),
      label: 'Teléfono'
    },
    rut: {
      ...register('rut', {
        required: (useSsn && !userData.isRegistered) ? 'Campo requerido' : false,
        validate: useSsn && (value => InputValidator.isRutValid(value) || 'Rut inválido'),
        onBlur: () => setCustomErrors({ ...customErrors, rut: errors?.rut }),
        onChange: e => setValue('rut', prettifyRut(e.target.value))
      }),
      label: 'Rut'
    },
    saveData: { ...register('saveData', { required: false }) }
  }

  if (!useSsn && ecommerceInfo.cms !== 'vtex') delete userInfo.rut

  const billingInfo = {
    corporateName: {
      ...register('corporateName', {
        required: isBilling ? 'Campo requerido' : false,
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            corporateName: errors?.corporateName
          })
      }),
      label: 'Razón Social'
    },
    corporateRut: {
      ...register('corporateRut', {
        required: isBilling ? 'Campo requerido' : false,
        validate: value =>
          InputValidator.isRutValid(value) || !isBilling || 'Rut inválido',
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            corporateRut: errors?.corporateRut
          }),
        onChange: e => setValue('corporateRut', prettifyRut(e.target.value))
      }),
      label: 'Rut'
    },
    category: {
      ...register('category', {
        required: isBilling ? 'Campo requerido' : false,
        onBlur: () =>
          setCustomErrors({ ...customErrors, category: errors?.category })
      }),
      label: 'Giro'
    },
    corporatePhone: {
      ...register('corporatePhone', {
        required: isBilling ? 'Campo requerido' : false,
        validate: value =>
          InputValidator.isPhoneValid(value) ||
          !isBilling ||
          'Ingrese un número con 9 dígitos',
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            corporatePhone: errors?.corporatePhone
          }),
        onChange: e => setValue('corporatePhone', onlySomeNumbers(onlyNumbers(e.target.value)))
      }),
      label: 'Teléfono'
    }
  }

  const shopifyExtraBillingInfo = {
    regionCode: register('regionCode', { required: isBilling && isShopify ? 'Campo requerido' : false }),
    district: {
      ...register('district', {
        required: isBilling && isShopify ? 'Campo requerido' : false,
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            district: errors?.district
          })
      }),
      label: 'Comuna'
    },
    address: {
      ...register('address', {
        required: isBilling && isShopify ? 'Campo requerido' : false,
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            address: errors?.address
          })
      }),
      label: 'Dirección'
    }
  }

  useBillingData && isShopify && Object.assign(billingInfo, shopifyExtraBillingInfo)

  const containerClasses = classNames(
    'flex flex-col items-between justify-between grow md:w-[495px] md:text-[11px]',
    className
  )

  const setDataToShippingStep = (userData, billingData) => {
    window.parent.postMessage(
      {
        type: 'ga-event-step1',
        origin: 'pinflag-shopify-pinmap-pro',
        message: 'pinmap_user_info_filled'
      },
      '*'
    )

    const checkoutData = {
      email: userData.email,
      lineItems: abreviatedCheckoutLines(cartData.lines)
    }
    createCheckoutLog(checkoutData)

    setUserInfo((prevState) => {
      return { ...prevState, ...userData }
    })

    isBilling && setBillingInfo(billingData)

    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    setSteps(updatedSteps)
    setCurrentStep(2)

    saveUserInformationVtex(userData, isBilling ? billingData : false)
  }

  const handleUserForm = () => {
    if (!isUserValidated) {
      const updatedSteps = [...steps]
      updatedSteps[0].completed = true
      updatedSteps[1].disabled = false
      setSteps(updatedSteps)
      setCurrentStep(2)
    }
  }

  useEffect(() => {
    if (!loadingRegistered) {
      setIsConsultingRegisteredEmail(false)
    }
  }, [loadingRegistered])

  const isRegisteredEmail = (email) => {
    if (email === '' || !InputValidator.isEmailValid(email)) return
    isEmailRegistered(email, setLoadingRegistered).then((response) => {
      if (response.error === undefined) {
        const newData = { email, isRegistered: true, encryptedData: response }
        setDataToShippingStep(newData, {})
      } else {
        setLoadingRegistered(false)
        setIsConsultingRegisteredEmail(false)
      }
    })
  }

  const handleCheckboxSave = () => {
    setIsSaving(!isSaving)
  }

  const handleEdit = () => {
    setIsEditing(!isEditing)
  }

  const handleRegionChange = data => {
    setValue('regionCode', data.value)
  }

  const handleToggleDiscountChange = () => {
    if (discountCodes.length > 0) {
      setWarningDiscountCode(true)
    } else {
      setIsEditingDiscountCode(!isEditingDiscountCode)
    }
  }

  useEffect(() => {
    if (!isUserValidated && useTwoClick > 0) {
      timer = setTimeout(() => {
        isRegisteredEmail(inputValue)
      }, 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [inputValue])

  useEffect(() => {
    const fillInput = (key, value) => {
      if (!value || watch(key) !== '') return
      setValue(key, value)
    }

    // auto completes user data
    fillInput('names', userData.names)
    if (loadingRegistered) {
      fillInput('email', inputValue)
    } else {
      fillInput('email', userData.email)
    }
    if (useSsn) fillInput('rut', userData.rut)
    if (!useSsn && ecommerceInfo.cms === 'vtex') setValue('rut', '11.111.111-1')
    fillInput('phone', userData.phone)
    setValue('saveData', userData.saveData)

    // auto completes billing data
    fillInput('corporateName', billingData.corporateName)
    fillInput('corporateRut', billingData.corporateRut)
    fillInput('category', billingData.category)
    fillInput('corporatePhone', billingData.corporatePhone)
    useBillingData && isShopify && fillInput('address', billingData.address)
    useBillingData && isShopify && fillInput('district', billingData.district)
  }, [setValue, userData, billingData, ecommerceInfo])

  useEffect(() => {
    isBilling && scrollToBottom('div-user-form')
  }, [isBilling])

  useEffect(() => {
    setIsPickup(false)
  }, [setIsPickup])

  useEffect(() => {
    if (steps[0].completed) {
      const context = {
        setPointsData,
        setSteps,
        setSelectedShippingMethod,
        setSelectedServiceType,
        setDeliveryInfo,
        setCartData
      }
      resetDataSelected(context, userData.isRegistered)
    }
  }, [])

  const handleUserFormSubmit = async formData => {
    const {
      names,
      email,
      rut,
      phone,
      saveData,
      category,
      corporateName,
      corporatePhone,
      corporateRut,
      address,
      district,
      regionCode
    } = formData
    const userData = { names: names.trim(), email, rut, phone, saveData }
    const billingData = {
      category,
      corporateName,
      corporatePhone,
      corporateRut,
      address,
      district,
      regionCode
    }
    gaCompletedUserInfoPostMessage()
    setLoadingButton(true)
    setCustomerInfo(prevState => {
      return {
        ...prevState,
        email,
        fullName: names,
        phone,
        ssn: rut
      }
    })
    const validEmail = await validateEmail(email)
    if (!validEmail) {
      const emailError = {
        type: 'validate',
        message: 'Ingrese un dominio que exista'
      }
      setCustomErrors(prevState => {
        return { ...prevState, email: emailError }
      })
      setError('email', emailError)
    } else {
      if (!deletedCheckoutLog) {
        const checkoutData = {
          email,
          lineItems: abreviatedCheckoutLines(cartData.lines)
        }
        createCheckoutLog(checkoutData)
      }
      setUserInfo((prevState) => {
        return { ...prevState, ...userData }
      })
      isBilling && setBillingInfo(billingData)
      const updatedSteps = [...steps]
      updatedSteps[0].completed = true
      updatedSteps[1].disabled = false
      setSteps(updatedSteps)
      setCurrentStep(2)
      if (useTwoClick === 0) setUserInfoLocalStorage(userData)
      saveUserInformationVtex(userData, isBilling ? billingData : false)
      validateDiscountCodesForCustomer(email)
      validateCouponsWoocommerce(email)
      getPaymentMethodsWoocommerce()
    }
    setLoadingButton(false)
  }

  return (
    <>
      <div className={`flex justify-center items-center w-[100vw] h-full fixed z-[10000] bg-blackTransparent top-[0px]  ${isEditing ? '' : 'hidden'}`}>
        <PopUpSendCode/>
      </div>

      <div className={`grow md:h-full md:w-[537px] bg-slate-100 ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-white'} pt-2`}>
        <div className='text-xs text-slate-500 pt-2 pb-2 flex w-[100vw] justify-center md:hidden'>
          Completa tus datos para continuar con la compra.
        </div>
        <form
          onSubmit={handleSubmit(handleUserFormSubmit)}
          className={containerClasses}
          autoComplete='off'
          id='user-info-form'
        >
          <div
            className={`div-user-form ${useBillingData ? 'h-[382px]' : 'h-[352px]'
              } bg-white md:w-full md:rounded-lg p-4 mx-5 md:mt-3 md:p-0 md:px-[21px] md:h-[320px] overflow-auto ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-slate-100'} max-[350px]:h-[300px]`}
          >
            <div className='flex items-center justify-between pl-2 pb-3 md:pb-2 md:my-1 md:mt-3 text-sm font-medium md:text-[11px] md:font-bold'>
              <div className='flex flex-row w-screen md:w-fit justify-between'>
                <SectionTitle
                  title='Datos de identificación'
                  iconName='user'
                  primaryColor={primaryColor}
                  iconCustomStyle={{ fill: 'transparent', stroke: 'white' }}
                />
                <Button
                  role='button'
                  className={`md:hidden bg-slate-100 px-4 py-1 rounded-2xl text-[11px] font-light flex items-center h-[23px] ${userData.isRegistered ? '' : 'hidden'} ${isUserValidated ? 'hidden' : ''}`}
                  label='Editar'
                  LeftIcon={
                    <Icon name='edit' className='mr-1 text-sm' width={9} />
                  }
                  disabled={false}
                  onClick={handleEdit}
                />
              </div>
              <div className={`${useBillingData ? 'md:flex' : ''} hidden`}>
                <Button
                  label={isBilling ? 'Cancelar' : 'Sumar datos de Facturación'}
                  LeftIcon={
                    <Icon name='edit' className='mr-1 text-sm' width={9} />
                  }
                  onClick={() => {
                    setIsBilling(!isBilling)
                  }}
                  className={`bg-slate-100 px-4 py-1 rounded-2xl text-[11px] font-light flex items-center h-[23px] ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-white'}`}
                  testId='billing-data'
                />
              </div>
            </div>
            <div className={'flex flex-wrap justify-between'}>
              {Object.keys(userInfo).map(key => {
                if (key === 'saveData') return false
                if (!useSsn && key === 'rut') return false
                return (
                  <Input
                    key={key}
                    {...userInfo[key]}
                    containerClass={`mb-3 md:mb-1.5 bg-white border md:bg-white ${key === 'phone' && useSsn
                      ? 'md:w-7/12'
                      : key === 'rut'
                        ? 'md:w-2/5'
                        : ''
                      }`}
                    innerRef={userInfo[key].ref}
                    type={
                      key === 'email' ? 'email' : key === 'phone' ? 'tel' : 'text'
                    }
                    errors={errors[key] || watch(key) === ''}
                    customErrors={customErrors[key]}
                    secondaryColor={secondaryColor}
                    testId={key}
                    isLoading={ key === 'email' ? loadingRegistered : false}
                    disabled={(key === 'phone' || key === 'names') ? (userData.isRegistered && !isUserValidated) : userData.isRegistered }
                  />
                )
              })}
            </div>

            <div
              className={`${isBilling
                ? 'justify-between my-2'
                : 'justify-end mt-3 mb-0 md:hidden'
                } flex row items-center`}
            >
              <SectionTitle
                title='Datos de facturación'
                iconName='apartment'
                primaryColor={primaryColor}
                className={`${isBilling ? 'flex' : 'hidden'
                  } items-center pl-2 text-sm font-medium md:text-[11px] md:font-bold`}
              />

              <div className={`${useBillingData ? '' : 'hidden'} md:hidden`}>
                <Button
                  label={isBilling ? 'Cancelar' : 'Sumar datos de Facturación'}
                  LeftIcon={
                    <Icon name='edit' className='mr-1 text-sm' width={9} />
                  }
                  onClick={() => {
                    if (!isEditing && userData.isRegistered && !isUserValidated) {
                      setIsEditing(!isEditing)
                    } else {
                      setIsBilling(!isBilling)
                    }
                  }}
                  className='bg-slate-100 px-4 py-1 rounded-2xl text-[11px] font-light flex items-center h-[23px]'
                  testId='billing-data'
                />
              </div>

            </div>
            <div
              className={`${isBilling ? 'block' : 'hidden'
                } flex flex-wrap justify-between`}
            >
              {Object.keys(billingInfo).map(key => {
                if (key === 'regionCode') {
                  return (
                    <div key={key} className='w-full mb-3 md:mb-1.5 md:w-7/12'>
                      <Select
                        label='Región'
                        options={Object.keys(regionBillingCode).map(regionCode => ({
                          label: regionBillingCode[regionCode],
                          value: regionCode
                        }))}
                        onChange={handleRegionChange}
                        errors={errors?.regionCode}
                        defaultValue={{
                          label: watch('regionCode')
                            ? regionBillingCode[watch('regionCode')]
                            : '',
                          value: watch('regionCode')
                        }}
                        testId="region-select"
                        optionsContainerClass='max-h-[100px] bg-white'
                      />
                    </div>
                  )
                }
                return (
                  <Input
                    key={key}
                    {...billingInfo[key]}
                    containerClass={`mb-3 md:mb-1.5 bg-white border md:bg-white ${key === 'corporateName' || key === 'category'
                      ? 'md:w-7/12'
                      : key === 'corporateRut' || key === 'corporatePhone' || key === 'district'
                        ? 'md:w-2/5'
                        : ''
                      }`}
                    innerRef={billingInfo[key].ref}
                    type={key === 'corporatePhone' ? 'tel' : 'text'}
                    errors={errors[key] || watch(key) === ''}
                    customErrors={customErrors[key]}
                    secondaryColor={secondaryColor}
                    testId={key}
                  />
                )
              })}
            </div>

            <div className={`hidden md:flex md:items-start md:mt-3 md:flex-row  ${isUserValidated || !(useTwoClick > 0) ? 'md:justify-end' : 'md:justify-between'}`}>
              <Button
                role='button'
                className={`bg-slate-100 px-4 py-1 rounded-2xl text-[11px] font-light flex items-center h-[23px] ${userData.isRegistered ? '' : 'hidden'} ${isUserValidated ? 'hidden' : ''}`}
                label='Editar'
                LeftIcon={
                  <Icon name='edit' className='mr-1 text-sm' width={9} />
                }
                disabled={false}
                onClick={handleEdit}
              />
              <div className={`flex flex-row bg-white rounded-md pr-1 pt-2 items-center ${(userData.isRegistered || !(useTwoClick > 0)) ? 'hidden' : ''}`}>
                <div className='flex items-center relative self-center'>
                  <input
                    type='checkbox'
                    name='guardar'
                    onChange={isSaving => handleCheckboxSave(isSaving)}
                    checked={isSaving}
                    onInvalid={(e) => e.target.setCustomValidity('Guardando datos')}
                    onInput={(e) => e.target.setCustomValidity('')}
                    data-testid='term-check'
                    className={`w-5 h-5 border-solid border-[0.5px] cursor-pointer appearance-none rounded-[4px] z-10 sticky ${isSaving ? 'border-[#000000]' : 'border-[#BECED1] '}`}
                  />
                  <div className=' '>
                    <span className={`text-sm md:text-xs absolute top-[0.5px] right-[0.5px] p-1 rounded-[4px] border-[0.5px]  ${isSaving ? 'bg-[#000000] border-[#000000]' : ''}`}>
                      <Icon name='check' className={`fill-[#ffffff] checked:bg-[#000000] ${isSaving ? 'fill-[#ffffff]' : ''}`} width={10} />
                    </span>
                  </div>
                </div>
                <div className='flex flex-row items-center '>
                  <Icon name='flash' className='fill-[#798E95] pt-[3px] ml-2 mr-2' width={10} />
                  <span className='text-xs text-[color:#798E95] self-center'>
                    Guarda mis datos para una compra más rápida
                  </span>
                </div>
              </div>
              <div className={`flex flex-col md:pl-3 w-full items-start ${companyName === 'SELFIE' ? '' : 'hidden'}`}>
                <div className={`flex pt-5 justify-center terms-checkbox ${companyName === 'SELFIE' ? '' : 'hidden'}`}>
                  <div className='text-sm'>
                    &nbsp; ¿Tienes cuenta? Ingresa<a className="no-underline pl-1" target="_blank" rel="noreferrer" href="https://www.selfie.cl/account/login?return_url=%2Faccount"><b>acá</b></a>
                  </div>
                </div>
              </div>
              <div className='flex justify-center pb-2'>
                {(userData.isRegistered)
                  ? <div>
                    <Button
                      role={isUserValidated ? 'submit' : 'button'}
                      className='justify-end bg-black text-white text-base rounded-3xl mt-3 mb-2 pb-1 disabled:bg-slate-100 disabled:text-gray-500 md:h-[37px] md:w-[149px] md:px-[15px] md:my-0.5'
                      label='Continuar'
                      disabled={isUserValidated
                        ? (Object.keys(watch()).some(
                            key =>
                              ((!useSsn && key !== 'rut') || (useSsn)) &&
                                (((watch(key) === '' &&
                                  !Object.keys(billingInfo).includes(key)) || isConsultingRegisteredEmail) ||
                                ((isBilling && watch(key) === '') || isConsultingRegisteredEmail))
                          ))
                        : (false || isConsultingRegisteredEmail)}
                      loading={loadingButton}
                      customStyle={{ backgroundColor: '#000' }}
                      onClick={handleUserForm}
                    />
                  </div>
                  : <div>
                    <Button
                      role='submit'
                      className='bg-black text-white text-base rounded-3xl mt-3 mb-2 pb-1 justify-center disabled:bg-slate-100 disabled:text-gray-500 md:h-[37px] md:w-[149px] md:px-[15px] md:my-0.5'
                      label='Continuar'
                      disabled={Object.keys(watch()).some(
                        key =>
                          ((!useSsn && key !== 'rut') || (useSsn)) &&
                            (((watch(key) === '' &&
                              !Object.keys(billingInfo).includes(key)) || isConsultingRegisteredEmail) ||
                            ((isBilling && watch(key) === '') || isConsultingRegisteredEmail))
                      )}
                      loading={loadingButton}
                      customStyle={{ backgroundColor: secondaryColor }}
                      testId='go-delivery'
                    />
                    </div>
                }
              </div>
            </div>
          </div>
        <div className={`bg-white mt-2 mx-5 rounded ${(useGiftCard || useDiscountCode) ? 'md:hidden' : 'hidden'}`}>
          <div className='flex items-center justify-between px-2 py-3 md:pb-2 md:my-1 md:mt-3 text-sm font-medium md:text-[11px] md:font-bold'>
            <SectionTitle
              title='¿Tienes un cupón?'
              iconName='coupon'
              primaryColor={primaryColor}
              iconCustomStyle={{ fill: 'transparent', stroke: 'white' }}
            />

              <ToggleInput handleChange={handleToggleDiscountChange} isActive={isEditingDiscountCode} />

          </div>
          <div className={`px-4 ${isEditingDiscountCode ? '' : 'hidden'}`}>
            <InputDiscountCode
              className='mt-4 max-h-[7.5rem] overflow-hidden'
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              disabled={currentStep !== 1}
              label={ labelDiscountCodeGiftCard(useGiftCard, useDiscountCode)}
              setIsEditingDiscountCode = {setIsEditingDiscountCode}
              warningDiscountCode = {warningDiscountCode}
              setWarningDiscountCode = {setWarningDiscountCode}
            />

            </div>
          </div>

          <div className='mt-9 w-full bg-white flex flex-col justify-between'>
            <div className='md:hidden'>
              <div className='flex flex-col'>
                <div className={`flex pt-5 justify-center terms-checkbox ${companyName === 'SELFIE' ? '' : 'hidden'}`}>
                  <div className='text-sm'>
                    &nbsp; ¿Tienes cuenta? Ingresa<a className="no-underline pl-1" target="_blank" rel="noreferrer" href="https://www.selfie.cl/account/login?return_url=%2Faccount"><b>acá</b></a>
                  </div>
                </div>
              </div>

              <div className={`flex flex-row bg-white rounded-md pr-1 pt-2 items-center mx-5 mt-5 ${(userData.isRegistered || !(useTwoClick > 0)) ? 'hidden' : ''}`}>
                <div className='flex items-center relative self-center'>
                  <input
                    type='checkbox'
                    name='guardar'
                    onChange={isSaving => handleCheckboxSave(isSaving)}
                    checked={isSaving}
                    onInvalid={(e) => e.target.setCustomValidity('Guardando datos')}
                    onInput={(e) => e.target.setCustomValidity('')}
                    data-testid='term-check'
                    className={`w-5 h-5 border-solid border-[0.5px] cursor-pointer appearance-none rounded-[4px] z-10 sticky ${isSaving ? 'border-[#000000]' : 'border-[#BECED1] '}`}
                  />
                  <div className=' '>
                    <span className={`text-sm md:text-xs absolute top-[0.5px] right-[0.5px] p-1 rounded-[4px] border-[0.5px]  ${isSaving ? 'bg-[#000000] border-[#000000]' : ''}`}>
                      <Icon name='check' className={`fill-[#ffffff] checked:bg-[#000000] ${isSaving ? 'fill-[#ffffff]' : ''}`} width={10} />
                    </span>
                  </div>
                </div>
                <div className='flex flex-row items-center '>
                  <Icon name='flash' className='fill-[#798E95] pt-[3px] ml-2 mr-2' width={10} />
                  <span className='text-xs text-[color:#798E95] self-center'>
                    Guarda mis datos para una compra más rápida
                  </span>
                </div>
              </div>
              <div className='flex justify-center'>
                {(userData.isRegistered)
                  ? <div>
                      <Button
                        role={isUserValidated ? 'submit' : 'button'}
                        className='h-[3rem] bg-black text-white text-base rounded-lg my-4 w-[90vw] justify-center disabled:bg-slate-100 disabled:text-gray-500'
                        label='Continuar'
                        disabled={isUserValidated
                          ? (Object.keys(watch()).some(
                              key =>
                                ((!useSsn && key !== 'rut') || (useSsn)) &&
                                  (((watch(key) === '' &&
                                    !Object.keys(billingInfo).includes(key)) || isConsultingRegisteredEmail) ||
                                  ((isBilling && watch(key) === '') || isConsultingRegisteredEmail))
                            ))
                          : (false || isConsultingRegisteredEmail)}
                        loading={loadingButton}
                        customStyle={{ backgroundColor: '#000' }}
                        onClick={handleUserForm}
                      />
                    </div>
                  : <div>
                      <Button
                        role='submit'
                        className='h-[3rem] bg-black text-white text-base rounded-lg my-4 w-[90vw] justify-center disabled:bg-slate-100 disabled:text-gray-500'
                        label='Continuar'
                        disabled={Object.keys(watch()).some(
                          key =>
                            ((!useSsn && key !== 'rut') || (useSsn)) &&
                              (((watch(key) === '' &&
                                !Object.keys(billingInfo).includes(key)) || isConsultingRegisteredEmail) ||
                              ((isBilling && watch(key) === '') || isConsultingRegisteredEmail))
                        )}
                        loading={loadingButton}
                        customStyle={{ backgroundColor: secondaryColor }}
                        testId='go-delivery'
                      />
                    </div>
                }

              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

UserForm.propTypes = {
  className: PropTypes.string
}

export default UserForm
