import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../context/DataContext'
import { useMagento } from '../hook/useMagento'
import { useShopify } from '../hook/useShopify'
import { useWoocommerce } from '../hook/useWoocommerce'
import { Icon, ProgressBar } from '@pinflag/pinflag-ui-kit'
import { SHOPIFY_PLUS_STORES } from '../constants/stores'

const FinalLoader = () => {
  const { shippingPostMessageMagento } = useMagento()
  const { completeCheckoutLog, removeIframeShopify, redirectPermalink } = useShopify()
  const { shippingPostMessageWoocommerce } = useWoocommerce()
  const { companyInfo, loaderInfo, userInfo, ecommerceInfo, currentStep, isLoadingLoaderInfo } = useContext(DataContext)
  const { companyName, primaryColor, secondaryColor } = companyInfo
  const isLoaderIframe = new URLSearchParams(document.location.search).get('loaderIframe')
  const [progressTime, setProgressTime] = useState(isLoaderIframe ? 66 : 2)
  const [isIframeRemoved, setIsIframeRemoved] = useState(false)
  const { imageUrl, backgroundColor, body, title, titleColor, bodyColor } = loaderInfo[0]

  useEffect(() => {
    if (isIframeRemoved) {
      removeIframeShopify({}, {}, 'Exceeded 30s timeout on final loader')
    }
  }, [isIframeRemoved])

  useEffect(() => {
    if (currentStep === 1) return
    shippingPostMessageWoocommerce()
    shippingPostMessageMagento()
    /* saveShippingInformationVtex() */
    // redirectPermalink()
    // saveShippingInformationShopify()
    if (!SHOPIFY_PLUS_STORES.includes(companyName) && ecommerceInfo.cms === 'shopify') {
      const timeoutLoader = setTimeout(() => {
        setIsIframeRemoved(true)
      }, 30000)
      return () => clearTimeout(timeoutLoader)
    } else {
      completeCheckoutLog(userInfo)
      redirectPermalink()
    }
  }, [])

  const step = 32
  const interval = 3000
  const maxProgress = 98
  const checkoutSteps = { user: 'Cargando tus datos personales', truck: 'Seleccionando envío', 'credit-card': 'Preparando pasarela de pago' }

  useEffect(() => {
    const updateProgress = () => {
      setProgressTime(progressTime + step)
    }
    if (progressTime < maxProgress) {
      setTimeout(updateProgress, interval)
    }
  }, [progressTime])

  const displayDidYouKnow = !SHOPIFY_PLUS_STORES.includes(companyName) &&
  (ecommerceInfo.cms === 'shopify' || ecommerceInfo.cms.includes('vtex') || ecommerceInfo.cms.includes('woocommerce') || isLoaderIframe)

  if (isLoadingLoaderInfo && displayDidYouKnow) return <div />

  return (
    <>
      {displayDidYouKnow
        ? (
        <div
          className='h-[91%] md:h-screen flex flex-col md:flex-row justify-center items-center border-2 md:py-8'
          style={{ backgroundColor }}
        >
          <div className='w-full h-2/5 md:h-full md:mx-4 md:rounded-lg bg-cover bg-no-repeat bg-center' style={{ backgroundImage: `url(${imageUrl})` }}>
          </div>

          <div className='w-full flex flex-col justify-between items-center h-3/5 md:h-full'>
            <div className='flex flex-col justify-evenly items-start h-3/4 md:w-3/4 px-6 md:px-0'>
              <img
                className='h-16'
                src={companyInfo.logoUrl}
                alt='ecommerce-logo'
              />
              <div>
                <p className='text-sm md:text-lg font-dm_sans opacity-80 mt-4' style={{ color: titleColor || primaryColor }}>
                  {title}
                </p>
                <h1 className='font-dm_sans font-bold text-lg md:text-xl text-left' style={{ color: bodyColor || primaryColor }}>
                  {body}
                </h1>
              </div>

            </div>
            <div className='w-full flex flex-col px-6 mb-6 md:px-4 md:mb-0 h-1/4 justify-start md:justify-end'>
              <div className='content'>
                    {Object.keys(checkoutSteps).map((key, index) => (
                      <div key={index} className='rotatingText'>
                        <div className='flex itemb-4 ms-center'>
                          <Icon
                            name={key}
                            width={18}
                            customStyle={{ fill: secondaryColor }}
                          />
                          <p
                            className='text-sm md:text-base font-dm_sans opacity-80 ml-3'
                            style={{ color: secondaryColor }}
                          >
                            {checkoutSteps[key]}
                          </p>
                        </div>
                      </div>
                    ))}
              </div>
              <ProgressBar bgcolor={secondaryColor} progress={progressTime} />
            </div>

          </div>
        </div>
          )
        : (
        <div className='h-screen flex justify-center items-center border-2'>
          <div className='flex flex-col items-center'>
            <div>
              <img
                className='h-12'
                src={companyInfo.logoUrl}
                alt='ecommerce-logo'
              />
            </div>
            <p className='font-bold text-sm text-slate-700 font-sans'>
              Cargando tu información
            </p>
            <div className='relative text-center h-16 w-full'>
              <div className='absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4'>
                <div className='lds-dual-ring'></div>
              </div>
            </div>
            <div className='flex'>
              <p className='text-sm flex font-sans'>
                Powered by{' '}
                <a
                  href='https://pinflag.cl'
                  target='_blank'
                  style={{ color: 'black', textDecoration: 'none' }}
                  rel='noreferrer'
                >
                  <span className='font-bold'>&nbsp;Pinflag</span>
                </a>
              </p>
            </div>
          </div>
        </div>
          )}
    </>
  )
}

export default FinalLoader
