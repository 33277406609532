import { STORES_WITHOUT_PINFLAG_SERVICES, THIRD_PARTY_RATES_DATA } from '../constants/stores'

export const filterServices = (data, shopifyLocations, conditions) => {
  const { companyName } = conditions
  const shipFromStore = data.services.filter(service => service.serviceType === 'ship-from-store')[0]
  if (shipFromStore) {
    const orderedServices = data.services.filter(service => service.serviceType !== 'ship-from-store')
    if (shopifyLocations.locations.includes(shipFromStore.warehouseCmsId) || companyName === 'Ironside') {
      orderedServices.unshift(shipFromStore)
    }
    data.services = orderedServices
  }
  if (companyName === 'Atakama Outdoor') {
    data.services.sort((a, b) => a.price - b.price)
  }
  return data
}

export const joinShippingRates = (thirdPartyRates, pinflagServices, companyName, stateId) => {
  const allServices = (stateId !== 13 && companyName === 'Gotta') || !STORES_WITHOUT_PINFLAG_SERVICES.includes(companyName)
    ? pinflagServices
    : { services: [] }

  thirdPartyRates.forEach(rate => {
    if (pinflagServices.services.every(service => service.name !== rate.title)) {
      const prevDataKey = Object.keys(THIRD_PARTY_RATES_DATA[companyName] || {}).find(rateKey => rate.title.includes(rateKey))

      allServices.services.push({
        courier: '',
        packageDeliveryTime: prevDataKey ? THIRD_PARTY_RATES_DATA[companyName][prevDataKey].packageDeliveryTime : '',
        serviceType: rate.handle,
        price: Number(rate.price.amount),
        name: rate.title,
        description: prevDataKey ? THIRD_PARTY_RATES_DATA[companyName][prevDataKey].description : ''
      })
    }
  })
  return allServices
}
