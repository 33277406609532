export const santiagoShippify = {
  13: {
    stateId: 13,
    name: 'Metropolitana de Santiago',
    cities: [
      {
        name: 'Santiago',
        id: 13101
      },
      {
        name: 'Cerrillos',
        id: 13102
      },
      {
        name: 'Cerro Navia',
        id: 13103
      },
      {
        name: 'Conchalí',
        id: 13104
      },
      {
        name: 'El Bosque',
        id: 13105
      },
      {
        name: 'Estación Central',
        id: 13106
      },
      {
        name: 'Huechuraba',
        id: 13107
      },
      {
        name: 'Independencia',
        id: 13108
      },
      {
        name: 'La Cisterna',
        id: 13109
      },
      {
        name: 'La Florida',
        id: 13110
      },
      {
        name: 'La Granja',
        id: 13111
      },
      {
        name: 'La Pintana',
        id: 13112
      },
      {
        name: 'La Reina',
        id: 13113
      },
      {
        name: 'Las Condes',
        id: 13114
      },
      {
        name: 'Lo Barnechea',
        id: 13115
      },
      {
        name: 'Lo Espejo',
        id: 13116
      },
      {
        name: 'Lo Prado',
        id: 13117
      },
      {
        name: 'Macul',
        id: 13118
      },
      {
        name: 'Maipú',
        id: 13119
      },
      {
        name: 'Ñuñoa',
        id: 13120
      },
      {
        name: 'Pedro Aguirre Cerda',
        id: 13121
      },
      {
        name: 'Peñalolén',
        id: 13122
      },
      {
        name: 'Providencia',
        id: 13123
      },
      {
        name: 'Pudahuel',
        id: 13124
      },
      {
        name: 'Quilicura',
        id: 13125
      },
      {
        name: 'Quinta Normal',
        id: 13126
      },
      {
        name: 'Recoleta',
        id: 13127
      },
      {
        name: 'Renca',
        id: 13128
      },
      {
        name: 'San Joaquín',
        id: 13129
      },
      {
        name: 'San Miguel',
        id: 13130
      },
      {
        name: 'San Ramón',
        id: 13131
      },
      {
        name: 'Vitacura',
        id: 13132
      },
      {
        name: 'Puente Alto',
        id: 13201
      },
      {
        name: 'San Bernardo',
        id: 13401
      }
    ]
  }
}

export const shippifyDistricts = ['Alhue', 'Buin', 'Calera de Tango', 'Colina', 'Curacavi', 'El Monte', 'Isla de Maipo', 'La Pintana', 'Lampa', 'Maria Pinto', 'Melipilla', 'Padre Hurtado', 'Paine', 'Peñaflor', 'Pirque', 'San Jose de Maipo', 'San Pedro', 'Talagante', 'Tiltil']

export const backupRates = {
  1: 7390,
  2: 7190,
  3: 5590,
  4: 4990,
  5: 4390,
  6: 4490,
  7: 4690,
  8: 4990,
  9: 5190,
  10: 5390,
  11: 8290,
  12: 8490,
  13: 2990,
  14: 5290,
  15: 7590,
  16: 4890
}

export const postalCodesVTEX = {
  alhue: '9650000',
  buin: '9500000',
  'calera de tango': '9560000',
  'Centro de Ski - El Colorado': '7690002',
  'Centro de Ski - Farellones': '7690003',
  'Centro de Ski - La Parva': '7690004',
  'Centro de Ski - Valle Nevado': '7690001',
  cerrillos: '9200000',
  'cerro navia': '9080000',
  chicureo: '9350001',
  colina: '9340000',
  conchali: '8540000',
  curacavi: '9630000',
  'el bosque': '8010000',
  'el monte': '9810000',
  'estacion central': '9160000',
  huechuraba: '8580000',
  independencia: '8380000',
  'isla de maipo': '9790000',
  'la cisterna': '7970000',
  'la florida': '8240000',
  'la granja': '8780000',
  'la pintana': '8820000',
  'la reina': '7850000',
  lampa: '9380000',
  'las condes': '7550000',
  'lo barnechea': '7690000',
  'lo barnechea (Excepto Cordillera)': '7690100',
  'lo espejo': '9120000',
  'lo prado': '8980000',
  macul: '7810000',
  maipu: '9250000',
  'maria pinto': '9620000',
  melipilla: '9580000',
  nunoa: '7750000',
  'padre hurtado': '9710000',
  paine: '9540000',
  'pedro aguirre cerda': '8460000',
  penaflor: '9750000',
  penalolen: '7910000',
  pirque: '9480000',
  providencia: '7500000',
  pudahuel: '9020000',
  'puente alto': '8150000',
  quilicura: '8700000',
  'quinta normal': '8500000',
  recoleta: '8420000',
  renca: '8640000',
  'san bernardo': '8050000',
  'san joaquin': '8940000',
  'san Jose de maipo': '9460000',
  'san miguel': '8900000',
  'san pedro': '9660000',
  'san ramon': '8860000',
  santiago: '8320000',
  talagante: '9670000',
  tiltil: '9420000',
  vitacura: '7630000',
  arica: '1000000',
  camarones: '1040000',
  'general lagos': '1080000',
  putre: '1070000',
  camina: '1150000',
  colchane: '1160000',
  huara: '1140000',
  iquique: '1100000',
  pica: '1170000',
  'pozo almonte': '1180000',
  'alto hospicio': '1130000',
  antofagasta: '1240000',
  calama: '1390000',
  'maria elena': '1360000',
  mejillones: '1310000',
  ollague: '1420000',
  'san pedro de atacama': '1410000',
  'sierra gorda': '1320000',
  taltal: '1300000',
  tocopilla: '1340000',
  'alto del carmen': '1650000',
  caldera: '1570000',
  chanaral: '1490000',
  copiapo: '1530000',
  'diego de almagro': '1500000',
  freirina: '1630000',
  huasco: '1640000',
  'tierra amarilla': '1580000',
  vallenar: '1610000',
  andacollo: '1750000',
  canela: '1960000',
  'caleta hornos': '1780008',
  combarbala: '1890000',
  coquimbo: '1780000',
  guanaqueros: '1780007',
  illapel: '1930000',
  'la higuera': '1740000',
  'la serena': '1700000',
  'las tacas': '1780002',
  'los vilos': '1940000',
  'monte patria': '1880000',
  morrillos: '1780004',
  ovalle: '1840000',
  paihuano: '1770000',
  'playa blanca': '1780003',
  'puerto velero': '1780001',
  punitaqui: '1900000',
  'rio hurtado': '1870000',
  salamanca: '1950000',
  tongoy: '1780006',
  totoralillo: '1780005',
  vicuna: '1760000',
  algarrobo: '2710000',
  cabildo: '2050000',
  'calle larga': '2130000',
  cartagena: '2680000',
  casablanca: '2480000',
  catemu: '2230000',
  concon: '2510000',
  'el quisco': '2700000',
  'el tabo': '2690000',
  hijuelas: '2310000',
  'isla de pascua': '2770000',
  'juan fernandez': '2600000',
  'la calera': '2290000',
  'la cruz': '2280000',
  'la ligua': '2030000',
  limache: '2240000',
  'llay-llay': '2220000',
  'los andes': '2100000',
  nogales: '2300000',
  olmue: '2330000',
  panquehue: '2210000',
  papudo: '2070000',
  petorca: '2040000',
  puchuncavi: '2500000',
  putaendo: '2190000',
  quillota: '2260000',
  quilpue: '2420000',
  quintero: '2490000',
  rinconada: '2140000',
  'san antonio': '2660000',
  'san esteban': '2120000',
  'san felipe': '2170000',
  'santa maria': '2200000',
  'santo domingo': '2720000',
  valparaiso: '2340000',
  'villa alemana': '2450000',
  'vina del mar': '2520000',
  zapallar: '2060000',
  chepica: '3120000',
  chimbarongo: '3090000',
  codegua: '2900000',
  coinco: '3010000',
  coltauco: '3000000',
  donihue: '3020000',
  graneros: '2880000',
  'la estrella': '3250000',
  'lago rapel': '3030001',
  'las cabras': '3030000',
  litueche: '3240000',
  lolol: '3140000',
  machali: '2910000',
  malloa: '2950000',
  marchigue: '3260000',
  mostazal: '2890000',
  nancagua: '3110000',
  navidad: '3230000',
  olivar: '2920000',
  palmilla: '3160000',
  paredones: '3270000',
  peralillo: '3170000',
  peumo: '2990000',
  pichidegua: '2980000',
  pichilemu: '3220000',
  placilla: '3100000',
  pumanque: '3150000',
  'quinta de tilcoco': '2960000',
  rancagua: '2820000',
  rengo: '2940000',
  requinoa: '2930000',
  'san fernando': '3070000',
  'san vicente': '2970000',
  'santa cruz': '3130000',
  cauquenes: '3690000',
  chanco: '3720000',
  colbun: '3610000',
  constitucion: '3560000',
  curepto: '3570000',
  curico: '3340000',
  empedrado: '3540000',
  hualane: '3400000',
  licanten: '3410000',
  linares: '3580000',
  longavi: '3620000',
  maule: '3530000',
  molina: '3380000',
  parral: '3630000',
  pelarco: '3500000',
  pelluhue: '3710000',
  pencahue: '3550000',
  rauco: '3430000',
  retiro: '3640000',
  'rio claro': '3510000',
  romeral: '3370000',
  'sagrada familia': '3390000',
  'san clemente': '3520000',
  'san javier': '3660000',
  'san rafael': '3490000',
  talca: '3460000',
  teno: '3360000',
  vichuquen: '3420000',
  'villa alegre': '3650000',
  'yerbas buenas': '3600000',
  bulnes: '3930000',
  chillan: '3780000',
  'chillan viejo': '3820000',
  cobquecura: '3990000',
  coelemu: '3970000',
  coihueco: '3870000',
  'el carmen': '3900000',
  ninhue: '4010000',
  niquen: '3850000',
  pemuco: '3910000',
  pinto: '3880000',
  portezuelo: '3960000',
  quillon: '3940000',
  quirihue: '4000000',
  ranquil: '3950000',
  'san carlos': '3840000',
  'san fabian': '3860000',
  'san ignacio': '3890000',
  'san Nicolas': '4020000',
  treguaco: '3980000',
  yungay: '3920000',
  'alto bio bio': '4590000',
  antuco: '4490000',
  arauco: '4360000',
  cabrero: '4470000',
  canete: '4390000',
  chiguayante: '4100000',
  concepcion: '4030000',
  contulmo: '4400000',
  coronel: '4190000',
  curanilahue: '4370000',
  dichato: '4160001',
  florida: '4170000',
  hualpen: '4600000',
  hualqui: '4180000',
  laja: '4560000',
  lebu: '4350000',
  lirquen: '4140001',
  'los alamos': '4380000',
  'los angeles': '4430000',
  lota: '4210000',
  mulchen: '4530000',
  nacimiento: '4550000',
  negrete: '4540000',
  penco: '4150000',
  pingueral: '4160002',
  quilaco: '4520000',
  quilleco: '4500000',
  recinto: '3880001',
  'san pedro de la paz': '4130000',
  'san rosendo': '4570000',
  'santa barbara': '4510000',
  'santa juana': '4230000',
  talcahuano: '4260000',
  tirua: '4410000',
  tome: '4160000',
  tucapel: '4480000',
  yumbel: '4580000',
  angol: '4650000',
  caburga: '4920001',
  calafquen: '4930001',
  carahue: '5010000',
  cholchol: '5040000',
  collipulli: '4680000',
  corralco: '4780002',
  cunco: '4890000',
  curacautin: '4700000',
  curarrehue: '4910000',
  ercilla: '4710000',
  freire: '4940000',
  galvarino: '5030000',
  gorbea: '4960000',
  'lago colico': '4930002',
  'lago colico norte': '4890001',
  'lago colico sur': '4890002',
  lautaro: '4860000',
  licanray: '4930001',
  loncoche: '4970000',
  lonquimay: '4690000',
  'los sauces': '4760000',
  lumaco: '4740000',
  malalcahuello: '4780001',
  melipeuco: '4900000',
  'nueva imperial': '5020000',
  'padre las casas': '4850000',
  perquenco: '4870000',
  pitrufquen: '4950000',
  pucon: '4920000',
  'puerto saavedra': '5000000',
  puren: '4750000',
  renaico: '4670000',
  temuco: '4780000',
  'teodoro schmidt': '4990000',
  tolten: '4980000',
  traiguen: '4730000',
  victoria: '4720000',
  vilcun: '4880000',
  villarrica: '4930000',
  conaripe: '5210001',
  corral: '5190000',
  futrono: '5180000',
  'la union': '5220000',
  'lago ranco': '5250000',
  lanco: '5160000',
  'los lagos': '5170000',
  mafil: '5200000',
  mariquina: '5150000',
  paillaco: '5230000',
  panguipulli: '5210000',
  'rio bueno': '5240000',
  valdivia: '5090000',
  ancud: '5710000',
  calbuco: '5570000',
  castro: '5700000',
  chaiten: '5850000',
  chonchi: '5770000',
  cochamo: '5560000',
  'curaco de velez': '5740000',
  dalcahue: '5730000',
  'el islote': '5370001',
  ensenada: '5550001',
  fresia: '5600000',
  frutillar: '5620000',
  futaleufu: '5870000',
  hualaihue: '5860000',
  llanquihue: '5610000',
  'los muermos': '5590000',
  'marina rupanco': '5360001',
  maullin: '5580000',
  osorno: '5290000',
  palena: '5880000',
  'puerto montt': '5480000',
  'puerto octay': '5370000',
  'puerto varas': '5550000',
  puqueldon: '5760000',
  purranque: '5380000',
  puyehue: '5360000',
  queilen: '5780000',
  quellon: '5790000',
  quemchi: '5720000',
  quinchao: '5750000',
  'rio negro': '5390000',
  'san juan de la costa': '5400000',
  'san pablo': '5350000',
  aysen: '6000000',
  'chile chico': '6050000',
  cisnes: '6010000',
  cochrane: '6100000',
  coyhaique: '5950000',
  guaitecas: '6020000',
  'lago verde': '5960000',
  "o'higgins": '6110000',
  'rio ibanez': '6060000',
  tortel: '6120000',
  antartica: '6360000',
  'cabo De Hornos': '6350000',
  'laguna blanca': '6250000',
  porvenir: '6300000',
  primavera: '6310000',
  'puerto natales': '6160000',
  'punta arenas': '6200000',
  'rio verde': '6240000',
  'san gregorio': '6260000',
  timaukel: '6320000',
  'torres del paine': '6170000',
  'region metropolitana de santiago': '8320000',
  'region de tarapaca': '1100000',
  'region de antofagasta': '1240000',
  'region de atacama': '1530000',
  'region de coquimbo': '1700000',
  'region de valparaiso': '2340000',
  "region de o'higgins": '2820000',
  'region del maule': '3460000',
  'region del biobio': '4030000',
  'region de la araucania': '4780000',
  'region de los lagos': '5480000',
  'region de los rios': '5090000',
  'region de aysen del general carlos ibanez del campo': '5950000',
  'region de magallanes y de la antartica chilena': '6200000',
  'region de arica y parinacota': '1000000',
  'region de nuble': '3780000'
}

export const comunasVtex = {
  RM: [
    'Buin',
    'Alhué',
    'Calera De Tango',
    'Cerrillos',
    'Cerro Navia',
    'Colina',
    'Conchalí',
    'Curacaví',
    'El Bosque',
    'El Monte',
    'Estación Central',
    'Huechuraba',
    'Independencia',
    'Isla De Maipo',
    'La Cisterna',
    'La Florida',
    'La Granja',
    'La Pintana',
    'La Reina',
    'Lampa',
    'Las Condes',
    'Lo Barnechea',
    'Lo Espejo',
    'Lo Prado',
    'Macul',
    'Maipú',
    'María Pinto',
    'Melipilla',
    'Ñuñoa',
    'Padre Hurtado',
    'Paine',
    'Pedro Aguirre Cerda',
    'Peñaflor',
    'Peñalolén',
    'Pirque',
    'Providencia',
    'Pudahuel',
    'Puente Alto',
    'Quilicura',
    'Quinta Normal',
    'Recoleta',
    'Renca',
    'San Bernardo',
    'San Joaquín',
    'San José De Maipo',
    'San Miguel',
    'San Pedro',
    'San Ramón',
    'Santiago',
    'Talagante',
    'Tiltil',
    'Vitacura'
  ],
  AP: [
    'Arica',
    'Camarones',
    'General Lagos',
    'Putre'
  ],
  TA: [
    'Camiña',
    'Colchane',
    'Huara',
    'Iquique',
    'Pica',
    'Pozo Almonte',
    'Alto Hospicio'
  ],
  AN: [
    'Antofagasta',
    'Calama',
    'María Elena',
    'Mejillones',
    'Ollagüe',
    'San Pedro De Atacama',
    'Sierra Gorda',
    'Taltal',
    'Tocopilla'
  ],
  AT: [
    'Alto Del Carmen',
    'Caldera',
    'Chañaral',
    'Copiapó',
    'Diego De Almagro',
    'Freirina',
    'Huasco',
    'Tierra Amarilla',
    'Vallenar'
  ],
  CO: [
    'Andacollo',
    'Canela',
    'Combarbalá',
    'Coquimbo',
    'Illapel',
    'La Higuera',
    'La Serena',
    'Los Vilos',
    'Monte Patria',
    'Ovalle',
    'Paihuano',
    'Punitaqui',
    'Río Hurtado',
    'Salamanca',
    'Vicuña'
  ],
  VS: [
    'Algarrobo',
    'Cabildo',
    'Calle Larga',
    'Cartagena',
    'Casablanca',
    'Catemu',
    'Concón',
    'El Quisco',
    'El Tabo',
    'Hijuelas',
    'Isla De Pascua',
    'Juan Fernández',
    'La Calera',
    'La Cruz',
    'La Ligua',
    'Limache',
    'Llay-Llay',
    'Los Andes',
    'Nogales',
    'Olmué',
    'Panquehue',
    'Papudo',
    'Petorca',
    'Puchuncaví',
    'Putaendo',
    'Quillota',
    'Quilpué',
    'Quintero',
    'Rinconada',
    'San Antonio',
    'San Esteban',
    'San Felipe',
    'Santa María',
    'Santo Domingo',
    'Valparaíso',
    'Villa Alemana',
    'Viña Del Mar',
    'Zapallar'
  ],
  LI: [
    'Chépica',
    'Chimbarongo',
    'Codegua',
    'Coinco',
    'Coltauco',
    'Doñihue',
    'Graneros',
    'La Estrella',
    'Las Cabras',
    'Litueche',
    'Lolol',
    'Machalí',
    'Malloa',
    'Marchigüe',
    'Mostazal',
    'Nancagua',
    'Navidad',
    'Olivar',
    'Palmilla',
    'Paredones',
    'Peralillo',
    'Peumo',
    'Pichidegua',
    'Pichilemu',
    'Placilla',
    'Pumanque',
    'Quinta De Tilcoco',
    'Rancagua',
    'Rengo',
    'Requinoa',
    'San Fernando',
    'San Vicente',
    'Santa Cruz'
  ],
  ML: [
    'Cauquenes',
    'Chanco',
    'Colbún',
    'Constitución',
    'Curepto',
    'Curicó',
    'Empedrado',
    'Hualañé',
    'Licantén',
    'Linares',
    'Longaví',
    'Maule',
    'Molina',
    'Parral',
    'Pelarco',
    'Pelluhue',
    'Pencahue',
    'Rauco',
    'Retiro',
    'Río Claro',
    'Romeral',
    'Sagrada Familia',
    'San Clemente',
    'San Javier',
    'San Rafael',
    'Talca',
    'Teno',
    'Vichuquén',
    'Villa Alegre',
    'Yerbas Buenas'
  ],
  NB: [
    'Bulnes',
    'Chillán',
    'Chillán Viejo',
    'Cobquecura',
    'Coelemu',
    'Coihueco',
    'El Carmen',
    'Ninhue',
    'Ñiquén',
    'Pemuco',
    'Pinto',
    'Portezuelo',
    'Quillón',
    'Quirihue',
    'Ranquil',
    'San Carlos',
    'San Fabián',
    'San Ignacio',
    'San Nicolás',
    'Treguaco',
    'Yungay'
  ],
  BI: [
    'Alto Bío Bío',
    'Antuco',
    'Arauco',
    'Cabrero',
    'Cañete',
    'Chiguayante',
    'Concepción',
    'Contulmo',
    'Coronel',
    'Curanilahue',
    'Florida',
    'Hualpén',
    'Hualqui',
    'Laja',
    'Lebu',
    'Los Alamos',
    'Los Ángeles',
    'Lota',
    'Mulchén',
    'Nacimiento',
    'Negrete',
    'Penco',
    'Quilaco',
    'Quilleco',
    'San Pedro De la Paz',
    'San Rosendo',
    'Santa Bárbara',
    'Santa Juana',
    'Talcahuano',
    'Tirúa',
    'Tomé',
    'Tucapel',
    'Yumbel'
  ],
  AR: [
    'Angol',
    'Carahue',
    'Cholchol',
    'Collipulli',
    'Curacautín',
    'Curarrehue',
    'Ercilla',
    'Freire',
    'Gorbea',
    'Lautaro',
    'Loncoche',
    'Lonquimay',
    'Los Sauces',
    'Lumaco',
    'Melipeuco',
    'Nueva Imperial',
    'Padre Las Casas',
    'Perquenco',
    'Pitrufquén',
    'Pucón',
    'Puerto Saavedra',
    'Purén',
    'Renaico',
    'Temuco',
    'Teodoro Schmidt',
    'Toltén',
    'Traiguén',
    'Victoria',
    'Vilcún',
    'Villarrica'
  ],
  LR: [
    'Corral',
    'Futrono',
    'La Union',
    'Lago Ranco',
    'Lanco',
    'Los Lagos',
    'Mafil',
    'Mariquina',
    'Paillaco',
    'Panguipulli',
    'Rio Bueno',
    'Valdivia'
  ],
  LL: [
    'Ancud',
    'Calbuco',
    'Castro',
    'Chaitén',
    'Chonchi',
    'Cochamó',
    'Curaco De Vélez',
    'Dalcahue',
    'Fresia',
    'Frutillar',
    'Futaleufú',
    'Hualaihué',
    'Llanquihue',
    'Los Muermos',
    'Maullín',
    'Osorno',
    'Palena',
    'Puerto Montt',
    'Puerto Octay',
    'Puerto Varas',
    'Puqueldón',
    'Purranque',
    'Puyehue',
    'Queilén',
    'Quellón',
    'Quemchi',
    'Quinchao',
    'Río Negro',
    'San Juan De la Costa',
    'San Pablo'
  ],
  AI: [
    'Aysén',
    'Chile Chico',
    'Cisnes',
    'Cochrane',
    'Coyhaique',
    'Guaitecas',
    'Lago Verde',
    "O'Higgins",
    'Río Ibáñez',
    'Tortel'
  ],
  MA: [
    'Antártica',
    'Cabo De Hornos',
    'Laguna Blanca',
    'Porvenir',
    'Primavera',
    'Puerto Natales',
    'Punta Arenas',
    'Río Verde',
    'San Gregorio',
    'Timaukel',
    'Torres Del Paine'
  ]
}

export const backupStates = {
  1: {
    stateId: 1,
    name: 'Tarapacá',
    cities: [
      {
        name: 'Alto Hospicio',
        id: 1107
      },
      {
        name: 'Camiña',
        id: 1402
      },
      {
        name: 'Colchane',
        id: 1403
      },
      {
        name: 'Huara',
        id: 1404
      },
      {
        name: 'Iquique',
        id: 1101
      },
      {
        name: 'Pica',
        id: 1405
      },
      {
        name: 'Pozo Almonte',
        id: 1401
      }
    ]
  },
  2: {
    stateId: 2,
    name: 'Antofagasta',
    cities: [
      {
        name: 'Antofagasta',
        id: 2101
      },
      {
        name: 'Calama',
        id: 2201
      },
      {
        name: 'María Elena',
        id: 2302
      },
      {
        name: 'Mejillones',
        id: 2102
      },
      {
        name: 'Ollague',
        id: 2202
      },
      {
        name: 'San Pedro De Atacama',
        id: 2203
      },
      {
        name: 'Sierra Gorda',
        id: 2103
      },
      {
        name: 'Taltal',
        id: 2104
      },
      {
        name: 'Tocopilla',
        id: 2301
      }
    ]
  },
  3: {
    stateId: 3,
    name: 'Atacama',
    cities: [
      {
        name: 'Alto Del Carmen',
        id: 3302
      },
      {
        name: 'Caldera',
        id: 3102
      },
      {
        name: 'Chañaral',
        id: 3201
      },
      {
        name: 'Copiapó',
        id: 3101
      },
      {
        name: 'Diego De Almagro',
        id: 3202
      },
      {
        name: 'Freirina',
        id: 3303
      },
      {
        name: 'Huasco',
        id: 3304
      },
      {
        name: 'Tierra Amarilla',
        id: 3103
      },
      {
        name: 'Vallenar',
        id: 3301
      }
    ]
  },
  4: {
    stateId: 4,
    name: 'Coquimbo',
    cities: [
      {
        name: 'Andacollo',
        id: 4103
      },
      {
        name: 'Canela',
        id: 4202
      },
      {
        name: 'Combarbalá',
        id: 4302
      },
      {
        name: 'Coquimbo',
        id: 4102
      },
      {
        name: 'Illapel',
        id: 4201
      },
      {
        name: 'La Higuera',
        id: 4104
      },
      {
        name: 'La Serena',
        id: 4101
      },
      {
        name: 'Los Vilos',
        id: 4203
      },
      {
        name: 'Monte Patria',
        id: 4303
      },
      {
        name: 'Ovalle',
        id: 4301
      },
      {
        name: 'Paiguano',
        id: 4105
      },
      {
        name: 'Punitaqui',
        id: 4304
      },
      {
        name: 'Río Hurtado',
        id: 4305
      },
      {
        name: 'Salamanca',
        id: 4204
      },
      {
        name: 'Vicuña',
        id: 4106
      }
    ]
  },
  5: {
    stateId: 5,
    name: 'Valparaíso',
    cities: [
      {
        name: 'Algarrobo',
        id: 5602
      },
      {
        name: 'Cabildo',
        id: 5402
      },
      {
        name: 'Calera',
        id: 5502
      },
      {
        name: 'Calle Larga',
        id: 5302
      },
      {
        name: 'Cartagena',
        id: 5603
      },
      {
        name: 'Casablanca',
        id: 5102
      },
      {
        name: 'Catemu',
        id: 5702
      },
      {
        name: 'Concón',
        id: 5103
      },
      {
        name: 'El Quisco',
        id: 5604
      },
      {
        name: 'El Tabo',
        id: 5605
      },
      {
        name: 'Hijuelas',
        id: 5503
      },
      {
        name: 'Isla De Pascua',
        id: 5201
      },
      {
        name: 'Juan Fernández',
        id: 5104
      },
      {
        name: 'La Cruz',
        id: 5504
      },
      {
        name: 'La Ligua',
        id: 5401
      },
      {
        name: 'Limache',
        id: 5505
      },
      {
        name: 'Llaillay',
        id: 5703
      },
      {
        name: 'Los Andes',
        id: 5301
      },
      {
        name: 'Nogales',
        id: 5506
      },
      {
        name: 'Olmué',
        id: 5507
      },
      {
        name: 'Panquehue',
        id: 5704
      },
      {
        name: 'Papudo',
        id: 5403
      },
      {
        name: 'Petorca',
        id: 5404
      },
      {
        name: 'Puchuncaví',
        id: 5105
      },
      {
        name: 'Putaendo',
        id: 5705
      },
      {
        name: 'Quillota',
        id: 5501
      },
      {
        name: 'Quilpué',
        id: 5106
      },
      {
        name: 'Quintero',
        id: 5107
      },
      {
        name: 'Rinconada',
        id: 5303
      },
      {
        name: 'San Antonio',
        id: 5601
      },
      {
        name: 'San Esteban',
        id: 5304
      },
      {
        name: 'San Felipe',
        id: 5701
      },
      {
        name: 'Santa María',
        id: 5706
      },
      {
        name: 'Santo Domingo',
        id: 5606
      },
      {
        name: 'Valparaíso',
        id: 5101
      },
      {
        name: 'Villa Alemana',
        id: 5108
      },
      {
        name: 'Viña Del Mar',
        id: 5109
      },
      {
        name: 'Zapallar',
        id: 5405
      }
    ]
  },
  6: {
    stateId: 6,
    name: "Región de O'Higgins",
    cities: [
      {
        name: 'Chépica',
        id: 6302
      },
      {
        name: 'Chimbarongo',
        id: 6303
      },
      {
        name: 'Codegua',
        id: 6102
      },
      {
        name: 'Coinco',
        id: 6103
      },
      {
        name: 'Coltauco',
        id: 6104
      },
      {
        name: 'Doñihue',
        id: 6105
      },
      {
        name: 'Graneros',
        id: 6106
      },
      {
        name: 'La Estrella',
        id: 6202
      },
      {
        name: 'Las Cabras',
        id: 6107
      },
      {
        name: 'Litueche',
        id: 6203
      },
      {
        name: 'Lolol',
        id: 6304
      },
      {
        name: 'Machali',
        id: 6108
      },
      {
        name: 'Malloa',
        id: 6109
      },
      {
        name: 'Marchihue',
        id: 6204
      },
      {
        name: 'Mostazal',
        id: 6110
      },
      {
        name: 'Nancagua',
        id: 6305
      },
      {
        name: 'Navidad',
        id: 6205
      },
      {
        name: 'Olivar',
        id: 6111
      },
      {
        name: 'Palmilla',
        id: 6306
      },
      {
        name: 'Paredones',
        id: 6206
      },
      {
        name: 'Peralillo',
        id: 6307
      },
      {
        name: 'Peumo',
        id: 6112
      },
      {
        name: 'Pichidegua',
        id: 6113
      },
      {
        name: 'Pichilemu',
        id: 6201
      },
      {
        name: 'Placilla',
        id: 6308
      },
      {
        name: 'Pumanque',
        id: 6309
      },
      {
        name: 'Quinta De Tilcoco',
        id: 6114
      },
      {
        name: 'Rancagua',
        id: 6101
      },
      {
        name: 'Rengo',
        id: 6115
      },
      {
        name: 'Requinoa',
        id: 6116
      },
      {
        name: 'San Fernando',
        id: 6301
      },
      {
        name: 'Santa Cruz',
        id: 6310
      },
      {
        name: 'San Vicente',
        id: 6117
      }
    ]
  },
  7: {
    stateId: 7,
    name: 'Maule',
    cities: [
      {
        name: 'Cauquenes',
        id: 7201
      },
      {
        name: 'Chanco',
        id: 7202
      },
      {
        name: 'Colbún',
        id: 7402
      },
      {
        name: 'Constitución',
        id: 7102
      },
      {
        name: 'Curepto',
        id: 7103
      },
      {
        name: 'Curicó',
        id: 7301
      },
      {
        name: 'Empedrado',
        id: 7104
      },
      {
        name: 'Hualañe',
        id: 7302
      },
      {
        name: 'Licantén',
        id: 7303
      },
      {
        name: 'Linares',
        id: 7401
      },
      {
        name: 'Longaví',
        id: 7403
      },
      {
        name: 'Maule',
        id: 7105
      },
      {
        name: 'Molina Maule',
        id: 7304
      },
      {
        name: 'Parral',
        id: 7404
      },
      {
        name: 'Pelarco',
        id: 7106
      },
      {
        name: 'Pelluhue',
        id: 7203
      },
      {
        name: 'Pencahue',
        id: 7107
      },
      {
        name: 'Rauco',
        id: 7305
      },
      {
        name: 'Retiro',
        id: 7405
      },
      {
        name: 'Río Claro',
        id: 7108
      },
      {
        name: 'Romeral',
        id: 7306
      },
      {
        name: 'Sagrada Familia',
        id: 7307
      },
      {
        name: 'San Clemente',
        id: 7109
      },
      {
        name: 'San Javier',
        id: 7406
      },
      {
        name: 'San Rafael',
        id: 7110
      },
      {
        name: 'Talca',
        id: 7101
      },
      {
        name: 'Teno',
        id: 7308
      },
      {
        name: 'Vichuquén',
        id: 7309
      },
      {
        name: 'Villa Alegre',
        id: 7407
      },
      {
        name: 'Yerbas Buenas',
        id: 7408
      }
    ]
  },
  8: {
    stateId: 8,
    name: 'Región del Bío Bío',
    cities: [
      {
        name: 'Alto Biobío',
        id: 8314
      },
      {
        name: 'Antuco',
        id: 8302
      },
      {
        name: 'Arauco',
        id: 8202
      },
      {
        name: 'Cabrero',
        id: 8303
      },
      {
        name: 'Cañete',
        id: 8203
      },
      {
        name: 'Chiguayante',
        id: 8103
      },
      {
        name: 'Concepción',
        id: 8101
      },
      {
        name: 'Contulmo',
        id: 8204
      },
      {
        name: 'Coronel',
        id: 8102
      },
      {
        name: 'Curanilahue',
        id: 8205
      },
      {
        name: 'Florida',
        id: 8104
      },
      {
        name: 'Hualpén',
        id: 8112
      },
      {
        name: 'Hualqui',
        id: 8105
      },
      {
        name: 'Laja',
        id: 8304
      },
      {
        name: 'Lebu',
        id: 8201
      },
      {
        name: 'Los Alamos',
        id: 8206
      },
      {
        name: 'Los Angeles',
        id: 8301
      },
      {
        name: 'Lota',
        id: 8106
      },
      {
        name: 'Mulchén',
        id: 8305
      },
      {
        name: 'Nacimiento',
        id: 8306
      },
      {
        name: 'Negrete',
        id: 8307
      },
      {
        name: 'Penco',
        id: 8107
      },
      {
        name: 'Quilaco',
        id: 8308
      },
      {
        name: 'Quilleco',
        id: 8309
      },
      {
        name: 'San Pedro de la Paz',
        id: 8108
      },
      {
        name: 'San Rosendo',
        id: 8310
      },
      {
        name: 'Santa Bárbara',
        id: 8311
      },
      {
        name: 'Santa Juana',
        id: 8109
      },
      {
        name: 'Talcahuano',
        id: 8110
      },
      {
        name: 'Tomé',
        id: 8111
      },
      {
        name: 'Tucapel',
        id: 8312
      },
      {
        name: 'Yumbel',
        id: 8313
      }
    ]
  },
  9: {
    stateId: 9,
    name: 'La Araucanía',
    cities: [
      {
        name: 'Angol',
        id: 9201
      },
      {
        name: 'Carahue',
        id: 9102
      },
      {
        name: 'Chol Chol',
        id: 9121
      },
      {
        name: 'Collipulli',
        id: 9202
      },
      {
        name: 'Cunco',
        id: 9103
      },
      {
        name: 'Curacautín',
        id: 9203
      },
      {
        name: 'Curarrehue',
        id: 9104
      },
      {
        name: 'Ercilla',
        id: 9204
      },
      {
        name: 'Freire',
        id: 9105
      },
      {
        name: 'Galvarino',
        id: 9106
      },
      {
        name: 'Gorbea',
        id: 9107
      },
      {
        name: 'Lautaro',
        id: 9108
      },
      {
        name: 'Loncoche',
        id: 9109
      },
      {
        name: 'Lonquimay',
        id: 9205
      },
      {
        name: 'Los Sauces',
        id: 9206
      },
      {
        name: 'Lumaco',
        id: 9207
      },
      {
        name: 'Melipeuco',
        id: 9110
      },
      {
        name: 'Nueva Imperial',
        id: 9111
      },
      {
        name: 'Padre Las Casas',
        id: 9112
      },
      {
        name: 'Perquenco',
        id: 9113
      },
      {
        name: 'Pitrufquén',
        id: 9114
      },
      {
        name: 'Pucón',
        id: 9115
      },
      {
        name: 'Puren',
        id: 9208
      },
      {
        name: 'Renaico',
        id: 9209
      },
      {
        name: 'Saavedra',
        id: 9116
      },
      {
        name: 'Temuco',
        id: 9101
      },
      {
        name: 'Teodoro Schmidt',
        id: 9117
      },
      {
        name: 'Toltén',
        id: 9118
      },
      {
        name: 'Traiguén',
        id: 9210
      },
      {
        name: 'Victoria',
        id: 9211
      },
      {
        name: 'Vilcún',
        id: 9119
      },
      {
        name: 'Villarrica',
        id: 9120
      }
    ]
  },
  10: {
    stateId: 10,
    name: 'Los Lagos',
    cities: [
      {
        name: 'Ancud',
        id: 10202
      },
      {
        name: 'Calbuco',
        id: 10102
      },
      {
        name: 'Castro',
        id: 10201
      },
      {
        name: 'Chaitén',
        id: 10401
      },
      {
        name: 'Chonchi',
        id: 10203
      },
      {
        name: 'Cochamó',
        id: 10103
      },
      {
        name: 'Curaco de Velez',
        id: 10204
      },
      {
        name: 'Dalcahue',
        id: 10205
      },
      {
        name: 'Fresia',
        id: 10104
      },
      {
        name: 'Frutillar',
        id: 10105
      },
      {
        name: 'Futaleufú',
        id: 10402
      },
      {
        name: 'Hualaihue',
        id: 10403
      },
      {
        name: 'Llanquihue',
        id: 10107
      },
      {
        name: 'Los Muermos',
        id: 10106
      },
      {
        name: 'Maullín',
        id: 10108
      },
      {
        name: 'Osorno',
        id: 10301
      },
      {
        name: 'Palena',
        id: 10404
      },
      {
        name: 'Puerto Montt',
        id: 10101
      },
      {
        name: 'Puerto Octay',
        id: 10302
      },
      {
        name: 'Puerto Varas',
        id: 10109
      },
      {
        name: 'Puqueldón',
        id: 10206
      },
      {
        name: 'Purranque',
        id: 10303
      },
      {
        name: 'Puyehue',
        id: 10304
      },
      {
        name: 'Queilén',
        id: 10207
      },
      {
        name: 'Quellón',
        id: 10208
      },
      {
        name: 'Quemchi',
        id: 10209
      },
      {
        name: 'Quinchao',
        id: 10210
      },
      {
        name: 'Río Negro',
        id: 10305
      },
      {
        name: 'San Juan de la Costa',
        id: 10306
      },
      {
        name: 'San Pablo',
        id: 10307
      }
    ]
  },
  11: {
    stateId: 11,
    name: 'Aysén del General Carlos Ibáñez del Campo',
    cities: [
      {
        name: 'Aysen',
        id: 11201
      },
      {
        name: 'Chile Chico',
        id: 11401
      },
      {
        name: 'Cisnes',
        id: 11202
      },
      {
        name: 'Cochrane',
        id: 11301
      },
      {
        name: 'Coyhaique',
        id: 11101
      },
      {
        name: 'Guaitecas',
        id: 11203
      },
      {
        name: 'Lago Verde',
        id: 11102
      },
      {
        name: 'OHiggins',
        id: 11302
      },
      {
        name: 'Río Ibáñez',
        id: 11402
      },
      {
        name: 'Tortel',
        id: 11303
      }
    ]
  },
  12: {
    stateId: 12,
    name: 'Magallanes y de la Antártica Chilena',
    cities: [
      {
        name: 'Antártica',
        id: 12202
      },
      {
        name: 'Cabo de Hornos',
        id: 12201
      },
      {
        name: 'Laguna Blanca',
        id: 12102
      },
      {
        name: 'Natales',
        id: 12401
      },
      {
        name: 'Porvenir',
        id: 12301
      },
      {
        name: 'Primavera',
        id: 12302
      },
      {
        name: 'Punta Arenas',
        id: 12101
      },
      {
        name: 'Río Verde',
        id: 12103
      },
      {
        name: 'San Gregorio',
        id: 12104
      },
      {
        name: 'Timaukel',
        id: 12303
      },
      {
        name: 'Torres del Paine',
        id: 12402
      }
    ]
  },
  13: {
    stateId: 13,
    name: 'Metropolitana de Santiago',
    cities: [
      {
        name: 'Alhué',
        id: 13502
      },
      {
        name: 'Buin',
        id: 13402
      },
      {
        name: 'Calera De Tango',
        id: 13403
      },
      {
        name: 'Cerrillos',
        id: 13102
      },
      {
        name: 'Cerro Navia',
        id: 13103
      },
      {
        name: 'Colina',
        id: 13301
      },
      {
        name: 'Conchalí',
        id: 13104
      },
      {
        name: 'Curacaví',
        id: 13503
      },
      {
        name: 'El Bosque',
        id: 13105
      },
      {
        name: 'El Monte',
        id: 13602
      },
      {
        name: 'Estación Central',
        id: 13106
      },
      {
        name: 'Huechuraba',
        id: 13107
      },
      {
        name: 'Independencia',
        id: 13108
      },
      {
        name: 'Isla De Maipo',
        id: 13603
      },
      {
        name: 'La Cisterna',
        id: 13109
      },
      {
        name: 'La Florida',
        id: 13110
      },
      {
        name: 'La Granja',
        id: 13111
      },
      {
        name: 'Lampa',
        id: 13302
      },
      {
        name: 'La Pintana',
        id: 13112
      },
      {
        name: 'La Reina',
        id: 13113
      },
      {
        name: 'Las Condes',
        id: 13114
      },
      {
        name: 'Lo Barnechea',
        id: 13115
      },
      {
        name: 'Lo Espejo',
        id: 13116
      },
      {
        name: 'Lo Prado',
        id: 13117
      },
      {
        name: 'Macul',
        id: 13118
      },
      {
        name: 'Maipú',
        id: 13119
      },
      {
        name: 'María Pinto',
        id: 13504
      },
      {
        name: 'Melipilla',
        id: 13501
      },
      {
        name: 'Ñuñoa',
        id: 13120
      },
      {
        name: 'Padre Hurtado',
        id: 13604
      },
      {
        name: 'Paine',
        id: 13404
      },
      {
        name: 'Pedro Aguirre Cerda',
        id: 13121
      },
      {
        name: 'Peñaflor',
        id: 13605
      },
      {
        name: 'Peñalolén',
        id: 13122
      },
      {
        name: 'Pirque',
        id: 13202
      },
      {
        name: 'Providencia',
        id: 13123
      },
      {
        name: 'Pudahuel',
        id: 13124
      },
      {
        name: 'Puente Alto',
        id: 13201
      },
      {
        name: 'Quilicura',
        id: 13125
      },
      {
        name: 'Quinta Normal',
        id: 13126
      },
      {
        name: 'Recoleta',
        id: 13127
      },
      {
        name: 'Renca',
        id: 13128
      },
      {
        name: 'San Bernardo',
        id: 13401
      },
      {
        name: 'San Joaquín',
        id: 13129
      },
      {
        name: 'San José De Maipo',
        id: 13203
      },
      {
        name: 'San Miguel',
        id: 13130
      },
      {
        name: 'San Pedro',
        id: 13505
      },
      {
        name: 'San Ramón',
        id: 13131
      },
      {
        name: 'Santiago',
        id: 13101
      },
      {
        name: 'Talagante',
        id: 13601
      },
      {
        name: 'Tiltil',
        id: 13303
      },
      {
        name: 'Vitacura',
        id: 13132
      }
    ]
  },
  14: {
    stateId: 14,
    name: 'Los Ríos',
    cities: [
      {
        name: 'Corral',
        id: 14102
      },
      {
        name: 'Futrono',
        id: 14202
      },
      {
        name: 'Lago Ranco',
        id: 14203
      },
      {
        name: 'Lanco',
        id: 14103
      },
      {
        name: 'La Unión',
        id: 14201
      },
      {
        name: 'Los Lagos',
        id: 14104
      },
      {
        name: 'Máfil',
        id: 14105
      },
      {
        name: 'Mariquina',
        id: 14106
      },
      {
        name: 'Paillaco',
        id: 14107
      },
      {
        name: 'Panguipulli',
        id: 14108
      },
      {
        name: 'Río Bueno',
        id: 14204
      },
      {
        name: 'Valdivia',
        id: 14101
      }
    ]
  },
  15: {
    stateId: 15,
    name: 'Arica y Parinacota',
    cities: [
      {
        name: 'Arica',
        id: 15101
      },
      {
        name: 'Camarones',
        id: 15102
      },
      {
        name: 'General Lagos',
        id: 15202
      },
      {
        name: 'Putre',
        id: 15201
      }
    ]
  },
  16: {
    stateId: 16,
    name: 'Región de Ñuble',
    cities: [
      {
        name: 'Bulnes',
        id: 16102
      },
      {
        name: 'Chillan',
        id: 16101
      },
      {
        name: 'Chillán Viejo',
        id: 16103
      },
      {
        name: 'Cobquecura',
        id: 16202
      },
      {
        name: 'Coelemu',
        id: 16203
      },
      {
        name: 'Coihueco',
        id: 16302
      },
      {
        name: 'El Carmen',
        id: 16104
      },
      {
        name: 'Ninhue',
        id: 16204
      },
      {
        name: 'Ñiquén',
        id: 16303
      },
      {
        name: 'Pemuco',
        id: 16105
      },
      {
        name: 'Pinto',
        id: 16106
      },
      {
        name: 'Portezuelo',
        id: 16205
      },
      {
        name: 'Quillón',
        id: 16107
      },
      {
        name: 'Quirihue',
        id: 16201
      },
      {
        name: 'Ranquil',
        id: 16206
      },
      {
        name: 'San Carlos',
        id: 16301
      },
      {
        name: 'San Fabián',
        id: 16304
      },
      {
        name: 'San Ignacio',
        id: 16108
      },
      {
        name: 'San Nicolás',
        id: 16305
      },
      {
        name: 'Trehuaco',
        id: 16207
      },
      {
        name: 'Yungay',
        id: 16109
      }
    ]
  }
}
