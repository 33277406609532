import { useContext } from 'react'

import { DataContext } from '../context/DataContext'
import { normalizeRegionName, regionCode } from '../helpers/addressParser'
import { getPaymentMethodsLocalStorage, savePaymentMethodsLocalStorage } from '../helpers/localStorage'

import { useCmsAdapter } from './useCmsAdapter'
import { HOME_DELIVERY } from '../constants/deliveryTypes'

export const useWoocommerce = () => {
  const {
    userInfo,
    addressInfo,
    setEcommerceInfo,
    ecommerceInfo,
    setCartData,
    setAddressInfo,
    setSteps,
    steps,
    setIsPaying,
    setCurrentStep,
    setSelectedShippingMethod,
    billingInfo,
    setBillingInfo,
    setUserInfo,
    setSelectedServiceType,
    setPaymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setDiscountInfo,
    discountInfo,
    companyInfo,
    deliveryInfo
  } = useContext(DataContext)
  const { getPaymentMethods, validateDiscountForCustomerWoocomerce } = useCmsAdapter()
  const { discountCodes } = discountInfo

  const setInitialInformationWoocommerce = (message, setInitialInfoReady) => {
    const { cms, currentUrl, cart, cartHash } = message
    if (!cms.includes('woocommerce')) return
    let discount
    setEcommerceInfo(prevState => {
      return { ...prevState, cms, currentUrl }
    })
    let totalPrice = parseInt(cart.totals.total_items) + parseInt(cart.totals.total_items_tax)
    const subtotal = totalPrice
    const totalCount = cart.items_count
    const totalWeight = cart.items_weight / 1000
    const cartProducts = cart.items.map(product => {
      return {
        id: product.key,
        productImage: product.images[0].src,
        productName: product.name,
        quantity: product.quantity,
        lineTotal: product.prices.price,
        weight: cart.items_weight / 1000,
        requires_shipping: cart.needs_shipping
      }
    })

    if (cart.coupons.length > 0) {
      discount = cart.coupons.reduce((acc, discount) => acc + (parseInt(discount.totals.total_discount) + parseInt(discount.totals.total_discount_tax)), 0)
      const filteredDiscounts = cart.coupons.map((coupon) => ({ code: coupon.code, type: 'coupon' }))
      setDiscountInfo(prevState => {
        return {
          ...prevState,
          discountCodes: filteredDiscounts
        }
      })

      totalPrice -= discount
    }

    const checkFreeShipping = cart.shipping_rates.map((shippingRate) => {
      const isFree = shippingRate.shipping_rates.some(shipping => shipping.method_id.includes('free_shipping'))
      return isFree
    })

    const isFreeShipping = checkFreeShipping.some(option => option)

    setCartData(prevState => {
      return {
        ...prevState,
        total: Math.trunc(totalPrice),
        subtotal: Math.trunc(subtotal),
        lines: cartProducts,
        itemsCount: totalCount,
        totalWeight: totalWeight ? Math.ceil(totalWeight) : 1,
        cartId: cartHash,
        discount: discount || 0,
        isFreeShipping,
        shippingCost: null
      }
    })
    setInitialInfoReady(true)
  }

  const iframeLoadedWoocommerce = () => {
    setTimeout(() => {
      window.parent.postMessage(
        {
          type: 'iframe-mounted',
          origin: 'pinflag-checkout-iframe',
          message: true
        },
        '*'
      )
    }, 1500)
  }

  const redirectToHomeWoocommerce = () => {
    window.parent.postMessage(
      {
        type: 'redirect-to-home',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const wooCommerceClosePinmap = () => {
    window.parent.postMessage(
      {
        type: 'close-pinmap',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const hideWoocommerceSummary = () => {
    window.parent.postMessage(
      {
        type: 'edit-button',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const continueCheckout = (message) => {
    if (message.cms !== 'woocommerce') return
    setAddressInfo(prevState => {
      return {
        ...prevState,
        userAddress: message.formData.address,
        aditionalInfo: message.formData.aditionalInfo.split('|')[0],
        district: message.formData.city,
        deliveryType: message.formData.deliveryType,
        price: message.formData.postcode,
        serviceType: message.formData.serviceType,
        region: message.formData.region,
        mapCoords: message.formData.mapCoords,
        sla: message.formData.sla
      }
    })
    setUserInfo(prevState => {
      return {
        ...prevState,
        email: message.formData.email,
        names: message.formData.firstname + ' ' + message.formData.lastname,
        phone: message.formData.phone,
        rut: message.formData.aditionalInfo.split('|')[1]
      }
    })
    setSelectedServiceType((prevState) => {
      return {
        ...prevState,
        packageDeliveryTime: message.formData.sla
      }
    })
    setBillingInfo((prevState) => {
      return {
        ...prevState,
        corporateName: message.formData.corporateName ? message.formData.corporateName : '',
        category: message.formData.corporateCategory ? message.formData.corporateCategory : '',
        corporateRut: message.formData.corporateRut ? message.formData.corporateRut : '',
        corporatePhone: message.formData.corporatePhone ? message.formData.corporatePhone : '',
        address: message.formData.corporateAddress ? message.formData.corporateAddress : '',
        district: message.formData.corporateDistrict ? message.formData.corporateDistrict : ''
      }
    })
    setSelectedShippingMethod(prevState => {
      return {
        ...prevState,
        selectedShippingMethod: message.formData.selectedShippingMethod
      }
    })
    setSelectedPaymentMethod(message.formData.paymentMethod ? message.formData.paymentMethod : '')
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = true
    updatedSteps[1].subSteps[1].completed = true
    updatedSteps[1].subSteps[2].completed = true
    setCurrentStep(3)
    setSteps(updatedSteps)
    if (message.formData.paymentMethod) {
      setIsPaying(true)
    }
  }

  const shippingPostMessageWoocommerce = () => {
    if (!ecommerceInfo.cms.includes('woocommerce')) return
    const firstname = userInfo.names.substring(0, userInfo.names.indexOf(' '))
    const lastname = userInfo.names.substring(userInfo.names.indexOf(' ') + 1)
    window.parent.postMessage(
      {
        type: 'set-form-data',
        origin: 'pinflag-checkout-iframe',
        message: {
          firstname,
          lastname,
          address: addressInfo.userAddress,
          aditionalInfo: `${addressInfo.aditionalInfo}|${userInfo.rut}`,
          phone: userInfo.phone,
          email: userInfo.email,
          city: addressInfo.district,
          regionCode: `CL-${regionCode[normalizeRegionName(addressInfo.region)]}`,
          postcode: addressInfo.deliveryType === HOME_DELIVERY && deliveryInfo?.services
            ? deliveryInfo?.services.findIndex(service => service.serviceType === addressInfo.serviceType)
            : 0,
          deliveryType: addressInfo.deliveryType,
          manualDelivery: addressInfo.manualDelivery,
          serviceType: `${addressInfo.courier && addressInfo.courier}${addressInfo.serviceType ? ` - ${addressInfo.serviceType}` : ''}`,
          region: addressInfo.region,
          mapCoords: addressInfo.mapCoords,
          corporateName: billingInfo.corporateName ? billingInfo.corporateName : '',
          corporateCategory: billingInfo.category ? billingInfo.category : '',
          corporateRut: billingInfo.corporateRut ? billingInfo.corporateRut : '',
          corporatePhone: billingInfo.corporatePhone ? billingInfo.corporatePhone : '',
          corporateAddress: billingInfo.address ? billingInfo.address : '',
          corporateDistrict: billingInfo.district ? billingInfo.district : '',
          sla: addressInfo.sla,
          paymentMethod: selectedPaymentMethod
        }
      },
      '*'
    )
  }

  const fetchPaymentMethods = async () => {
    const { pinflagApiKey } = companyInfo
    try {
      const response = await getPaymentMethods(ecommerceInfo.cms)
      const { paymentMethods } = response.data
      const availablePaymentMethods = paymentMethods.map(method => ({ title: method.title, code: method.title }))
      savePaymentMethodsLocalStorage(availablePaymentMethods)

      setPaymentMethods(availablePaymentMethods, pinflagApiKey)

      return paymentMethods
    } catch (err) {
      console.log(err)
    }
  }

  const getPaymentMethodsWoocommerce = async () => {
    if (!ecommerceInfo.cms.includes('woocommerce')) return
    const { pinflagApiKey } = companyInfo

    const localStorageInfo = getPaymentMethodsLocalStorage(pinflagApiKey)

    if (localStorageInfo) {
      setPaymentMethods(localStorageInfo)
      return localStorageInfo
    }

    const paymentMethods = await fetchPaymentMethods()

    return paymentMethods
  }

  const notifyDiscountCodeWoocommerce = (code) => {
    if (!ecommerceInfo.cms.includes('woocommerce')) return

    if (!companyInfo.useDiscountCode) {
      return { valid: false, error: 'Código inválido' }
    }

    window.parent.postMessage(
      {
        type: 'send-discount-code',
        origin: 'pinflag-checkout-iframe',
        message: { code }
      },
      '*'
    )
  }

  const handleDiscountCodeWoocommerce = (message) => {
    if (!message.cms.includes('woocommerce')) return
    if (message.discountData.message) {
      setDiscountInfo(prevState => {
        return {
          ...prevState,
          discountError: { message: 'Código inválido' }
        }
      })
    } else {
      const newDiscountCodes = message.discountData.coupons.map((coupon) => ({ code: coupon.code, type: 'coupon' }))
      setDiscountInfo(prevState => {
        return {
          ...prevState,
          discountError: undefined,
          discountCodes: newDiscountCodes
        }
      })
      const checkFreeShipping = message.discountData.shipping_rates.map((shippingRate) => {
        const isFree = shippingRate.shipping_rates.some(shipping => shipping.method_id === 'free_shipping')
        return isFree
      })
      const isFreeShipping = checkFreeShipping.some(option => option)
      setCartData(prevState => {
        return {
          ...prevState,
          discount: message.discountData.totals.total_discount !== '0'
            ? (parseInt(message.discountData.totals.total_discount) + parseInt(message.discountData.totals.total_discount_tax))
            : null,
          shippingCost: null,
          isFreeShipping
        }
      })
    }
  }

  const deleteDiscountCodeWoocommerce = (code) => {
    if (!ecommerceInfo.cms.includes('woocommerce')) return
    window.parent.postMessage(
      {
        type: 'remove-discount-code',
        origin: 'pinflag-checkout-iframe',
        message: { code }
      },
      '*'
    )
  }

  const validateCouponsWoocommerce = async (email) => {
    if (!ecommerceInfo.cms.includes('woocommerce')) return

    const validatedCodes = await validateDiscountForCustomerWoocomerce(email, discountCodes)

    validatedCodes.data.forEach(coupon => {
      if (!coupon.valid) {
        deleteDiscountCodeWoocommerce(coupon.code)
        const filteredCodes = discountCodes.filter(discount => discount.code !== coupon.code)
        setDiscountInfo(prevState => {
          return {
            ...prevState,
            discoutCodes: filteredCodes,
            discountNotification: 'Los códigos de descuentos han sido modificados'
          }
        })

        setTimeout(() => setDiscountInfo(prevState => {
          return {
            ...prevState,
            discountNotification: ''
          }
        }), 5000)
      }
    })
  }

  return {
    setInitialInformationWoocommerce,
    iframeLoadedWoocommerce,
    shippingPostMessageWoocommerce,
    redirectToHomeWoocommerce,
    hideWoocommerceSummary,
    continueCheckout,
    wooCommerceClosePinmap,
    getPaymentMethodsWoocommerce,
    notifyDiscountCodeWoocommerce,
    handleDiscountCodeWoocommerce,
    deleteDiscountCodeWoocommerce,
    validateCouponsWoocommerce
  }
}
