export const resetDataSelected = (context, isRegistered) => {
  const {
    setPointsData,
    setSteps,
    setSelectedShippingMethod,
    setSelectedServiceType,
    setDeliveryInfo,
    setCartData
  } = context

  setPointsData((prevState) => ({
    ...prevState,
    localPoints: [],
    pinflagPoints: []
  }))
  setSteps([
    {
      label: 'Identificación',
      stepId: 1,
      completed: true,
      disabled: false,
      subSteps: []
    },
    {
      label: 'Entrega',
      stepId: 2,
      completed: false,
      disabled: !isRegistered,
      subSteps: [
        {
          label: 'Method',
          subStepId: 2.1,
          completed: false
        },
        {
          label: 'Address',
          subStepId: 2.2,
          completed: false
        },
        {
          label: 'Confirmation',
          subStepId: 2.3,
          completed: false
        }
      ]
    },
    {
      label: 'Pago',
      stepId: 3,
      completed: false,
      disabled: true,
      subSteps: []
    }
  ])
  setSelectedShippingMethod(null)
  setSelectedServiceType({})
  setDeliveryInfo({})
  setCartData(prevState => ({
    ...prevState,
    shippingCost: null
  }))
}
