import { Button, Icon, SectionTitle } from '@pinflag/pinflag-ui-kit'
import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../context/DataContext'
import { useWoocommerce } from '../hook/useWoocommerce'
import { HOME_DELIVERY, PICKUP_POINT, LOCAL_PICKUP } from '../constants/deliveryTypes'

const ReturnPickupPoint = () => {
  const {
    selectedShippingMethod,
    setSelectedShippingMethod,
    steps,
    setSteps,
    setCurrentStep,
    companyInfo,
    setIsPickup,
    setDeliveryInfo,
    setPointsData,
    ecommerceInfo,
    setIsSummaryOpen,
    userInfo
  } = useContext(DataContext)

  const { hideWoocommerceSummary } = useWoocommerce()

  const deliveryMethod = [
    {
      type: 'local-pickup',
      name: LOCAL_PICKUP
    },
    {
      type: 'click-and-collect',
      name: PICKUP_POINT
    },
    {
      type: 'home-delivery',
      name: HOME_DELIVERY
    },
    {
      type: 'unnecessary',
      name: 'No aplica'
    }
  ]

  const [primaryColor, setPrimaryColor] = useState(companyInfo.primaryColor)
  const [iconColor, setIconColor] = useState('white')

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768
      setPrimaryColor(isMobile ? '#F2F4F4' : companyInfo.primaryColor)
      setIconColor(isMobile ? '#1F2937' : 'white')
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleBack = () => {
    hideWoocommerceSummary()
    setIsPickup(false)
    setSelectedShippingMethod(null)
    setPointsData((prevState) => {
      prevState.localPointSelected = {}
      prevState.pinflagPointSelected = {}
      return prevState
    })
    if (userInfo.isRegistered) {
      setIsSummaryOpen(true)
    }
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = false
    updatedSteps[1].subSteps[1].completed = false
    updatedSteps[1].subSteps[2].completed = false
    setSteps(updatedSteps)
    setCurrentStep(2)
    setDeliveryInfo({})
  }

  return (
    <div className={`flex w-full md:px-5 px-3 ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-slate-100 md:rounded'}`}>
      <div className='flex flex-row w-full py-2 rounded items-center justify-between'>
        <SectionTitle
          className=''
          title='Modo entrega:'
          iconName='truck'
          iconCustomStyle={{ fill: 'transparent', stroke: iconColor }}
          primaryColor={primaryColor}
        />
        <div className='flex sm:px-5 text-gray-500 text-xs md:min-w-[190px]'>
          {deliveryMethod.map((method, index) => {
            return (
              method.type === selectedShippingMethod && (
                <p key={index}>{method.name}</p>
              )
            )
          })}
        </div>
        <Button
          label={<span className='hidden md:block'>Cambiar</span>}
          LeftIcon={<Icon name='edit' className='fill-gray-800 mr-2' width={9} />}
          onClick={handleBack}
          className={`flex items-center bg-slate-100 ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-white'} pr-2 md:px-4 py-1 rounded-2xl text-[11px] font-light h-[23px] w-auto`}
          testId='change-delivery'
        />
      </div>
    </div>
  )
}

export default ReturnPickupPoint
