export const getUserLocation = (setUserDefaultLocation) => {
  if (navigator.geolocation) {
    navigator.permissions && navigator.permissions.query({ name: 'geolocation' })
      .then((result) => {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition(setUserDefaultLocation)
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(setUserDefaultLocation)
        } else if (result.state === 'denied') {
          // If denied then you have to show instructions to enable location
        }
      })
  } else {
    console.log('Geolocation is not supported by this browser.')
  }
}
