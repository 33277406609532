import { BILLING_TYPE_STORES, DIFFERENT_REGION_NAME, ONLY_CHECKOUT } from '../constants/stores'
import { BILLING_KEY_TRANSLATION, BILLING_KEY_TRANSLATION_STORES } from '../constants/translation'
import { camelToSnake, getPinflagPostalCode, getPostalCode, rutWithoutDot } from './utils'
import { comunasVtex } from '../constants/states'

export const addressParser = (address) => {
  const addressParser = address.split(', ')
  const region = addressParser[2]
  const regionWithoutPostcode = region.slice(0, addressParser[2].length - 8)
  addressParser[2] = regionWithoutPostcode
  return addressParser
}

export const addressParserUnnormalized = (address) => {
  const addressParserUnnormalized = address.split(',')
  if (addressParserUnnormalized.length === 2) {
    return [addressParserUnnormalized[0], addressParserUnnormalized[1], '']
  } else {
    return addressParserUnnormalized.slice(0, 3)
  }
}

export const normalizeRegionName = (region) => {
  const normalizedRegionName = region
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace("'", '')
    .replace('Región del ', '')
    .replace('Región de ', '')
    .replace('Region de ', '')
    .replace('Region del ', '')
    .replace('Region ', '')
    .replace(/[" "][0-9]+/g, '')
    .replace('Biobio', 'Bio Bio')

  return normalizedRegionName
}

export const regionIdApi = {
  Tarapaca: '1',
  Antofagasta: '2',
  Atacama: '3',
  Coquimbo: '4',
  Valparaiso: '5',
  OHiggins: '6',
  Maule: '7',
  'Bio Bio': '8',
  Biobio: '8',
  'La Araucania': '9',
  'Los Lagos': '10',
  'Aysen del General Carlos Ibanez del Campo': '11',
  'Magallanes y de la Antartica Chilena': '12',
  'Metropolitana de Santiago': '13',
  'Los Rios': '14',
  'Arica y Parinacota': '15',
  Nuble: '16'
}

export const getRegionById = regionId => {
  return Object.keys(regionIdApi).filter(
    region => regionIdApi[region] === regionId
  )[0]
}

export const regionCode = {
  Tarapaca: 'TA',
  Antofagasta: 'AN',
  Atacama: 'AT',
  Coquimbo: 'CO',
  Valparaiso: 'VS',
  'Libertador General Bernardo OHiggins': 'LI',
  OHiggins: 'LI',
  Maule: 'ML',
  'Bio Bio': 'BI',
  Biobio: 'BI',
  'La Araucania': 'AR',
  'Los Lagos': 'LL',
  'Aysen del General Carlos Ibanez del Campo': 'AI',
  'Magallanes y de la Antartica Chilena': 'MA',
  'Metropolitana de Santiago': 'RM',
  'Los Rios': 'LR',
  'Arica y Parinacota': 'AP',
  Nuble: 'NB'
}

export const regionBillingCode = {
  TA: 'Tarapacá',
  AN: 'Antofagasta',
  AT: 'Atacama',
  CO: 'Coquimbo',
  VS: 'Valparaíso',
  LI: "Región de O'Higgins",
  ML: 'Maule',
  BI: 'Región del Bío Bío',
  AR: 'La Araucanía',
  LL: 'Los Lagos',
  AI: 'Aysén del General Carlos Ibáñez del Campo',
  MA: 'Magallanes y de la Antártica Chilena',
  RM: 'Metropolitana de Santiago',
  LR: 'Los Ríos',
  AP: 'Arica y Parinacota',
  NB: 'Región de Ñuble'
}

export const regionId = {
  AN: 655,
  AR: 656,
  AP: 668,
  AT: 657,
  AI: 654,
  BI: 658,
  CO: 659,
  LI: 660,
  LL: 661,
  LR: 667,
  MA: 662,
  ML: 663,
  RM: 664,
  TA: 665,
  VS: 666,
  NB: 754
}

const addKeyToObject = (object, key, value) => {
  if (!key) return

  object[key] = value
}

export const shippingAddressObject = (userInfo, addressInfo, storeConfig) => {
  const { names, rut, phone } = userInfo
  const {
    userAddress,
    district,
    aditionalInfo,
    region,
    serviceName
  } = addressInfo

  const firstName = names.substring(0, names.indexOf(' '))
  const lastName = names.substring(names.indexOf(' ') + 1)

  const shippingAddress = {
    address1: '',
    address2: '',
    city: district,
    country: 'Chile',
    company: '',
    firstName,
    lastName,
    phone,
    province: region ? regionCode[normalizeRegionName(region)] : '',
    zip: '',
    shippingRate: serviceName
  }
  addKeyToObject(shippingAddress, storeConfig.streetNameField, userAddress)
  addKeyToObject(shippingAddress, storeConfig.cityField, district)
  addKeyToObject(shippingAddress, storeConfig.deliveryInstructionsField, aditionalInfo)
  addKeyToObject(shippingAddress, storeConfig.ssnField, rut)

  return shippingAddress
}

export const shippingAddressParser = (shippingAddress, type) => {
  const addressParsed = Object.keys(shippingAddress)
    .filter(
      key => shippingAddress[key] !== ''
    )
    .map(
      key =>
        `checkout[${type}][${camelToSnake(key)}]=${shippingAddress[key]
        }`
    )
    .join('&')

  return addressParsed !== '' ? `&${addressParsed}` : ''
}

export const billingUrlParser = (billingInfo, companyName) => {
  const url = Object.keys(billingInfo)
    .filter(
      key => billingInfo[key] !== ''
    )
    .map(
      key =>
        `attributes[${BILLING_KEY_TRANSLATION_STORES[companyName]
          ? BILLING_KEY_TRANSLATION_STORES[companyName][key]
          : BILLING_KEY_TRANSLATION[key]
        }]=${companyName === 'Atakama Outdoor' && key === 'corporateRut'
          ? rutWithoutDot(billingInfo[key])
          : billingInfo[key]
        }`
    )
    .join('&')
  const prevUrl = BILLING_TYPE_STORES[companyName] || ''
  return prevUrl + url
}

export const billingInputParser = billingInfo => {
  const customAttributes = []
  Object.keys(billingInfo).forEach((key) => {
    customAttributes.push({
      key: BILLING_KEY_TRANSLATION[key],
      value: billingInfo[key]
    })
  })
  return { customAttributes }
}

export const changedRegionName = (oldName) => {
  const newNames = {
    TA: 'Región de Tarapacá (I)',
    AN: 'Región de Antofagasta (II)',
    AT: 'Región de Atacama (III)',
    CO: 'Región de Coquimbo (IV)',
    VS: 'Región de Valparaíso (V)',
    LI: 'Región del Libertador General Bernardo O’Higgins (VI)',
    ML: 'Región del Maule (VII)',
    BI: 'Región del Biobío (VIII)',
    AR: 'Región de La Araucanía (IX)',
    LL: 'Región de Los Lagos (X)',
    AI: 'Región de Aysén del General Carlos Ibáñez del Campo (XI)',
    MA: 'Región de Magallanes y la Antártica Chilena (XII)',
    RM: 'Región Metropolitana',
    LR: 'Región de Los Ríos (XIV)',
    AP: 'Región de Arica y Parinacota (XV)',
    NB: 'Región del Ñuble (XVI)'
  }
  const code = regionCode[normalizeRegionName(oldName)]
  return newNames[code]
}

export const defaultDocumentParser = (rut, companyName) => {
  if (companyName === 'Atakama Outdoor') return `&attributes[billing_documento]=39&attributes[billing_rut]=${rutWithoutDot(rut)}`
  if (companyName === 'Vielco') {
    return '&' + billingUrlParser({
      corporateName: ' ',
      category: ' ',
      corporateRut: rut,
      corporatePhone: ' ',
      address: ' ',
      district: ' ',
      regionCode: ' '
    }, companyName)
  }
  return ''
}

// código para hacer similaridad entre comunas para colgram
const editDistance = (s1, s2) => {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  const costs = []
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j
      else {
        if (j > 0) {
          let newValue = costs[j - 1]
          if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          }
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue
  }
  return costs[s2.length]
}

const similarity = (s1, s2) => {
  let longer = s1
  let shorter = s2
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  const longerLength = longer.length
  if (longerLength === 0) {
    return 1.0
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
}

export const setCitySelect = (region, mapCityName) => {
  const cityOptions = comunasVtex[region]
  let bestCandidateOption = cityOptions[0]
  let currentSim = 0
  let currentOption
  for (let i = 0; i < cityOptions.length; i++) {
    currentOption = cityOptions[i]
    const sim = similarity(mapCityName, currentOption)
    if (sim > currentSim) {
      currentSim = sim
      bestCandidateOption = currentOption
    }
  }

  return bestCandidateOption
}

export const getAddressInformationObjectVtex = (addressObject, companyInfo, userInfo) => {
  const { district, userAddress, price, region, aditionalInfo, deliveryType, courier, serviceType } = addressObject
  const street = userAddress?.split(' ').slice(0, -1).join(' ')
  const number = userAddress?.split(' ').pop()
  const codeRegion = regionCode[normalizeRegionName(region)]
  const newDistrict = setCitySelect(codeRegion, district)
  const postcode = ONLY_CHECKOUT.includes(companyInfo.companyName) ? getPostalCode(newDistrict) : getPinflagPostalCode(price)
  const addressInformation = {
    postalCode: postcode,
    state: DIFFERENT_REGION_NAME.includes(companyInfo.companyName) ? changedRegionName(region) : region,
    country: 'CHL',
    street,
    number,
    neighborhood: DIFFERENT_REGION_NAME.includes(companyInfo.companyName) ? newDistrict : district,
    complement: aditionalInfo,
    reference: `pinflag_${courier}-${serviceType || ''}`,
    addressType: deliveryType,
    receiverName: userInfo.names,
    geoCoordinates: addressObject.geoCoordinates ? addressObject.geoCoordinates : []
  }
  return addressInformation
}
