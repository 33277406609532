import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Input,
  Icon,
  SectionTitle,
  ModalServiceType,
  Spinner,
  GenericModal
} from '@pinflag/pinflag-ui-kit'
import { DataContext } from '../../context/DataContext'
import axios from 'axios'
import {
  addressParser,
  addressParserUnnormalized
} from '../../helpers/addressParser'
import { InputValidator } from '../../helpers/Validator'
import { setServiceAndShippingcost, onlySomeLetters } from '../../helpers/utils'
import { useForm } from 'react-hook-form'
import ReturnPickupPoint from '../ReturnPickupPoint'
import { useApiPinflag } from '../../hook/useApiPinflag'
import { ONLY_CHECKOUT } from '../../constants/stores'
import { useVtex } from '../../hook/useVtex'
import { HOME_DELIVERY } from '../../constants/deliveryTypes'

const AddressUserData = ({
  setter,
  setSelectedPointData,
  handleAddressInformation,
  setGiftCardProduct
}) => {
  const {
    setAddressInfo,
    setSelectedShippingMethod,
    addressInfo,
    companyInfo,
    selectedServiceType,
    setSelectedServiceType,
    deliveryInfo,
    setDeliveryInfo,
    setCartData,
    cartData,
    ecommerceInfo
  } = useContext(DataContext)
  const { companyName, primaryColor, secondaryColor } = companyInfo
  const [customErrors, setCustomErrors] = useState({})
  const [citySelected, setCitySelected] = useState('')
  const [stateSelected, setStateSelected] = useState('')
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    setFocus
  } = useForm({
    mode: 'all',
    defaultValues: {
      address: '',
      aditionalInfo: addressInfo.aditionalInfo
    }
  })

  const addressForm = {
    address: { ...register('address', { required: 'Campo requerido' }) },
    aditionalInfo: {
      ...register('aditionalInfo', {
        validate: value =>
          InputValidator.isAditionalInfoValid(value) || 'Máximo 55 caracteres',
        onBlur: () => {
          setCustomErrors({ ...customErrors, aditionalInfo: errors?.aditionalInfo })
        },
        onChange: e => setValue('aditionalInfo', onlySomeLetters(e.target.value, 55))
      })
    }
  }
  const [searchResults, setSearchResults] = useState([])
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [districtNotAvailable] = useState(false)
  const [openServiceTypeModal, setOpenServiceTypeModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState(primaryColor)
  const [iconColor, setIconColor] = useState('white')

  const debounceRef = useRef()

  const { getShippingInfoVtex } = useVtex()

  const onQueryChanged = event => {
    setValue('address', event.target.value)
    setIsPopupOpen(true)

    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }

    debounceRef.current = setTimeout(() => {
      searchPlaces(event.target.value)
    }, 400)
  }

  const searchAPI = axios.create({
    baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
    params: {
      limit: 3,
      language: 'es',
      autocomplete: false,
      country: 'cl',
      access_token: companyInfo.mapboxToken,
      types: 'address'
    }
  })

  const searchPlaces = async addressQuery => {
    if (addressQuery.length > 5) {
      try {
        const resp = await searchAPI.get(`/${addressQuery}.json`)
        const noResults = {
          id: 'No results action ID',
          title: 'No encuentras tu dirección?',
          type: 'action'
        }
        resp.data.features.length > 0
          ? setSearchResults(resp.data.features)
          : setSearchResults([noResults])
      } catch (error) {
        console.log(error)
      }
    }
  }
  const watchAllFields = watch()

  const { calculateShippingPrice } = useApiPinflag()

  useEffect(() => {
    watchAllFields.address.length === 0 && setSearchResults({})
  }, [watchAllFields.address])

  useEffect(() => {
    if (addressInfo.userAddress && !addressInfo.userAddress.includes('*')) {
      setValue('address', addressInfo.userAddress)
      setIsPopupOpen(true)

      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
      }

      debounceRef.current = setTimeout(() => {
        searchPlaces(addressInfo.userAddress)
      }, 50)
    }
    const handleResize = () => {
      const isMobile = window.innerWidth < 768
      setBackgroundColor(isMobile ? '#F2F4F4' : companyInfo.primaryColor)
      setIconColor(isMobile ? '#1F2937' : 'white')
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(deliveryInfo).length) {
      setServiceAndShippingcost(deliveryInfo.services[0], setSelectedServiceType, setCartData, cartData)
      if (deliveryInfo.services.length > 1) {
        setOpenServiceTypeModal(true)
      }
    }
  }, [deliveryInfo])

  const handleClickAddress = data => {
    const address = addressParser(data.place_name_es)

    setValue('address', address[0])
    setCitySelected(address[1])
    setStateSelected(address[2])
    if (data.address) {
      setSelectedPointData(data)
      setIsPopupOpen(false)
      setIsDisabled(false)
    } else {
      setFocus('address')
    }
  }

  const goManualDirection = () => {
    setAddressInfo(prevState => {
      return {
        ...prevState,
        userAddress: watchAllFields.address,
        district: citySelected,
        region: stateSelected,
        aditionalInfo: watchAllFields.aditionalInfo,
        deliveryType: HOME_DELIVERY,
        manualDelivery: true
      }
    })
    // go to manual direction
    setter(true)
    setIsModalOpen(false)
    setSelectedPointData(undefined)
  }

  const handleGoConfirmation = (selectedService) => {
    setServiceAndShippingcost(selectedService, setSelectedServiceType, setCartData, cartData)
    handleAddressInformation({
      serviceType: selectedService.serviceType,
      price: selectedService.price,
      courier: deliveryInfo.courier ? deliveryInfo.courier : selectedService.courier,
      serviceName: selectedService.name
    })
    setOpenServiceTypeModal(false)
  }

  const handleOpenServiceTypeModal = async (addressData) => {
    if (!/\d/.test(addressData.address)) return setIsModalOpen(true)

    const arrayAddress = [addressData.address, citySelected, stateSelected]
    if (ecommerceInfo.cms === 'shopify' && cartData.lines.every(item => !item.requires_shipping)) return setGiftCardProduct(arrayAddress, addressData.aditionalInfo)

    setIsLoading(true)
    if (ONLY_CHECKOUT.includes(companyName)) {
      getShippingInfoVtex(
        addressData.address,
        citySelected,
        stateSelected,
        addressData.aditionalInfo,
        setDeliveryInfo,
        setIsLoading,
        handleGoConfirmation
      )
    } else {
      await calculateShippingPrice(arrayAddress, setDeliveryInfo, setIsLoading, handleGoConfirmation, 'address automatic')
    }

    setAddressInfo(prevState => {
      return {
        ...prevState,
        userAddress: addressData.address,
        district: citySelected,
        region: stateSelected,
        aditionalInfo: addressData.aditionalInfo,
        deliveryType: HOME_DELIVERY
      }
    })
  }

  const handleSelectServiceType = (service) => {
    setSelectedServiceType(service)
  }

  const handleCloseModal = () => {
    setSelectedServiceType({})
    setCartData(prevState => {
      return {
        ...prevState,
        shippingCost: null
      }
    })
    setOpenServiceTypeModal(false)
  }

  return (
    <>
      <div className='flex flex-col items-center'>
        <GenericModal
          isOpenModal={isModalOpen}
          icon='error'
          modalTitle='Falta la numeración de la calle'
          modalText='Al parecer no se encuentra tu dirección exacta, puedes ingresarla
              manualmente.'
          firstButton='Volver'
          secondButton='Ingresar Número'
          handleClose={() => setIsModalOpen(false)}
          handleAction={goManualDirection}
        />
      </div>
      <div className=''>
        <ModalServiceType
          services={deliveryInfo.services ? deliveryInfo.services : []}
          serviceSelected={selectedServiceType.serviceType ? selectedServiceType.serviceType : ''}
          handleSelectServiceType={handleSelectServiceType}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          handlePayment={() => handleGoConfirmation(selectedServiceType)}
          handleClose={handleCloseModal}
          isOpen={openServiceTypeModal}
          className='bg-white'
          isFreeShipping={cartData.isFreeShipping}
        />
      </div>
      <div className='md:h-full'>
        <form onSubmit={handleSubmit(handleOpenServiceTypeModal)} className='flex flex-col md:h-full'>
          <div className={`bg-slate-100 grow flex flex-col md:w-[537px] ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-white'}`}>
            <div className='flex mt-2 md:mt-5 mx-[22px] md:text-sm text-xs bg-white rounded'>
              <ReturnPickupPoint setter={setSelectedShippingMethod} />
            </div>
            <div className='py-3 md:mb-2'>
              <div className={`flex flex-col bg-white rounded-lg mx-[21px] px-3 md:px-0 ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-slate-100'}`}>
                <SectionTitle
                  title='Dirección'
                  iconName='home'
                  iconCustomStyle={{ fill: iconColor }}
                  primaryColor={backgroundColor}
                  className='mb-3 md:ml-5 mt-2'
                />
                <div className='md:mx-5 font-inter'>
                  {isLoading
                    ? (
                      <div className='flex flex-col gap-y-4 justify-center items-center mb-10'>
                        <Spinner />
                        <div className='text-gray-500 text-sm'>Obteniendo las tarifas de envío disponible...</div>
                      </div>
                      )
                    : (
                      <div className='relative'>
                        <Input
                          containerClass=''
                          label='Calle y Número'
                          innerRef={addressForm.address.ref}
                          onChange={onQueryChanged}
                          onBlur={addressForm.address.onBlur}
                          name='address'
                          type='text'
                          errors={errors?.address}
                          secondaryColor={secondaryColor}
                          testId="street-number"
                        />
                        {searchResults.length > 0 && isPopupOpen && (
                          <ul
                            className='flex flex-col rounded-[10px] m-0 w-[100%] md:w-[287px] md:h-[163px] sm:w-max absolute shadow-xl bg-zinc-50 p-2 z-10 overflow-y-scroll'
                            data-testid="address-list"
                          >
                            {searchResults?.map((result, index) => {
                              return (
                                <div key={index}>
                                  {result.type && result.type === 'action'
                                    ? (
                                        ''
                                      )
                                    : (
                                      <div className='flex justify-between md:hover:bg-slate-200 md:hover:rounded-[7px] md:py-[1px]'>
                                        <li
                                          className='flex flex-col text-sm  cursor-pointer md:text-[10px] md:text-gray-500 md:font-medium leading-[125.02%] md:h-[43px] py-3'
                                          onClick={() => handleClickAddress(result)}
                                          key={result.id}
                                        >
                                          <div className='flex flex-col px-4 justify-center h-full'>
                                            <span className='font-semibold md:text-zinc-800'>
                                              {addressParser(result.place_name_es)[0]}
                                            </span>
                                            <span>
                                              {addressParser(result.place_name_es)[1]},
                                              {addressParserUnnormalized(
                                                result.place_name_es
                                              )[2].slice(0, -8)}
                                            </span>
                                          </div>
                                        </li>
                                        <Icon
                                          name='diagonal-arrow'
                                          width={14}
                                          className='fill-gray-300 min-w-[9px] w-[9px] md:mr-3'
                                        />
                                      </div>
                                      )}
                                </div>
                              )
                            })}
                            <hr />
                            <div className='flex h-24 items-center my-2'>
                              <Icon
                                name='search-address'
                                width={37}
                                className='fill-gray-800 ml-3 mr-5'
                              />
                              <div className='text-[10px]'>
                                <li className='text-gray-800'>
                                  ¿No encuentras tu dirección?
                                </li>
                                <li className='text-slate-300'>
                                  Puedes ingresar la dirección de forma manual
                                </li>
                                <div className='flex'>
                                  <Button
                                    label='Ingresar manualmente'
                                    customStyle={{
                                      backgroundColor: secondaryColor
                                    }}
                                    LeftIcon={
                                      <Icon
                                        name='edit'
                                        width={9}
                                        className='fill-white mr-1 text-sm'
                                      />
                                    }
                                    onClick={() => {
                                      setter(true)
                                    }}
                                    className={`flex items-center justify-center bg-cyan-50 px-3 py-1 mt-3 rounded-2xl mr-4 text-[11px] font-light text-white h-[23px] ${ecommerceInfo.cms === 'woocommerce' && 'border'}`}
                                    testId='manual-address'
                                  />
                                </div>
                              </div>
                            </div>
                          </ul>
                        )}
                        <div className='md:w-full md:flex md:justify-end'>
                          <Input
                            containerClass='md:w-[213px] mt-3 z-0'
                            label='Depto/Oficina/Block'
                            innerRef={addressForm.aditionalInfo.ref}
                            onChange={addressForm.aditionalInfo.onChange}
                            onBlur={addressForm.aditionalInfo.onBlur}
                            name='aditionalInfo'
                            type='text'
                            errors={errors.aditionalInfo}
                            customErrors={customErrors.aditionalInfo}
                            secondaryColor={secondaryColor}
                            testId="additional-info"
                          />
                        </div>
                        <div className='flex justify-end'>
                          <Button
                            label='Ingresar manualmente'
                            LeftIcon={<Icon name='edit' className='fill-gray-800 mr-1 text-sm' width={9} />}
                            onClick={() => {
                              setter(true)
                            }}
                            className={`text-[11px] flex items-center bg-slate-100 px-4 py-1 my-6 rounded-2xl mr-4 font-light h-[23px] w-auto border ${ecommerceInfo.cms === 'woocommerce' && 'md:bg-white'}`}
                            testId='manual-address'
                          />
                        </div>
                        <div className={`hidden md:flex w-full justify-end ${districtNotAvailable && 'justify-around'} ${ecommerceInfo.cms === 'woocommerce' ? '' : 'my-4 mt-[30px]'}`}>
                        {districtNotAvailable && <div className="flex justify-start text-sm text-red-500 pt-1">No despachamos hacia esa comuna. Elige otra.</div>}
                          <Button
                            className='bg-black text-white w-[149px] rounded-3xl disabled:bg-slate-100 disabled:text-gray-500 h-9 text-base'
                            role='submit'
                            label='Continuar'
                            disabled={isDisabled || districtNotAvailable}
                            customStyle={{
                              backgroundColor: secondaryColor
                            }}
                            testId='go-confirmation'
                          />
                        </div>
                      </div>
                      )}
                </div>
              </div>
            </div>
          </div>
          <div className='md:hidden flex flex-col relative justify-end bg-white'>
            <div className='w-full text-center my-4'>
              <Button
                className='h-[3rem] text-base bg-black text-white w-[90vw] rounded-lg disabled:bg-slate-100 disabled:text-gray-500'
                role='submit'
                label='Continuar'
                disabled={isDisabled || districtNotAvailable}
                customStyle={{ backgroundColor: secondaryColor }}
                testId='go-confirmation'
              />
              {districtNotAvailable && <div><span className="pt-2 text-sm text-red-500">No despachamos hacia esa comuna. Elige otra.</span></div>}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

AddressUserData.propTypes = {
  setter: PropTypes.func,
  setSelectedPointData: PropTypes.func,
  handleAddressInformation: PropTypes.func,
  setGiftCardProduct: PropTypes.func
}

export default AddressUserData
