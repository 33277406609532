export const STORES_DISCOUNT_CYC = { SELFIE: 30 }

export const STORES_REDIRECT_SHIPPING_STEP = []

export const LOCAL_PICKUP_DELIVERY_TIME = { 'Atakama Outdoor': 'Desde 50 minutos' }

export const STORES_STOCKABLES = ['PinflagTestStore', 'Lippi', 'Haka Honu', 'Due Scarpe', 'Atakama Outdoor', 'Trailstore.cl', 'Me lo llevo', 'Simplit', 'Lhotse', 'Kano', 'Relan', 'Caracol Natural Skincare', 'Yambo Bags', 'Raindoor']

export const STORES_DEFAULT_WEIGHT = {
  'Safety Store B2B': 20
}

export const STORES_WITH_THIRD_PARTY_RATES = ['Atakama Outdoor', 'Zazu', 'Veus', 'Caracol Natural Skincare', 'Me lo llevo', 'Lhotse', 'Simplit', 'Kano', 'Relan', 'Yambo Bags', 'Verde Legion', 'Monarch', 'Zitica', 'Gotta', 'Raindoor']

export const THIRD_PARTY_RATES_DATA = {
  'Atakama Outdoor': {
    'Retiro en tienda Nomad': {
      description: 'Retira en 50 minutos',
      packageDeliveryTime: '50 minutos'
    },
    'Nomad - Envío Express': {
      description: '',
      packageDeliveryTime: ''
    }
  },
  Veus: {
    'Envío Express': {
      description: 'Despacho en 3 horas (días hábiles)',
      packageDeliveryTime: '3 horas (días hábiles)'
    }
  },
  'Me lo llevo': {
    'Bluexpress Sameday': {
      description: 'Por compras realizadas antes de las 10.59 de Lunes a Viernes, solo RM',
      packageDeliveryTime: ''
    },
    'Bluexpress Priority': {
      description: 'Por compras antes de las 10.59, llega al día siguiente (de Lunes a Viernes)',
      packageDeliveryTime: ''
    }
  },
  Lhotse: {
    'Bluexpress Sameday': {
      description: 'Por compras realizadas antes de las 10.59 de Lunes a Viernes, solo RM',
      packageDeliveryTime: ''
    },
    'Bluexpress Priority': {
      description: 'Por compras antes de las 10.59, llega al día siguiente (de Lunes a Viernes)',
      packageDeliveryTime: ''
    }
  },
  Simplit: {
    'Bluexpress Sameday': {
      description: 'Por compras realizadas antes de las 10.59 de Lunes a Viernes, solo RM',
      packageDeliveryTime: ''
    },
    'Bluexpress Priority': {
      description: 'Por compras antes de las 10.59, llega al día siguiente (de Lunes a Viernes)',
      packageDeliveryTime: ''
    }
  },
  'Verde Legion': {
    'Despacho gratis': {
      description: 'Se despacha los días jueves',
      packageDeliveryTime: ''
    },
    'Despacho a domicilio': {
      description: '1 a 2 días hábiles',
      packageDeliveryTime: ''
    },
    'Despacho Escar': {
      description: 'Se despacha todos los días',
      packageDeliveryTime: ''
    },
    'Despacho Esucar': {
      description: 'Se despacha los días jueves',
      packageDeliveryTime: ''
    },
    'Despacho Esfocar': {
      description: 'Se despacha los días jueves',
      packageDeliveryTime: ''
    }
  }
}

export const BILLING_TYPE_STORES = {
  'Atakama Outdoor': 'attributes[billing_documento]=33&',
  Ironside: 'attributes[tipo_documento]=FACTURA&'
}

export const SHOPIFY_PLUS_STORES = ['Ironside', 'Pichara', 'Test Store', 'Monarch']

export const ONLY_CHECKOUT = [
  'Opaline',
  'Colloky'
]

export const DIFFERENT_REGION_NAME = [
  'Opaline',
  'Colloky'
]

export const CHANGE_ORDER = [
  'Lorenzo di Pontti'
]

export const STORES_WITHOUT_PINFLAG_SERVICES = ['Me lo llevo', 'Lhotse', 'Simplit', 'Kano', 'Relan', 'Yambo Bags', 'Verde Legion', 'Monarch', 'Zitica', 'Gotta', 'Raindoor']

export const STORES_WITH_REFACTOR_SCRAPER = ['RI soluciones', 'Atakama Outdoor', 'Kano', 'Me lo llevo', 'Lhotse', 'Simplit', 'Somos Juegos', 'Yambo Bags', 'Verde Legion']

export const STORES_WITH_DEFAULT_DOCUMENT = ['Atakama Outdoor', 'Vielco']
