export const numberParser = (figure) => {
  try {
    const num = figure
    const parts = num?.toString().split('.')
    return parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  } catch (err) {
    console.log(err)
    return figure
  }
}
