import PropTypes from 'prop-types'
import { DiscountCodes, Notification, GenericModal } from '@pinflag/pinflag-ui-kit'
import React, { useContext, useEffect } from 'react'
import { useShopify } from '../../hook/useShopify'
import { useWoocommerce } from '../../hook/useWoocommerce'
import { useVtex } from '../../hook/useVtex'
import { DataContext } from '../../context/DataContext'

const InputDiscountCode = ({
  className,
  primaryColor,
  secondaryColor,
  disabled,
  label,
  setIsEditingDiscountCode,
  warningDiscountCode,
  setWarningDiscountCode
}) => {
  const {
    setDiscountInfo,
    discountInfo,
    ecommerceInfo
  } = useContext(DataContext)

  const { removeDiscountCodeShopify, removeGiftCardShopify, addGiftCardShopify, addDiscountCodeShopify } = useShopify()
  const { deleteDiscountCodeWoocommerce, notifyDiscountCodeWoocommerce } = useWoocommerce()
  const { addDiscountCodeVtex, removeDiscountCodeVtex, addGiftCardVtex, removeGiftCardVtex } = useVtex()

  const { discountCodes, discountError, discountNotification } = discountInfo

  const handleClose = () => {
    setWarningDiscountCode(false)
  }

  const handleDeleteCode = async () => {
    for (let index = 0; index < discountCodes.length; index++) {
      if (discountCodes[index].discountType === 'gift-card') {
        removeGiftCardShopify(discountCodes[index].code)
      } else {
        await removeDiscountCodeShopify(discountCodes[index].code)
        deleteDiscountCodeWoocommerce(discountCodes[index].code)
        await removeDiscountCodeVtex()
      }
    }
    setDiscountInfo(prevState => {
      return {
        ...prevState,
        discountCodes: []
      }
    })
    setIsEditingDiscountCode(false)
    setWarningDiscountCode(false)
  }

  const onDeleteCode = async (selectedCode) => {
    const discountType = discountCodes.find(discount => discount.code === selectedCode).type
    if (discountType === 'gift-card') {
      removeGiftCardShopify(selectedCode)
      await removeGiftCardVtex(selectedCode)
    } else {
      await removeDiscountCodeShopify(selectedCode)
      deleteDiscountCodeWoocommerce(selectedCode)
      await removeDiscountCodeVtex()
    }

    const filteredCodes = discountCodes.filter(discount => discount.code !== selectedCode)
    setDiscountInfo(prevState => {
      return {
        ...prevState,
        discountCodes: filteredCodes
      }
    })
  }

  useEffect(() => {
    if (discountCodes.length > 0) {
      setIsEditingDiscountCode(true)
    }
  }, [discountCodes])

  const onSubmitCode = (discountCode) => {
    setDiscountInfo(prevState => {
      return {
        ...prevState,
        discountError: undefined
      }
    })
    if (ecommerceInfo.cms === 'shopify') {
      addDiscountCodeShopify(discountCode).then(resCoupon => {
        if (resCoupon.valid) {
          setDiscountInfo(prevState => {
            return {
              ...prevState,
              discountError: undefined
            }
          })
        } else {
          addGiftCardShopify(discountCode).then(resGiftCard => {
            setDiscountInfo(prevState => {
              return {
                ...prevState,
                discountError: resGiftCard.valid ? undefined : { message: resCoupon.error }
              }
            })
          })
        }
      })
    } else if (ecommerceInfo.cms.includes('vtex')) {
      addGiftCardVtex(discountCode).then(resGiftCard => {
        if (resGiftCard.valid) {
          setDiscountInfo(prevState => {
            return {
              ...prevState,
              discountError: undefined
            }
          })
        } else {
          addDiscountCodeVtex(discountCode).then((resCoupon) => {
            setDiscountInfo(prevState => {
              return {
                ...prevState,
                discountError: resCoupon.valid ? undefined : { message: resCoupon.error }
              }
            })
          })
        }
      })
    }
    notifyDiscountCodeWoocommerce(discountCode)
  }

  return (
    <>
      <GenericModal
        isOpenModal={warningDiscountCode}
        icon={'error'}
        modalTitle={'Deshabilitar cupones'}
        modalText={'Esta acción eliminirará los descuentos aplicados a tu compra.'}
        firstButton={'Cancelar'}
        secondButton={'Eliminar'}
        handleClose={handleClose}
        handleAction={handleDeleteCode}
      />
    {
      (ecommerceInfo.cms === 'shopify' || ecommerceInfo.cms.includes('woocommerce') || ecommerceInfo.cms.includes('vtex')) &&
      (
        <DiscountCodes
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          onDeleteCode={onDeleteCode}
          disabled={disabled}
          label={label}
          discountCodes={discountCodes}
          discountError={discountError}
          onSubmitCode={onSubmitCode}
          className={className}
        />
      )
    }

    {discountNotification && <Notification message={discountNotification} />}
    </>
  )
}

InputDiscountCode.propTypes = {
  className: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  setIsEditingDiscountCode: PropTypes.func,
  warningDiscountCode: PropTypes.bool,
  setWarningDiscountCode: PropTypes.func
}

InputDiscountCode.defaultProps = {
  primaryColor: '#263046',
  secondaryColor: '#33cccc',
  disabled: false,
  label: 'Cupón de descuento'
}

export default InputDiscountCode
