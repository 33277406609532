import React, { useEffect, useContext, useState } from 'react'
import './Tailwind.css'
import { DesktopStepper, Stepper } from '@pinflag/pinflag-ui-kit'
import { DataContext } from './context/DataContext'
import { Header } from './components/Header'
import FinalLoader from './components/FinalLoader'
import { useApiPinflag } from './hook/useApiPinflag'
import { useMagento } from './hook/useMagento'
import { useVtex } from './hook/useVtex'
import ShippingOptionRenderer from './components/organism/ShippingOptionRenderer'
import { useShopify } from './hook/useShopify'
import UserForm from './components/UserForm'
import PaymentMethodSelection from './components/PaymentMethodSelection'
import { useCmsAdapter } from './hook/useCmsAdapter'
import { useWoocommerce } from './hook/useWoocommerce'
import { useSocket } from './hook/useSocket'
import { Summary } from './components/Summary'
import { shippingAddressObject } from './helpers/addressParser'
import { abreviatedCheckoutLines } from './helpers/utils'
import CartSection from './components/CartSection'

const PinmapPro = () => {
  const {
    isPaying,
    steps,
    currentStep,
    setCurrentStep,
    ecommerceInfo,
    companyInfo,
    userInfo,
    setCartNotes,
    setPointsData,
    setGiftNotes,
    setIsGiftWrapping,
    isSummaryOpen,
    addressInfo,
    cartData,
    isRemoved,
    deletedCheckoutLog,
    pointsData,
    setIsSummaryOpen,
    selectedShippingMethod,
    configurations,
    isPickup
  } = useContext(DataContext)
  const { getCompanyInfo, getPoints } = useApiPinflag()
  const {
    iframeLoadedMagento,
    submitFormMagento,
    setInitialInformationMagento
  } = useMagento()
  const { iframeLoadedVtexIO, setInitialInformationVtex } = useVtex()
  const {
    setInitialInformationShopify,
    checkUpdatedCart,
    applyFreeShippingAutomaticDiscount,
    getConfigurationsShopify
  } = useShopify()
  const {
    iframeLoadedWoocommerce,
    setInitialInformationWoocommerce,
    hideWoocommerceSummary,
    continueCheckout,
    handleDiscountCodeWoocommerce
  } = useWoocommerce()
  const { createCheckoutLog, trackingLeak, trackingPinmap } = useCmsAdapter()
  const [closePinmap, setClosePinmap] = useState(false)
  const [initialInfoReady, setInitialInfoReady] = useState(false)
  const { scrapperClient } = useSocket()
  const isLoaderIframe = new URLSearchParams(document.location.search).get('loaderIframe')

  const handleStepClick = (stepData) => {
    if (pointsData.localPoints.length === 1 && selectedShippingMethod === 'local-pickup' && userInfo.isRegistered) {
      setIsSummaryOpen(true)
    }
    if (stepData.stepId === 1 && userInfo.isRegistered) {
      setIsSummaryOpen(true)
    }
    if (stepData && !stepData.disabled && stepData.stepId !== currentStep) {
      setPointsData((prevState) => {
        prevState.localPointSelected = {}
        prevState.pinflagPointSelected = {}
        return prevState
      })
      hideWoocommerceSummary()
      setCurrentStep(stepData.stepId)
    }
  }

  const incomingMessageHandler = async (event) => {
    if (event.data.origin) {
      const { message, origin } = event.data
      switch (event.data.type) {
        case 'receive-cart-info':
          setInitialInformationMagento(message, setInitialInfoReady)
          setInitialInformationVtex(message, setInitialInfoReady)
          setInitialInformationShopify(message, origin, setInitialInfoReady)
          setInitialInformationWoocommerce(message, setInitialInfoReady)
          break
        case 'set-form-data-finished':
          submitFormMagento()
          break
        case 'close-pinmap':
          setClosePinmap(true)
          break
        case 'order-aditional-info':
          setCartNotes(message)
          break
        case 'gift-wrapping':
          setIsGiftWrapping(message)
          break
        case 'gift-aditional-info':
          setGiftNotes(message)
          break
        case 'receive-user-checkout-info':
          continueCheckout(message)
          break
        case 'update-info-cart':
          checkUpdatedCart(message)
          break
        case 'handle-discount-info':
          handleDiscountCodeWoocommerce(message)
          break
        case 'is-ready':
          getCompanyInfo(message)
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    if (closePinmap && !isRemoved && !isPaying && userInfo.email && cartData.lines.length) {
      trackingLeak({
        causePinmapLeak: 'user action - close window or reload'
      })
      if (ecommerceInfo.cms !== 'shopify') return
      let storeConfig = configurations
      if (!Object.keys(configurations)?.length) storeConfig = getConfigurationsShopify()

      const checkoutData = {
        email: userInfo.email,
        shippingAddress: addressInfo.userAddress
          ? shippingAddressObject(userInfo, addressInfo, storeConfig)
          : undefined,
        lineItems: abreviatedCheckoutLines(cartData.lines)
      }
      if (!deletedCheckoutLog) createCheckoutLog(checkoutData)
    }
  }, [closePinmap])

  useEffect(() => {
    if (ecommerceInfo.cms && companyInfo.companyName) {
      if (ecommerceInfo.cms !== 'shopify') {
        getPoints()
      }
    }
  }, [ecommerceInfo.cms, companyInfo.companyName])

  // useEffect(() => {
  //   ecommerceInfo.checkoutId && companyInfo.pinflagApiKey && loadCheckout()
  // }, [ecommerceInfo.checkoutId, companyInfo.pinflagApiKey])

  useEffect(() => {
    window.addEventListener('message', incomingMessageHandler)
    iframeLoadedMagento()
    iframeLoadedVtexIO(setInitialInfoReady)
    setTimeout(iframeLoadedWoocommerce(), 1000)
    getCompanyInfo()
    return () => {
      window.removeEventListener('message', incomingMessageHandler)
    }
  }, [])

  useEffect(() => {
    if (companyInfo.companyName.length > 0 && initialInfoReady) {
      trackingPinmap()
    }
  }, [companyInfo, initialInfoReady])

  useEffect(() => {
    if (initialInfoReady && currentStep === 1 && ecommerceInfo.cms === 'shopify') {
      applyFreeShippingAutomaticDiscount()
    }
  }, [initialInfoReady])

  return (
    <div className='h-full small-mobile-container overflow-y-scroll md:overflow-y-hidden pb-28 md:pb-0'>
      {!isPaying && !isLoaderIframe
        ? (
          <div className='md:w-[850px] h-full font-poppins'>
            <Header />
            <div className='md:hidden'>
              <Stepper
                className='mt-3 px-4 md:w-4/6'
                steps={steps}
                currentStep={currentStep}
                onStepClick={handleStepClick}
                secondaryColor={companyInfo.secondaryColor}
              />
            </div>
            <div className='hidden md:flex'>
              <DesktopStepper
                className='mt-5 md:mt-0 px-4 md:w-[480px] md:text-[11px] md:ml-5 md:mb-1'
                steps={steps}
                currentStep={currentStep}
                onStepClick={handleStepClick}
                primaryColor={companyInfo.primaryColor}
                secondaryColor={companyInfo.secondaryColor}
              />
            </div>
            <div className='min-h-96 md:min-h-0 md:h-full'>
              {currentStep === 1 && <UserForm />}
              {currentStep === 2 && (isSummaryOpen ? <Summary /> : <ShippingOptionRenderer />)}
              {currentStep === 3 && <PaymentMethodSelection scrapperClient={scrapperClient} />}
            </div>
            {!isPickup && (
              <div className='md:hidden pb-20 bg-slate-100 pt-2'>
                <CartSection setIsOpen={() => {}} className='p-4' />
                <div className='md:hidden flex justify-center pt-2 text-sm text-slate-300 mb-[3rem]'>
                  100% Seguro + Rápido y Facil. Powered by
                  <a
                    href='https://pinflag.cl'
                    target='_blank'
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                  >
                    <span className='font-bold'>&nbsp;Pinflag</span>
                  </a>
                </div>
              </div>
            )}
          </div>
          )
        : (
          <FinalLoader />
          )}
    </div>
  )
}

export default PinmapPro
