import axios from 'axios'

import { shippingObject, billingAddress } from './paymentData'

export const setShippingInformationApi = (
  ecommerceDomain,
  checkoutId,
  userInfo,
  addressInfo
) => {
  const response = axios.post(
    `${ecommerceDomain}/rest/default/V1/guest-carts/${checkoutId}/shipping-information`,
    shippingObject(addressInfo, userInfo)
  )

  return response
}

export const setPaymentInformationApi = async (
  ecommerceDomain,
  checkoutId,
  userInfo,
  addressInfo,
  paymentMethod
) => {
  try {
    await axios.post(
      `${ecommerceDomain}/rest/default/V1/guest-carts/${checkoutId}/payment-information`,
      {
        billingAddress: billingAddress(addressInfo, userInfo),
        paymentMethod: {
          method: paymentMethod
        },
        email: userInfo.email
      }
    )

    return paymentMethod
  } catch (error) {
    // TODO: Handle error on frontend
  }
}
