import axios from 'axios'

import {
  TBK_ENVIRONMENT
} from '../constants/payment'

const CMSURL = 'https://dba1-201-219-234-101.sa.ngrok.io/dev'

const mercadopago = async () => {
  console.log('Work in progress...')
}

const transbank = async (paymentData) => {
  const { ecommerceInfo, amount } = paymentData

  const txData = {
    action: 'createTransaction',
    pinflagApiKey: process.env.REACT_APP_API_KEY,
    enviroment: TBK_ENVIRONMENT,
    buyOrder: Date.now().toString(36),
    sessionId: ecommerceInfo.magentoQuoteId,
    amount,
    returnUrl: `'${ecommerceInfo.domain}/checkout/onepage/success/'` // TODO: Where to redirect?
  }

  try {
    const tx = await axios.post(
      `${CMSURL}/transbank`,
      txData,
      {
        headers: {
          'Bypass-Tunnel-Reminder': true,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true
        }
      }
    )
    const { url, token } = tx.data

    window.parent.postMessage(
      {
        type: 'redirect-to-tbk',
        origin: 'pinflag-checkout-iframe',
        message: { url, token }
      },
      '*'
    )
  } catch (error) {
    // TODO: Manage error on frontend
  }
}

export const processPayment = {
  transbank_webpay: transbank,
  mercadopago
}
