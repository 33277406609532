import axios from 'axios'

const awsInstance = axios.create({
  baseURL: 'https://ed4hoe23ce.execute-api.sa-east-1.amazonaws.com/main',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default awsInstance
