import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  CartLines,
  CartSummary,
  Icon
} from '@pinflag/pinflag-ui-kit'
import { DataContext } from '../context/DataContext'
import { numberParser } from '../helpers/numberParser'
import InputDiscountCode from './organism/InputDiscountCode'
import { labelDiscountCodeGiftCard } from '../helpers/labelDiscountCodeGiftCard'
import classNames from 'classnames'

const CartSection = ({ setIsOpen, className }) => {
  const { cartData, isPickup, currentStep, companyInfo, discountInfo, ecommerceInfo } = useContext(DataContext)
  const { primaryColor, secondaryColor, useGiftCard, useDiscountCode } = companyInfo
  const { discountCodes } = discountInfo
  const { cms } = ecommerceInfo

  const updateItemQuantity = (id, quantity) => {
    if (cms !== 'shopify') return
    window.parent.postMessage(
      {
        type: 'update-cart',
        origin: 'pinflag-shopify-pinmap-pro',
        message: { key: id, quantity }
      },
      '*'
    )
  }

  const containerClasses = classNames(
    `flex flex-col h-full w-full ${isPickup ? 'md:hidden' : ''}`,
    className
  )

  return (
    <div className={containerClasses} >
      <div className='flex text-sm font-medium justify-between my-3 md:mt-1'>
        <div className='flex flex-raw md:text-lg text-pfblue items-center font-semibold'>
          <Icon name='shopping-cart' width={22} className='fill-slate-200 mr-5' customStyle={{ fill: primaryColor }} />
          Resumen de compra
        </div>
        <Icon name='close' width={20} onClick={() => setIsOpen(false)} className={`md:invisible ${isPickup ? 'flex' : 'invisible'}`}>X</Icon>
      </div>
      <div className='grow max-h-[40%] my-4 md:my-0'>
        <CartLines
          lines={cartData.lines}
          showDelete={currentStep === 1 && cms === 'shopify'}
          onClickFunction={(id, quantity) => updateItemQuantity(id, quantity)}
          height={'100%'}
          showCounter={currentStep === 1 && cms === 'shopify' }
        />
      </div>
      <div className='flex flex-col justify-end grow max-h-[40%] md:max-h-[50%]'>
        <div className={`${(useGiftCard || useDiscountCode) ? 'hidden md:block' : 'hidden'}`}>
          <InputDiscountCode
            className='mt-4 max-h-[7.5rem] overflow-hidden'
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            disabled={currentStep !== 1}
            label={ labelDiscountCodeGiftCard(useGiftCard, useDiscountCode) }
            setIsEditingDiscountCode = {() => {}}
            warningDiscountCode = {false}
            setWarningDiscountCode = {() => {}}
          />
        </div>
        <div className=''>
          <hr className='mt-2 mb-2' />
          <CartSummary
            subtotal={numberParser(cartData.subtotal)}
            total={numberParser(cartData.total)}
            discount={cartData.discount
              ? { value: numberParser(cartData.discount), quantity: discountCodes.filter(code => code.type === 'coupon').length }
              : null}
            itemsCount={numberParser(cartData.itemsCount)}
            shippingCost={cartData.isFreeShipping ? '0' : cartData.shippingCost !== null ? numberParser(cartData.shippingCost) : null}
            giftCard={cartData.giftCard.applied
              ? { value: numberParser(cartData.giftCard.applied), quantity: discountCodes.filter(code => code.type === 'gift-card').length }
              : null}
          />
        </div>
      </div>
    </div>
  )
}

CartSection.propTypes = {
  setIsOpen: PropTypes.func,
  className: PropTypes.string
}

export default CartSection
