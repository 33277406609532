export const applyChangesInPrices = (addedGiftCard, removedGiftCard, cartData, setCartData) => {
  const { subtotal, shippingCost: realShippingCost, discount, giftCard, isFreeShipping } = cartData
  const shippingCost = isFreeShipping ? 0 : realShippingCost
  if (addedGiftCard) {
    giftCard.total += Number(addedGiftCard.balance)
    giftCard.cards.push(addedGiftCard)
  }
  if (removedGiftCard) {
    giftCard.total -= Number(removedGiftCard.balance)
    const filteredCards = giftCard.cards.filter(card => card.last_characters !== removedGiftCard.last_characters)
    giftCard.cards = filteredCards
  }
  const parcialTotal = subtotal - discount + shippingCost || 0
  const isTotalPriceHigher = parcialTotal > giftCard.total
  const newTotal = isTotalPriceHigher ? parcialTotal - giftCard.total : 0
  const giftCardApplied = isTotalPriceHigher ? giftCard.total : parcialTotal

  setCartData(prevState => {
    return {
      ...prevState,
      giftCard: { total: giftCard.total, applied: giftCardApplied, cards: giftCard.cards },
      total: newTotal
    }
  })
}
